<template>
    <div class="digitalInputContainer">
        <h1 ref="text">{{text}}</h1>
    </div>
</template>

<script>
export default {
    data(){
        return{
            isOn: undefined,
            color: undefined,
            text: undefined,
        }
    },
    props:{
        value: {
            type: Number,
            required: false,
        },
        info:{
            type: Object,
            required: true
        },
        divisionsColorList:{
            type:Array,
            required:true
        }
    },
    computed:{
        onColor(){
            return this.color
        },
        textColor(){
            var c = this.onColor.substring(1);     
            var rgb = parseInt(c, 16);  
            var r = (rgb >> 16) & 0xff; 
            var g = (rgb >>  8) & 0xff; 
            var b = (rgb >>  0) & 0xff; 

            var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
            var color
            if(luma < 150) color = 'white'
            else color = 'black'
            return color
        },
    },
    methods:{
        resizeMeasurer(){
            const container = this.$el 
            var area = (container.offsetHeight * container.offsetWidth) / 1200 
            area = area > container.offsetHeight/2 ? container.offsetHeight/2 : area 
            this.$refs.text.style.fontSize = `${area}px`
        },
        toggle(status){
            const element = this.$el
            const divisions = this.info.divisions
            if(status){
                element.classList.remove("off")
                this.text =  divisions[divisions.length - 1].text
                this.color =  divisions[divisions.length - 1].color
            }else{
                element.classList.add("off")
                this.text = divisions[0].text
                this.color = divisions[0].color
            }
        },
    },
    mounted(){
        this.toggle(this.value)
    },
    beforeDestroy(){
        clearInterval(this.toggleSwitchIterval)
    },
    watch:{
        value(){
            this.toggle(this.value)
        }
    }
}
</script>

<style scoped lang="scss">
.digitalInputContainer{
    padding: 1rem;
    height: 60%;
    width: 80%;
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: v-bind(onColor);
    border-radius: 1rem;
    border: 1px solid white;
    outline: 2px solid v-bind(onColor);
    outline-offset: 7px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    h1{
        color: v-bind(textColor);
        font-size: 1.75rem !important;
    }
    &.off{
        background-color: v-bind(onColor);
        outline-color: v-bind(onColor);
    }
}
</style>