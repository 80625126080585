<template>
    <div class="headerContainer" :class="{'isSticky': isSticky}">
        <section class="w-100">
            <div class=" mb-2 header" style="display: grid; grid-template-columns: 33% 33% 33%">
                <h4 v-if="small">{{name}}</h4>
                <h1 v-else>{{name}}</h1>

                <div class="searchContainer" v-if="!hideSearch">
                    <b-input-group class="search-group">
                        <b-input-group-prepend>
                            <b-input-group-text>
                                <b-icon icon="search" />
                            </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input id="search-table" type="text" v-model="filter"></b-form-input>
                    </b-input-group>
                </div>
                <span v-else></span>

                <div class="w-100 h-100 d-flex justify-content-end">
                    <action-button v-if="toggleDisabled" type="button" :title="buttonTitle" 
                        buttonStyle="outline-danger" class="mr-1 control" 
                        @buttonAction="toggleDisabled()" >
                    </action-button>
                    <action-button v-if="button" type="button" :title="$i18n.t(buttonTitle)" 
                    :buttonStyle=buttonStyle @buttonAction="click()" class="m-0">
                    </action-button>
                    <span v-else></span>
                </div>
            </div>
            <p>{{description}}</p>
        </section>
        <hr>
    </div>
</template>

<script>
import ActionButton from "../button/MtwButton.vue"

export default {
    data(){
        return {
            filter: ''
        }
    },
    components:{
        ActionButton,
    },
    props:{
        name:{
            type:String,
            required: true
        },
        description: {
            type: String,
            required: false,
            default: ""
        },
        button: {
            type: Boolean,
            required: false,
            default: true
        },
        buttonTitle: {
            type: String,
            required: false,
            default: "new"
        },
        buttonStyle: {
            type: String,
            required: false,
            default: "outline-light"
        },
        toggleDisabled:{
            required:false,
            default: false,
            type:Boolean
        },
        small:{
            required:false,
            default:false,
            type:Boolean
        },
        hideSearch:{
            required:false,
            default:false,
            type:Boolean
        },
        sticky: {
            required: false,
            default: false,
            type: Boolean
        },

    },
    computed: {
        isSticky(){
            return this.sticky
        },
    },
    methods:{
        click(){
            this.$emit('click');  
        }
    },
    watch:{
        filter(){
            this.$emit('filter', this.filter)
        }
    
    },
}
</script>

<style lang="scss" scoped>
    @media (min-width: 320px) and (max-width: 991px){
        .header{
            flex-direction: column;
        }
    }
    .headerContainer{
        &.isSticky{
            top: 20px;
            position: sticky;
            background: #1d252f;
            z-index: 9;
            border-radius: 1rem 1rem 0 0;
            border: 2px solid #5c738b;
            border-bottom: 0;
            padding: 0 1rem;
            hr{
                display: none;
            }
        }
    }
</style>