<template>
    <div class="slidecontainer form mb-5">
        <label 
            :for="inputID" 
            style="width:100%;">
                {{label}} <span class="required" v-if="required">*</span>
        </label>
        <div class="pl-5 pr-5">
            <vue-slider :id="inputID" v-model="sliderValue" v-bind="sliderOptions" :marks="markers" />
        </div>
    </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
export default {
    data(){
        return{
            sliderValue: 0
        }
    },
    components: {
        VueSlider
    },
    props:{
        name: {
            type: String,
            required: true
        },
        label:{
            type:String,
            required:true
        },
        required:{
            type:Boolean,
            required: false,
            default: false
        },
        display: {
            required:false,
            default(){
                return {
                    divisions: []
                }
            },
            type:Object
        }
    },
    computed:{
        min(){
			return this.display.minimum != undefined ? this.display.minimum : 0
		},
		max(){
			return this.display.maximum  != undefined ? this.display.maximum : 100
		},
        divisions(){
            return [...this.display.division].sort((a, b) => a.end-b.end);
            
        },
        isInPercent(){
            return false
            //return this.divisions[this.divisions.length - 1].end == 100
        },
        inputID(){
            return `${this.name}Input`
        },
        sliderOptions(){
            return {
                min: this.min,
                max: this.max,
                railStyle: {background: this.railColor, border: '0.1px solid rgba(255, 255, 255, 0.3)', height: '15px'},
                processStyle: {
                    background: this.processColor
                },
                tooltipStyle: {
                    background: this.darkColor,
                    borderColor: this.darkColor
                },
                dotStyle: {
                    background: this.lightColor,
                    border: this.lightColor
                }
            }
        },
        markers(){
            if(this.divisions == 0) return false
            const result = {}
            this.divisions.forEach((item, index) => {
                var start;
                var end;
                start = this.getValueByPercentage(item.start)
                end = this.getValueByPercentage(item.end)
                var half = (start + end) /2 
                result[end] = {
                    label: '', 
                    style: index != this.divisions.length - 1 ? {
                        background: "rgb(255 255 255 / 75%)",
                        width: "5px",
                        height: "5px",
                        display: "block",
                        borderRadius:"100%",
                        border: "none !important",
                        transform: "translate(-2px, 3px)"
                    } : {}
                };

                result[half] = {
                    label: item.text, 
                    style: {}
                }
            })
            return result
        },
        activeDivision(){
            for (const item of this.divisions) {
                var start;
                var end;

                start = this.getValueByPercentage(item.start)
                end = this.getValueByPercentage(item.end)

                if(start <= this.sliderValue&& this.sliderValue <= end) return item
            }
            return false
        },
        processColor() {
            var division = this.activeDivision
            if(division){
                return division.color
            }
            return '#0096884d'
        },
        railColor(){
            const divisions = this.divisions
            if(divisions.length > 0){
                var grad = "-webkit-linear-gradient(left, "
                for(let division of divisions){
                    var {color, start, end} = division
                    grad += `${color}30 ${start}%, ${color}30 ${end}%, ` 
                }
                return `${grad.slice(0, -2)})`
            }
            return '#ffffff0d'
        },
        lightColor(){
            var division = this.activeDivision
            if(division){
                return this.adaptColor(0.5, division.color)
            }
            return '#42ccbf'
        },
        darkColor(){
            var division = this.activeDivision
            if(division){
                return this.adaptColor(-0.5, division.color)
            }
            return 'black'
        }
    },
    methods:{
        getValueByPercentage(percentage) {
            const clamp = Math.max(0, Math.min(percentage, 100));

            const range = this.max - this.min;
            const value = this.min + (clamp / 100) * range;

            return value;
        },

        adaptColor(p,c0,c1,l){
            let r,g,b,P,f,t,h,i=parseInt,m=Math.round,a=typeof(c1)=="string";
            if(typeof(p)!="number"||p<-1||p>1||typeof(c0)!="string"||(c0[0]!='r'&&c0[0]!='#')||(c1&&!a))return null;
            if(!this.pSBCr)this.pSBCr=(d)=>{
                let n=d.length,x={};
                if(n>9){
                    [r,g,b,a]=d=d.split(","),n=d.length;
                    if(n<3||n>4)return null;
                    x.r=i(r[3]=="a"?r.slice(5):r.slice(4)),x.g=i(g),x.b=i(b),x.a=a?parseFloat(a):-1
                }else{
                    if(n==8||n==6||n<4)return null;
                    if(n<6)d="#"+d[1]+d[1]+d[2]+d[2]+d[3]+d[3]+(n>4?d[4]+d[4]:"");
                    d=i(d.slice(1),16);
                    if(n==9||n==5)x.r=d>>24&255,x.g=d>>16&255,x.b=d>>8&255,x.a=m((d&255)/0.255)/1000;
                    else x.r=d>>16,x.g=d>>8&255,x.b=d&255,x.a=-1
                }return x};
            h=c0.length>9,h=a?c1.length>9?true:c1=="c"?!h:false:h,f=this.pSBCr(c0),P=p<0,t=c1&&c1!="c"?this.pSBCr(c1):P?{r:0,g:0,b:0,a:-1}:{r:255,g:255,b:255,a:-1},p=P?p*-1:p,P=1-p;
            if(!f||!t)return null;
            if(l)r=m(P*f.r+p*t.r),g=m(P*f.g+p*t.g),b=m(P*f.b+p*t.b);
            else r=m((P*f.r**2+p*t.r**2)**0.5),g=m((P*f.g**2+p*t.g**2)**0.5),b=m((P*f.b**2+p*t.b**2)**0.5);
            a=f.a,t=t.a,f=a>=0||t>=0,a=f?a<0?t:t<0?a:a*P+t*p:0;
            if(h)return"rgb"+(f?"a(":"(")+r+","+g+","+b+(f?","+m(a*1000)/1000:"")+")";
            else return"#"+(4294967296+r*16777216+g*65536+b*256+(f?m(a*255):0)).toString(16).slice(1,f?undefined:-2)
        },
    },
    watch:{
        sliderValue(){
            this.$emit("change", this.sliderValue)
        },
    }
}
</script>

<style scoped lang="scss">
    .slidecontainer {
    width: 100%; /* Width of the outside container */
    }

    /* The slider itself */
    .slider {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        border-radius: 50px;
        height: 15px;
        background: #ffffff0d;
        border: 0.1px solid rgba(255, 255, 255, 0.3);
        outline: none;
        opacity: 0.7;
        transition: opacity .2s;
    }

    /* Mouse-over effects */
    .slider:hover {
    opacity: 1; /* Fully shown on mouse-over */
    }

    /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
    .slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #10627e; /* Green background */
    border: 2px solid #22b0df;
    border-radius: 100%;
    cursor: pointer; /* Cursor on hover */
    &:active{
        background: #22b0df;
    }
    }

    .slider::-moz-range-thumb {
        width: 25px; /* Set a specific slider handle width */
        height: 25px; /* Slider handle height */
        background: #10627e; /* Green background */
        border: 2px solid #22b0df;
        border-radius: 100%;
        cursor: pointer; /* Cursor on hover */
        &:active{
            background: #22b0df;
        }
    }

    .form
    {
        min-height: 0px;
    }
    .form > input{
        border: 0.1px solid rgb(217 217 217 / 89%);
    }
    input.form-control {
        background: #ffffff0d;
        color: white;
    }
    input:focus{
        background-color: var(--background-color);
        color: white;

    }
    label{
        text-align: left;
        color: white;
        opacity: 0.75;
    }
    .input{
        height: 3rem;
        background-color: rgba(255, 255, 255, 0.05);
        padding: 1rem;
        border: 0.1px solid rgba(255, 255, 255, 0.3);
        color: white;
        border-radius: 8px;
    }
    .required{
        color: rgba(255,96,96);
    }
    .input-group-text {
        border: 1px solid white !important;
    }
</style>