<template>
    <div>
      <button id="notificationButton" title="Notificações" v-b-tooltip.hover>
          <b-icon-inbox/>
          <span class="newNotificationsIndicator" v-if="hasNewNotifications"/>
        </button>
      <b-popover target="notificationButton" custom-class="notification-popover" triggers="click" placement="bottomleft" @hidden="closeNotificationPopover">
        <section class="notificationPopoverHeader" >
          <div class="w-100 d-flex align-items-center justify-content-between">
            <strong>Notificações</strong>
            <button :class="['notificationToasts', {'enabled': isToastsEnabled}]" @click="toggleToastEnabled">
              <b-icon-menu-button-wide/>
            </button>
          </div>
          <hr>
        </section>
        <section class="notificationPopoverBody">
          <ul class="notificationPopoverList" v-if="orderedNotifications.length > 0">
            <li v-for="item of orderedNotifications" :key="item.eventId" :class="{'showNewNotificationAnimation': !item.read}">
              <section class="notificationHeader">
                <div class="headerLeftSide">

                  <span v-if="!item.read" class="wasVisualized" />

                  <span class="headerBadge alarm" v-if="item.type == 'alarm'"> <b-icon-bell-fill/> Alarme</span>
                  <span class="headerBadge status" v-else> <b-icon-plug-fill/> Status</span>

                  <span :class="['headerBadge', getConditionBadgeClass(item)]">{{$i18n.t(item.condition)}}</span>

                </div>
                <i class="notificationDate">{{item.datetime}}</i>
              </section>
              <section class="notificationBody">
                <strong>{{item.name}}</strong>
                <section class="alarmComment" v-if="isActiveAlarmNotification(item)">


                  <b-icon-chat-dots-fill v-b-tooltip.hover :title="`Comentário: ${item.comment}`" v-if="item.hasComment"/>

                  <button title="Adicionar comentário ao evento" class="addCommentToActiveAlarmNotification" v-else-if="isLastInstanceOfActiveAlarm(item)" @click="addComment(item)">
                    <b-iconstack>
                      <b-icon-chat-fill />
                      <b-icon-plus-lg scale="0.4"/>
                    </b-iconstack>
                  </button>

                  <button class="dismissEventButton" v-if="!isLastInstanceOfActiveAlarm(item) || item.hasComment" @click="dismissEvent(item)"><b-icon-x-lg/></button>

                </section>
              </section>
            </li>
          </ul>
          <div class="noEventContainer" v-else>
            <b-icon-x-octagon-fill/>
            <strong>Sem eventos registrados</strong>
          </div>
        </section>
      </b-popover>
    </div>
</template>
<script>
export default {
    data(){
        return{
            notifications: [], 
            isToastsEnabled: true,
        }
    },
    methods:{

      closeNotificationPopover(){
        const updatedItems = []
        for(const item of this.notifications){
          if(!item.read) item.read = true
          updatedItems.push(item)
        }
        this.notifications = updatedItems
      },

      getConditionBadgeClass(notification){
        const { type, condition } = notification
        let classes  = {active: false, normal:false, online:false, offline:false}
        if(type == "alarm"){
          if(condition == "active") classes.active = true
          else classes.normal = true
        }else {
          if(condition == "online") classes.online = true
          else classes.offline = true
        }
        return classes
      },

      isActiveAlarmNotification(notification){
        const { type, condition } = notification
        return type == "alarm" && condition == "active"
      },

      async addAlarmEventToList(alarmEvent){
        const { datetime, name, id, idDb, eventId, value} = alarmEvent
        const condition = value == 2 ? "active" : "normal"
        const type = "alarm"

        let payload = {
          type,
          eventId, 
          id,
          idDb,
          name,
          datetime: this.fixTimezone(datetime),
          read:false,
          condition,
          show: true,
        }

        if(this.isActiveAlarmNotification({type, condition})){
          const {hasComment, comment} = await this.checkIfEventHasComment(payload)
          payload = {...payload, hasComment, comment}
        }else{
          payload.hasComment = false
          payload.comment = ""
        }


        this.notifications.push(payload)

      },

      dismissEvent(event){
        event.show = false
      },

      async checkIfEventHasComment(event){
        const { id, idDb} = event
        return this.alarmHistoryService.getAlarmComment(id, idDb).then(comments => {
          if(comments.length == 0) return { hasComment:false, comment: ""}
          else return { hasComment: true, comment: comments[0].description}
        })
      },

      async addComment(notification){
        const { name, id, idDb, eventId } = notification
        const item = this.notifications.find(i => i.eventId == eventId)
        if(item == undefined) return 
        
        this.$commentAlarmModal.show(name, id, idDb).then(message => {
          item.hasComment = true
          item.comment = message
        })

        this.$bvModal.show("commentModal")
        
      },

      isLastInstanceOfActiveAlarm(event){
        const activeAlarms = this.notifications.filter(n => n.condition == "active")
        const last = activeAlarms[activeAlarms.length - 1]
        return last.eventId == event.eventId && last.id == event.id
      },


      toggleToastEnabled(){
        this.isToastsEnabled = !this.isToastsEnabled
        this.$enableToasts.setIsToastEnabled(this.isToastsEnabled ? "1" : "0")
      },

      fixTimezone(dateString){
        const [day, month, yearAndTime] = dateString.split('/');
        const [year, time] = yearAndTime.split(' ');
        const [hours, minutes] = time.split(':');

        const date = new Date(Date.UTC(year, month - 1, day, hours, minutes));
        const timezone = new Date().getTimezoneOffset() / 60
        date.setHours(date.getHours() - timezone);

        const adjustedDay = String(date.getUTCDate()).padStart(2, '0');
        const adjustedMonth = String(date.getUTCMonth() + 1).padStart(2, '0'); // Month is zero-indexed
        const adjustedYear = date.getUTCFullYear();
        const adjustedHours = String(date.getUTCHours()).padStart(2, '0');
        const adjustedMinutes = String(date.getUTCMinutes()).padStart(2, '0');

        return `${adjustedDay}/${adjustedMonth}/${adjustedYear} ${adjustedHours}:${adjustedMinutes}`;
      },

    },
    computed:{
      hasNewNotifications(){
        return this.notifications.filter(item => item.read == false).length >= 1
      },

      orderedNotifications() {
        const uniqueNotifications = this.notifications.filter(a => a.show).reduce((acc, current) => {
          const existing = acc.find(item => item.eventId === current.eventId);

          if (!existing) {
            acc.push(current);
          } else if (!existing.hasComment && current.hasComment) {
            acc = acc.filter(item => item.eventId !== current.eventId);
            acc.push(current);
          }

          return acc;
        }, []);
        return uniqueNotifications.sort((a, b) => b.eventId - a.eventId)
      },
    },
  mounted(){
    this.alarmHistoryService = new this.$alarmHistoryService()
    this.isToastsEnabled = this.$enableToasts.getIsToastsEnabled()
    this.refreshInterval = setInterval(()=>{
      //console.log(window.Alarm);
      
      const alarmEvents = window.Alarm.GetAlarmEvents() || []
      for (const event of alarmEvents){
        const isActive  = event.value == 2
        const isAlreadyInList = this.notifications.find(n => n.eventId == event.eventId) != undefined
        if(!isAlreadyInList && isActive){
          this.addAlarmEventToList(event)
        }
      }
    },1000)
  },


}
</script>

<style scoped lang="scss">
#notificationButton{
  background-color: transparent;
  border: none;
  color: white;
  color: rgb(var(--tmHubTheme-light-color));
  opacity: 0.5;
  font-size: 1.25rem;
  padding: 0.5rem;
  height: 100%;
  border-radius: 0.25rem;
  transition: 0.5s ease;
  border: 1px solid;
  height: max-content;
  padding: 0.125rem 0.5rem !important;

  &:hover{
    transition: opacity 0.5s ease;
    opacity: 1;
  }
  .newNotificationsIndicator{
    height: 0.65rem;
    width: 0.65rem;
    position: absolute;
    background: #6e79ff;
    top: 0.75rem;
    border-radius: 1rem;
  }
}
</style>

<style lang="scss">
.notification-popover{
  width: 30rem;
  max-width: none;
  background: transparent;
  border: none;
  .arrow{
    display: none;
  }
  .popover-header{
    display: none;
  }
  .popover-body{
    min-height: 10rem;
    height: max-content;
    max-height: 65vh;
    overflow: auto;
    border-radius: 1rem;
    width: 100%;
    background-color: #28313e;
    padding: 0;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    border: 1px solid #3f4b5d;
    .notificationPopoverHeader{
      color: #b6c2cf;
      font-size: 1.5rem;
      padding: 1.5rem 1rem 1rem;
      hr{
        margin: 0;
        margin-top: 1rem;
        height: 0.01em;
        background-color: #b6c2cf;
      }
      .notificationToasts{
        background-color: #83353b;
        &.enabled{
          background-color: #358347;
        }
        border: none;
        font-size: 1rem;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2rem;
        width: 2rem;
        border-radius: 2rem;
        opacity: 0.6;
        transition: opacity 0.2s ease, background-color 0.2s ease-in-out;
        &:hover{
          opacity: 1;
          background-color: #358347;
          &.enabled {
            background-color: #83353b;
          }
        }
      }
    }
    .notificationPopoverBody{
      width: 100%;
      padding: 0.125rem;
      padding-bottom: 1rem;
      ul.notificationPopoverList{
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin: 0;
        list-style: none;
        width: 100%;
        li{
          position: relative;
          display: flex;
          flex-direction: column;
          height: 4rem;
          width: 100%;
          padding: 0.25rem 1rem;
          border-left: 2px solid transparent;
          background-color: transparent;
          .notificationHeader{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .headerLeftSide{
              width: 100%;
              display: flex;
              align-items: center;
              .wasVisualized{
                display: flex;
                height: 8px;
                width: 8px;
                border-radius: 8px;
                background-color: #6e79ff;

                position: absolute;
                left: 0.5rem;
              }
              .headerBadge {
                color: white;
                font-size: 0.85em;
                scale: 0.8;
                padding: 0.05rem 0.4rem;
                background: #5a5b66;
                border-radius: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.25rem;
                border: 1px solid transparent;
                &.active{
                  background: #ff000052;
                  border: 1px solid red;
                }
                &.normal{
                  background-color: #41863d75;
                  border: 1px solid #41863d;
                }
                &.online {
                  background-color: #6d8ef4ba;
                  border: 1px solid #6d8ef4;
                }
                &.offline {
                  background-color: #0e0e0eb0;
                  border: 1px solid #db7d23;
                }
  
              }
            }

            .notificationDate{
              width: 100%;
              text-align: end;
              color: #a3aeba;
              font-size: 0.8em;
            }
          }
          .notificationBody{
            flex: 1;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: white;
            strong{
              font-size: 1.2em
            }
            .alarmComment{
              color: #aeb9c6;

              .addCommentToActiveAlarmNotification{
                height: 1.5rem;
                width: 1.5rem;
                font-size: 1.2em;
                border-radius: 0.25rem;
                border: none;
                background-color: transparent;
  
                .bi-chat-fill{color: #aeb9c6}
                .bi-plus-lg{color: black}
  
                &:hover{
                  .bi-chat-fill{color: #bc7982}
                  .bi-plus-lg{color: #310a0a}
                }
              }

              .dismissEventButton{
                  display: none;
                  margin-left: 0.25rem;
                  color: white;
                  background: transparent;
                  border: none;
                  transition: color 0.2s ease;
                  &:hover{
                    color: #f27f7f;

                  }
              }

            }
          }

          &.showNewNotificationAnimation{
            animation: blinkNewNotification 0.5s linear forwards;
          }
          &:not(:last-child):after{
            content: "";
            position: absolute;
            display: flex;
            height: 1px;
            width: calc(100% - 2.125rem);
            background: #a3aeba42;
            bottom: -0.25rem;
          }

          &:hover{
            border-left-color: #6e79ff;
            background-color: #6e79ff30 !important;

            .notificationBody > .alarmComment >  .dismissEventButton{
              display: initial;
            }
          }
        }
      }

      .noEventContainer{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #b6c2cf;
        gap: 1rem;
        flex-direction: column;
        padding: 1rem 0;
      }
    }
  }
}

@keyframes blinkNewNotification {
  0% {
      background-color: transparent;
  }
  50% {
      background-color: #3f47a0;
  }
  100% {
      background-color: transparent;
  }
}
</style>