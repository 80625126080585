<template>
    <b-form-group :input-name="name" class="form w-100" :class="{'disabled': disabled}" :invalid-feedback="invalidMessage" :state="validate ? state : null"
        :required="required && !disabled">
        <div class="d-flex ">
            <span class="infoIcon mr-1" v-b-tooltip.top tabindex="0" :title="infoText" v-if="infoText.length > 0">
                <b-icon-info-circle-fill/>
            </span>
            <label v-if="label.length" id="label" :for="inputId" class="d-flex align-items-center">
                <b-icon v-if="icon.length" :icon="icon" class="ml-1 mr-1"/>{{ label }} <span class="required" v-if="required && !disabled" title="required">*</span>
            </label>
        </div>
        <b-input-group size="lg" :append="append" :prepend="prepend" :class="inputClass">
            <b-form-input :type="type" :disabled="disabled" style="text-overflow: ellipsis" :class="{ 'hasAppend': hasTextAppend }"
                :state="validate ? state : null" :style="disabledColor" :id="inputId" v-model="input" @keyup="emitChanges"
                @change="exitFieldEmit" required :min="min" :max="max" :step="step" :value="value" :maxlength="maxlength" trim @focus="focusInput" @blur="blurInput"></b-form-input>
        </b-input-group>

    </b-form-group>
</template>

<script>
//import SimpleKeyboard from "./SimpleKeyboard";

export default {
    data() {
        return {
            input: this.value
        }
    },
    props: {
        label: {
            type: String,
            required: false,
            default: ''
        },
        name: {
            type: String,
            required: true
        },
        invalidMessage: {
            type: String,
            required: false,
            default: ''
        },
        validate: {
            type: Boolean,
            required: false,
            default: false
        },
        isValid: {
            type: Boolean,
            required: false,
            default: null
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        type: {
            type: String,
            required: false,
            default: "text"
        },
        value: {
            type: [String, Number, Boolean, Date],
            required: false,
            default(props) {
                if (props == undefined) return 0
                else return props
            }
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        min: {
            type: Number,
            required: false,
            default: null
        },
        max: {
            type: Number,
            required: false,
            default: null
        },
        step: {
            type: Number,
            required: false,
        },
        append: {
            type: String,
            required: false,
            default: ''
        },
        prepend: {
            type: String,
            required: false,
            default: ''
        },
        infoText:{
            type:String,
            required:false,
            default: ''
        },
        limitChars:{
            type: Number,
            required:false,
            default: 0
        },
        inputClass:{
            required: false,
            default: '',
            type:String
        },
        icon:{
            type:String,
            required:false,
            default: ''
        }
    },
    computed: {
        maxlength(){
            return this.limitChars == 0 ? Infinity : this.limitChars  
        },
        state() {
            return this.isValid
        },
        inputId() {
            return this.name + "Input"
        },
        disabledColor() {
            if (this.disabled) return { "--background-color": "#32323233" }
            else return { "--background-color": "#ffffff0d" }
        },
        hasTextAppend() {
            return this.append != ''
        }
    },
    methods: {

        focusInput(e){
            e.target.addEventListener("keydown", this.stopEvent)
        },
        blurInput(e){
            e.target.removeEventListener("keydown", this.stopEvent)
        },

        stopEvent(e){
            if(e.shiftKey && (e.keyCode === 78 || e.keyCode === 73)){
                e.stopPropagation()
                return
            }
        },

        emitChanges() {
            // if(this.type == 'number') this.blockValueInMinMaxRange()
            this.$emit("changeValue", this.input)
        },

        exitFieldEmit() {
            if(this.type == 'number') this.blockValueInMinMaxRange()
            this.$emit("changeValue", this.input, this.name)
            this.$emit("exitField", this.input)

        },


        blockValueInMinMaxRange(){
            var value = parseInt(this.input) 
            if(this.min != undefined && value < this.min) this.input = this.min
            if(this.max != undefined && value > this.max) this.input = this.max 
        }
    },
    mounted() {
        setTimeout(() => {
            this.input = this.value
            this.loaded = true;
        }, 500);
    },
    watch: {
        state() {
            this.$el.classList.remove("animate__headShake")
            if (!this.state) {
                setTimeout(() => {
                    this.$el.classList.add("animate__headShake")
                }, 100)
            }
        },
        min(){
            this.blockValueInMinMaxRange()
        },
        max(){
            this.blockValueInMinMaxRange()
        },
    }
}
</script>

<style scoped lang="scss">

.disabled{
    opacity: 0.25;
    & > *{
        cursor: not-allowed !important;
    }
}
.infoIcon{
    color: #ff990097
}
.form {
    min-height: 0px;
    max-width: 90vw;
}

.text-input{
    text-overflow: ellipsis
}

.form>input {
    height: 3rem;
    background-color: var(--background-color);
    padding: 1rem;
    border: 0.1px solid rgb(217 217 217 / 89%);
    color: white;
    border-radius: 8px;
    width: 100%;
    text-overflow: ellipsis;
}

input.form-control {
    background: #ffffff0d;
    background: rgba(var(--tmHubTheme-text-contrast), 0.0509803922);
    color: white;
    color: rgb(var(--tmHubTheme-text-contrast));
    padding: 0 0.25rem !important;
    box-sizing: border-box;
    margin: 0 !important;
    text-align: center;
    border-color: rgb(var(--tmHubTheme-text-contrast))
}

input:focus {
    background-color: var(--background-color);
    color: white;
    color: rgb(var(--tmHubTheme-text-contrast));

}

input.hasAppend {
    text-align: end;
}

label {
    text-align: left;
    color: white;
    color: rgb(var(--tmHubTheme-text-contrast));;
    opacity: 0.75;
}

.input {
    height: 3rem;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 1rem;
    border: 0.1px solid rgba(255, 255, 255, 0.3);
    color: white;
    border-radius: 8px;
}

.required {
    color: rgba(255, 96, 96);
}

.input-group-text {
    border: 1px solid white !important;
}
</style>

<style lang="scss">
.form>div {
    width: 100%;
}

.invalid-feedback,
.is-invalid {
    position: relative !important;
    top: 0px !important;

    .d-block,
    .invalid-feedback {
        font-size: 1rem;
        color: #c43845;
        margin: 0;
        font-style: italic;
        font-weight: bolder;
        background: #b92e2e2b;
        opacity: 1;
        text-align: center;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
</style>