<template>
    <div class="w-100 h-100">
        <div class="bgColor animate__animated" @mouseenter="() => { this.isHovering = true }"
            @mouseleave="() => { this.isHovering = false }">
            <b-card ref="card" class="card" :class="{ 'backside': isBacksideActive, 'pipActive': isPIPactive }"
                footer-tag="footer" header-tag="header">

                <!-- Cabeçalho -->
                <template #header>
                    <div id="cardHeader" class="w-100 d-flex justify-content-between cardHeader">
                        <div class="d-flex flex-column justify-content-between nameContainer">
                            <h4 class="cardName" :title="name">{{ name }}</h4>
                        </div>
                    </div>
                </template>


                <!-- Corpo -->

                <!-- Mensagens -->

                <div v-if="!hasAccess && inGroup" class="isOffIndicator measurerAccessFail">
                    <h6>{{ $i18n.t('failedToLoadMeasurer') }} <strong>{{ name }}</strong></h6>
                    <h6 v-if="!accessStatus.display"><span>{{ $i18n.t('error') }}:</span>
                        {{ $i18n.t('userHasNoAccessToDisplay') }} "{{ info.name }}"</h6>
                    <h6 v-if="!accessStatus.variable"><span>{{ $i18n.t('error') }}:</span>
                        {{ $i18n.t('userHasNoAccessToVariable') }} "{{ info.variableName }}"</h6>
                </div>
                <div class="isOffIndicator" v-else-if="(!isVariableActive && inGroup) || isLoading">
                    <h1>{{ $i18n.t('disabled') }}</h1>
                </div>

                <!-- Medidor -->
                <card-body v-if="inMap || (!isLoading && hasAccess && isVariableActive)" class="cardFrontside"
                    @alarm="setAlarm" :alarmColors="alarmColors" :isMinimized="isPIPactive" :topic="topic"
                    :inGroup="inGroup" :value="value" :inputValue="inputValue" :info="info" />
                <div class="cardBackside" v-if="isBacksideActive">
                    <photo-motion :id="element.id" :source="element.videoConfig.rtspLink"
                        :realTime="element.videoConfig.realTime" />
                </div>
                <b-icon :icon="isPIPactive ? 'x-circle-fill' : 'pip-fill'" v-if="isBacksideActive" class="pipIcon"
                    @click="togglePIP" />




                <!-- Rodapé -->
                <template #footer v-if="isHovering || isPopoverOpen">
                    <div class="d-flex justify-content-center align-items-center w-100" style="gap: 1rem">

                        <span v-b-tooltip.hover :title="varDetails">
                            <b-icon-info-circle-fill />
                        </span>

                        <popover direction="top" :disabled="!inGroup" :selected="groupId" :title="$i18n.t('changeGroup')"
                            :id="popoverIdChangeGroup" @changeMeasurer="changeGroup" :info="dashboardGroups"
                            optionText="name" />
                        <b-icon-eye-fill :id="popoverIdChangeMeasurer" :title="$i18n.t('changeMeasurer')" />
                        <popover direction="top" :disabled="!inGroup" :selected="info.name" translate
                            :title="$i18n.t('changeMeasurer')" :id="popoverIdChangeMeasurer"
                            @changeMeasurer="changeMeasurer" :info="measurersList" optionText="text" />
                        <span v-b-tooltip.hover :title="timeLabel">
                            <b-icon-arrow-repeat />
                        </span>
                        <b-iconstack @click="toggleBackside" v-if="hasCamera">
                            <b-icon-back />
                            <b-icon :icon="isBacksideActive ? 'speedometer' : 'camera-fill'" font-scale="0.5" shift-v="-3"
                                shift-h="3" />
                        </b-iconstack>
                    </div>
                </template>

            </b-card>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import popover from "./CardPopover.vue"
import CardBody from "./CardBody.vue"

export default {
    components: {
        popover,
        CardBody,
    },

    props: {
        topic: {
            type: String,
            required: false,
            default: ""
        },
        info: {
            type: Object,
            required: true
        },
        color: {
            type: String,
            required: false,
            default: "#000000"
        },
        created: {
            type: Boolean,
            required: false,
            default: false
        },
        inGroup: {
            type: Boolean,
            required: false,
            default: false
        },
        inMap: {
            type: Boolean,
            required: false,
            default: false
        },
        inputValue: {
            type: undefined,
            required: false
        },
        measurerId: {
            type: Number,
            required: false,
            id: 0
        },
        dashboardId: {
            type: undefined,
            required: false,
            default: 0
        },
        groupId: {
            type: Number,
            required: false,
            default: 0
        },
        isShowingAllIcons: {
            type: Boolean,
            required: true
        },
        showInfoIcons: {
            required: false,
            default: false,
            type: Boolean,
        },
        hideFooter: {
            required: false,
            default: false,
            type: Boolean,
        },
        alarmColors: {
            required: false,
            type: Object
        },
        isTransparent: {
            required: false,
            type: Boolean,
            default: true,
        },
        playAlarmSound: {
            // Quando o medidor estiver posicionado no mapa e a tela do mapa estiver aberta, quem será responsável por tocar o som do alarme será o mapa
            required: false,
            type: Boolean,
            default: true,
        },
        searchVariableValueById: {
            type: Function || undefined,
            required: false,
            default: undefined,
        }
    },

    data() {
        return {
            isLoading: false,
            isPopoverOpen: false,
            isHovering: false,
            hasCamera: false,
            historyOpen: false,
            element: {},
            value: 0,
            time: '',
            measurersList: [
                { text: "gauge", value: 1, },
                { text: "digital-output", value: 2 },
                { text: "chart", value: 3 },
                { text: "text", value: 4 },
                { text: "semaphore", value: 5 },
                { text: "vertical", value: 6 },
                { text: "cylinder", value: 7 },
                { text: "barchart", value: 8 },
                { text: "table", value: 9 },
                { text: "thermometer", value: 10 },
                // {text: "linear-gauge", value: 11},
                { text: "digital-input", value: 12 }
            ],
            dashboardGroups: [],
            isVariableActive: false,

            activeAlarmList: [],
            activeAlarmColor: 'red',

            isBacksideActive: false,
            isPIPactive: false,


            accessStatus: { variable: true, display: true },
        }
    },

    computed: {
        name() {
            return this.info.cardName && this.info.cardName != '' ? this.info.cardName : this.$i18n.t("subtitlePlaceholderValue")
        },
        type() {
            return this.info.name == undefined ? "gauge" : this.info.name
        },
        unit() {
            return this.info.unit && this.info.unit != '' ? this.info.unit : this.$i18n.t("unit")
        },
        background() {
            var color = this.color == '#ffffff00' ? '#192834' : this.color
            if (this.isTransparent) return `${color}66`;
            return color
        },
        backgroundWithAlarm() {
            return `${this.activeAlarmColor}66`
        },
        textColor() {
            var c = this.background.substring(1);
            var rgb = parseInt(c, 16);
            var r = (rgb >> 16) & 0xff;
            var g = (rgb >> 8) & 0xff;
            var b = (rgb >> 0) & 0xff;

            var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
            var color
            if (luma < 150) color = 'white'
            else color = 'black'
            return color
        },
        header() {
            return `${this.color}`;
        },
        createdStyle() {
            if (this.created) {
                return {
                    '--max-Width': '30rem',
                }
            } else {
                return {
                    '--max-Width': 'none',
                }
            }
        },
        popoverIdChangeMeasurer() {
            const random = Math.random() * 34
            return `MeasurerPopover-${random.toString()}`
        },
        popoverIdChangeGroup() {
            const random = Math.random() * 34
            return `GroupPopover-${random.toString()}`
        },
        cardMaxHeight() {
            return { "--max-Height": this.inGroup ? "none" : "40rem" }
        },
        timeLabel() {
            if (this.time.length == 0 && this.time != undefined) return 'Nenhum dado recebido'
            return `${this.time.split(" ")[0]} ${this.time.split(" ")[1]}`
        },
        hasAccess() {
            let { variable, display } = this.accessStatus
            return variable && display
        }
    },

    methods: {

        toggleBackside() {
            this.isBacksideActive = !this.isBacksideActive
            if (!this.isBacksideActive) this.isPIPactive = false
        },
        togglePIP() {
            this.isPIPactive = !this.isPIPactive
        },
        setAlarm(isActive) {
            var { value, color, alarms } = isActive
            if (value) {
                this.activeAlarmColor = color
                this.activeAlarmList = alarms
                this.activeAlarm()
            }
            else this.removeAlarm()
        },

        activeAlarm() {
            this.$refs.card.classList.add('alarmActive')
            this.$el.querySelector('.animate__animated').classList.add("animate__headShake", 'animate__infinite')
            this.$emit("alarmActive", this.topic)
            //var audio = new Audio('../alarm.mp3');
            //if(this.playAlarmSound) audio.play();
        },
        removeAlarm() {
            this.activeAlarmList = []
            this.$refs.card.classList.remove('alarmActive')
            this.$el.querySelector('.animate__animated').classList.remove("animate__headShake", 'animate__infinite')
            this.$emit("removeAlarm", this.topic)
        },

        async getVariableInfo() {
            if (this.topic != "") {
                let id = this.topic.split("/")[2]
                const status = window.Status.GetStatusMapValue(this.topic)


                try {
                    if (status != 0) this.isVariableActive = status.isOnline
                    else {
                        this.isVariableActive = false
                    }
                } catch (err) {
                    this.value = 0
                    this.isVariableActive = false
                }
                if (!this.isVariableActive) return
                if (this.searchVariableValueById != undefined) {
                    var { value, time } = this.searchVariableValueById(id)

                    this.value = value
                    if (time) {
                        const [day, month, year, timeOfDay] = time.match(/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}:\d{2}:\d{2})/).slice(1);
                        const date = new Date(`${year}-${month}-${day}T${timeOfDay}Z`);
                        this.time = date.toLocaleString()
                    }

                }
                else {
                    await this.dataService.getValueByVariableId(id).then(el => {
                        if (el.length == 0) {
                            this.value = 0
                            this.time = ''
                        } else {
                            this.value = parseFloat(el[0].value.replace(',', '.'))
                            this.time = window.Variable.FixTimezone(el[0].datetime)
                        }

                    })
                }
            }
            else {
                this.value = 0
                this.isVariableActive = false
            }
        },

        async userHasAccessToVariableAndDisplay() {
            const varId = parseInt(this.topic.split("/")[2])
            const displayId = parseInt(this.info.id)



            var userId = this.$session.get('logged_id')

            this.variableService.listCompleteByUserId(userId).then(response => {
                this.accessStatus.variable = response.map(i => i.id).includes(varId)
            })

            this.displayService.listCompleteByUserId(userId).then(response => {
                this.accessStatus.display = response.map(i => i.id).includes(displayId)
            })
        },

        formatNumber(number) {
            if (number === 0) { return 0 }

            const magnitude = Math.floor(Math.log10(Math.abs(number))) + 1;
            const formattedNumber = number.toPrecision(Math.min(Math.max(magnitude, 3), 5));
            return parseFloat(formattedNumber);
        },

        updateDisplay() {
            this.displayService.update(this.info, this.$session.get("logged_id"))
        },


        changeMeasurer(measurer) {
            this.info.name = measurer.text
            // this.updateDisplay()
        },
        changeGroup(group) {
            this.$emit("changeGroup", group)
        },

        varDetails() {
            const id = this.element.variable.id;
            console.log(id);
            const variable = this.element.variable;
            console.log(variable)
            const module = parseInt(variable.module) + 1
            const { serialNumber, name } = variable.device
            const interf = variable.variableCode.code

            const t = (text) => this.$i18n.t(text)
            return `${t('device')}: ${name},\n${t('serialNumber')}: ${serialNumber},\n${t('variableCode')}: ${interf},\n${t('variable')}: ${variable.name},\n${t('module')}: ${module}`
        },

    },

    async mounted() {
        this.isLoading = true

        this.service = new this.$measurerService();
        this.displayService = new this.$displayService()
        this.dashboardService = new this.$dashboardService();
        this.dataService = new this.$dataService();
        this.variableService = new this.$variableService();
        this.$displayService = new this.$displayService();


        if (this.measurerId) {
            await this.service.search(this.measurerId).then(response => {
                console.log(response);
                this.element = response;
                this.info.unit = response.variable.unit
                this.info.variableName = response.variable.name

                if (this.element.videoConfig) {
                    let { id, ip, readPort } = this.element.videoConfig.videoServerConfig
                    if (id == -1) readPort = 8080
                    let name = this.element.videoConfig.name
                    let rtsp = ''
                    if (this.element.videoConfig.realTime)
                        rtsp = `http://${ip}:${readPort}/${name}`;
                    else
                        rtsp = `http://${ip}:${readPort}/video/${name}.m3u8`;
                    this.element.videoConfig.rtspLink = rtsp;
                    this.hasCamera = true
                }
            })
        }


        if (this.inMap) this.getVariableInfo() // Se tiver no mapa não precisa esperar 
        else await this.getVariableInfo()

        this.updateInterval = setInterval(this.getVariableInfo, 1000)

        this.userHasAccessToVariableAndDisplay()

        this.isLoading = false

        this.$root.$on('bv::popover::show', bvEventObj => {
            let id = bvEventObj.target.getAttribute('id')
            if (id == this.popoverIdChangeGroup || id == this.popoverIdChangeMeasurer) {
                this.isPopoverOpen = true
            }
        })
        this.$root.$on('bv::popover::hide', () => {
            this.isPopoverOpen = false
        })




    },

    beforeDestroy() {
        clearInterval(this.updateInterval)
    },
    watch: {
        isShowingAllIcons() {
            const footer = this.$el.querySelector(".card-footer")
            if (this.isShowingAllIcons) footer.classList.remove('hideBorder')
            else footer.classList.add('hideBorder')
        },
    }

}
</script>

<style scoped lang="scss">
.measurerAccessFail {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 0.5rem;

    strong {
        color: red;
        text-decoration: underline;
    }

    h6 {
        span {
            color: red;
        }
    }
}

.isOffIndicator {
    position: absolute;
    height: 100%;
    top: 0%;
    left: -1.25rem;
    width: calc(100% + 1.25rem);
    background: #000000b8;
    z-index: 10;
    display: flex;
    justify-content: center;
    cursor: not-allowed;
    align-items: center;
    backdrop-filter: blur(7px) saturate(172%);
}

.bgColor {
    background-color: v-bind(background);
    color: v-bind(textColor);
    width: 100%;
    height: 100%;
}

.card {
    max-height: var(--max-Height);
    height: 100% !important;
    width: 100%;
    border: 1px solid transparent;
    max-width: var(--max-Width);
    background:
        linear-gradient(to right, white 3px, transparent 3px) 0 0,
        linear-gradient(to right, white 3px, transparent 3px) 0 100%,
        linear-gradient(to left, white 3px, transparent 3px) 100% 0,
        linear-gradient(to left, white 3px, transparent 3px) 100% 100%,
        linear-gradient(to bottom, white 3px, transparent 3px) 0 0,
        linear-gradient(to bottom, white 3px, transparent 3px) 100% 0,
        linear-gradient(to top, white 3px, transparent 3px) 0 100%,
        linear-gradient(to top, white 3px, transparent 3px) 100% 100%;

    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-color: #00000036;
    perspective: 800px;

    .card-header {
        padding: 0
    }

    .cardHeader {
        position: relative;
        padding: 12px 12px 0px;
        background: linear-gradient(to right, white px, transparent 3px) 0 0, linear-gradient(to right, transparent 3px, transparent 3px) 0 100%, linear-gradient(to left, white 3px, transparent 3px) 100% 0, linear-gradient(to left, transparent 3px, transparent 3px) 100% 100%, linear-gradient(to bottom, white 3px, transparent 3px) 0 0, linear-gradient(to bottom, white 3px, transparent 3px) 100% 0, linear-gradient(to top, transparent 3px, transparent 3px) 0 100%, linear-gradient(to top, transparent 3px, transparent 3px) 100% 100%;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-color: v-bind(header);
    }

    .card-body {
        min-height: 13rem;
        align-items: center;
        position: relative;
        transition: transform 1500ms;
        transform-style: preserve-3d;

        .cardFrontside,
        .cardBackside {
            position: absolute;
            backface-visibility: hidden;
            height: 100%;
            top: 0;
            left: 0;
            width: 100%;
            transition: transform 1500ms;
            transform-style: preserve-3d;
        }

        .cardFrontside {
            padding: 0.3rem 0;
        }

        .cardBackside {
            transform: rotateY(180deg);
            height: 100%;
            width: 100%;
        }

        .pipIcon {
            z-index: 9;
            transform: rotateX(180deg);
            position: absolute;
            top: 2px;
            right: 2px;
        }
    }

    .hideBorder {
        border: none !important;
    }

    h4 {
        font-size: calc(1rem * (1/(0.85 * var(--zoom, 1))));
        white-space: nowrap;
        overflow: hidden;
    }

    h6 {
        font-size: 0.9rem;
        white-space: nowrap;
        overflow: hidden;
    }

    p {
        white-space: nowrap;
        overflow: hidden;
    }

    header {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    footer {
        padding: 0;
        height: 3rem;
        justify-content: center;
        display: flex;
    }

    header,
    footer {
        border-color: rgb(255, 255, 255, 0.1);
    }

    svg {
        cursor: pointer !important;
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }

        g {
            svg {
                opacity: 1 !important;
            }
        }
    }

    &.alarmActive {
        outline: 5px solid v-bind(activeAlarmColor);
        box-shadow: v-bind(activeAlarmColor) 0px 2px 8px 0px;
        outline-offset: -5px;
        animation: blinkAlarmBorder 0.75s ease-in-out alternate-reverse infinite;
        background-color: v-bind(backgroundWithAlarm);

        .cardHeader {
            background: v-bind(activeAlarmColor);
        }

        .cardBodyContainer {
            .iconsContainer {
                .alarmIcon {
                    color: v-bind(activeAlarmColor)
                }
            }
        }
    }

    &.backside {
        .card-body {
            .cardFrontside {
                transform: rotateY(180deg);
            }

            .cardBackside {
                transform: rotateY(0deg);
            }
        }

        &.pipActive {
            .card-body {

                .cardFrontside,
                .cardBackside {
                    backface-visibility: initial;
                }

                .cardFrontside {
                    z-index: 1;
                    transform: rotateY(0deg) scale(0.33);
                    top: -33.25%;
                    left: 33.25%;
                    border: 0.5rem solid #292b31;
                    background-color: rgba(4, 15, 37, 0.81);
                    position: absolute;
                    border-radius: 1rem 0 0 1rem;
                    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
                }
            }

            .pipIcon {
                color: #ff0404;
            }
        }
    }
}

.nameContainer {
    width: 100%;

    .cardName {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.measurerContainer {
    overflow: hidden;
}

h5 {
    span {
        color: #af7213;
    }
}

@keyframes blinkAlarmBorder {
    0% {
        outline: 5px solid v-bind(activeAlarmColor)
    }

    100% {
        outline: 5px solid v-bind(backgroundWithAlarm)
    }
}
</style>

<style>
.vue-resizable-handle,
.card-footer,
.card-header {
    z-index: 20;
}
</style>