import axios from "axios";
export class ApiController{
   static BASE_URL = "http://10.53.24.79:8085"

   static GetServers(){
       return [
           {name: "localhost", url: "http://localhost:5078"},
           {name: "cloud", url: "http://172.104.199.107:8085"},
           {name: "AEON", url: "http://10.53.24.79:8085"},
           //{name: "133", url: "http://172.16.2.133:8085"},
           {name: "HUPE", url: "http://192.168.100.200:8085"},
           //{name: "HUPE testes", url: "http://192.168.100.251:8085"},
           //{name: "HUPE testes local", url: "http://192.168.100.200:8080"},
           //{name: "local", url: "http://172.16.2.193:8085"}
       ]
   }

   static API = axios.create({
       baseURL: ApiController.BASE_URL
   });

   static ChangeApiURL(newUrl){
       ApiController.BASE_URL = newUrl;
       ApiController.API.defaults.baseURL = newUrl;
   }

   static GetServerName(serverURL){
       return ApiController.GetServers().find(s => s.url === serverURL)?.name;
   }
   static GetServerURL(serverName){
        return ApiController.GetServers().find(s => s.name === serverName)?.url;
   }

}

export default ApiController.API;

