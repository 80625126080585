<template>
  <div class="">
    <button type="button" id="themePicker" :class="{'open': isOpen}" :style="selectedColor">
        <b-icon-palette-fill/>
    </button>

    <b-popover target="themePicker" custom-class="themePopover" triggers="click blur" placement="bottom" @show="popoverShown" @hide="popoverHiden">
        <ul class="colorOptionList themeSelectItemContainer" listName="Tema">
            <li class="colorOptionContainer" v-b-tooltip.hover :title="color.id" @click="setTheme(color.id)" :class="{'selected': color.selected}" v-for="color in colors" :key="color.id">
                <span class="colorOption" :style="getOptionColor(color)"></span>
            </li>
        </ul>
        <ul class="imagePickerContainer themeSelectItemContainer" listName="Imagem de Fundo">
            <li class="imagePreview" v-for="(image, index) of images" :key="index" :style="{'--image-url': `url(${image.url})`}" :class="{'selected': image.selected,'hasImage': image.url.length > 0}" @click="change(image)">
                <b-icon-x v-if="image.url.length == 0"/>
            </li>
        </ul>
        <ul v-if="textures.length >= 1" class="texturePickerContainer themeSelectItemContainer" listName="Textura">
            <li class="texturePreview" v-for="texture of textures" :key="texture.id" :style="getTextureStyle(texture)"></li>
        </ul>
    </b-popover>
  </div>
</template>

<script>
/* eslint-disable */
export default {
    data(){
        return {
            isOpen: false,

            textures: [],

            images: [],
            colors: []
        }
    },

    methods:{

        getTextureStyle(texture){
            return {'--texture': `url(${texture.url})`}
        },

        change(image){
            if(image.url.length > 0){
                this.images = this.$theme.setThemeImage(image.id)
            }else{
                return
            }
        },
        setTheme(id){
            this.colors = this.$theme.setThemeColor(id)
        },
        popoverShown(){
            this.isOpen = true;
        },
        popoverHiden(){
            this.isOpen = false;
        },
        getOptionColor(color){
            return {"--option-color": color.color}
        }
    },

    computed: {
        selectedColor(){
            return {"--selected-color": this.colors.find(color => color.selected)?.color || "white"}
        }
    },

    mounted(){

        this.colors = this.$theme.getColors()

        this.images = this.$theme.getImages();


        const html = document.querySelector("html")
        const observer = new MutationObserver((mutationsList, observer) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'theme') {
                    var changedTo = html.getAttribute('theme') || "default"
                    this.colors = this.colors.map(color => {
                        color.selected = color.id == changedTo
                        return color
                    })
                }
            }
        });

        observer.observe(html, { attributes: true });
    }


}
</script>

<style scoped lang="scss">
    #themePicker{
        height: 1.75rem;
        width: 1.75rem;
        border-radius: 50%;
        background-color: var(--selected-color);
        border: 2px solid rgba(var(--tmHubTheme-text-contrast), 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.25s ease;

        svg{
            opacity: 0;
            color: rgb(var(--tmHubTheme-text-contrast));
            font-size: 0.75rem;
            transition: 0.25s ease;
        }
        &:hover, &.open{
            border-color: rgb(var(--tmHubTheme-text-contrast));
            svg{
            opacity: 1;
            }
        }
    }
</style>
<style lang="scss">
.themePopover{
  background: transparent;
  border:none;
  .arrow{
    display: none;
  }
  .popover-body{
    backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(var(--tmHubTheme-darken-primary-color), 0.75);
    color: rgb(var(--tmHubTheme-light-color));
    height: max-content;
    width: 18rem;
    border-radius: 0.25rem;
    border: 1px solid rgba(var(--tmHubTheme-primary-color), 0.5);
    box-shadow: rgba(var(--tmHubTheme-primary-shadow-color), 0.3) 0px 19px 38px, rgba(var(--tmHubTheme-primary-shadow-color), 0.22) 0px 15px 12px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    padding-top: 1.25rem;

    .themeSelectItemContainer{
        position: relative;
        width: 100%;
        -webkit-box-shadow: inset 0px 0px 0px 1px rgb(255, 255, 255, 0.5);
        -moz-box-shadow: inset 0px 0px 0px 1px rgb(255, 255, 255, 0.5);
        box-shadow: inset 0px 0px 0px 1px rgb(255, 255, 255, 0.5);
        background: linear-gradient(to right, white 2px, transparent 2px) 0 0,
            linear-gradient(to right, white 2px, transparent 2px) 0 100%,
            linear-gradient(to left, white 2px, transparent 2px) 100% 0,
            linear-gradient(to left, white 2px, transparent 2px) 100% 100%,
            linear-gradient(to bottom, white 2px, transparent 2px) 0 0,
            linear-gradient(to bottom, white 2px, transparent 2px) 100% 0,
            linear-gradient(to top, white 2px, transparent 2px) 0 100%,
            linear-gradient(to top, white 2px, transparent 2px) 100% 100%;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        padding: 1rem 0.125rem;
        padding-top: 1.25rem;
        list-style: none;
        &:before {
            content: attr(listName);
            position: absolute;
            left: 50%;
            top: -0.5rem;
            font-size: 0.75rem;
            opacity: 1;
            z-index: 2;
            transform: translateX(-50%);
            color: black;
            background: #889098;
            font-weight: bold;
            padding: 0 0.5rem;
            text-align: center;
        }
    }

    .colorOptionList{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        margin: 0;
        width: 100%;
    }

    .imagePickerContainer{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        font-size: 0.75rem;
        text-align: center;
        gap: 0.25rem;
        .imagePreview{
            height: 3rem;
            width: 4rem;
            border-radius: 0.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #00000030;
            font-size: 4rem;
            color: #e0e2e41f;
            position: relative;
            &.hasImage{
                background-image: var(--image-url);
                background-size: 100% 100%;
                cursor: pointer;
            }
            &.selected:before{
                content: "🗸";
                font-size: 1rem;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                position: absolute;
                border-radius: 0.5rem;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                background: #28242447;
                color: white;
                align-items: center;
                backdrop-filter: blur(2px) saturate(180%);
            }
        }
    }

    .colorOptionContainer{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      width: 100%;
      transition: 0.2s ease;
      .colorOption{
        height: 1.75rem;
        width: 1.75rem;
        /*border-radius: 1.75rem;*/
        background: var(--option-color);
      }
      &.selected:before{
          content: "🗸";
          height: 1.75rem;
          width: 1.75rem;
          display: flex;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          background: #28242447;
          color: white;
          align-items: center;
          backdrop-filter: blur(16px) saturate(180%);
      }

      &:hover{
        cursor: pointer;
      }
    }

    .texturePickerContainer{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        margin: 0;
        width: 100%;

        .texturePreview{
            height: 4rem;
            width: 5rem;
            border-radius: 0.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background: black;
            font-size: 4rem;
            color: #e0e2e41f;
            background-image: var(--texture);
            background-size: 100%;
            position: relative;
            &.selected:before{
                content: "🗸";
                font-size: 1rem;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                position: absolute;
                border-radius: 0.5rem;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                background: #28242447;
                color: white;
                align-items: center;
                backdrop-filter: blur(2px) saturate(180%);
            }
        }

    }

  }
}
</style>