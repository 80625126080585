<template>
    <div class="w-100 h-100 d-flex cardBodyContainer" ref="body">
        <!-- Icones de Alarme, Histórico, Ação e Reação -->
        <div v-if="!isMinimized" class="iconsContainer" >

            <b-icon v-if="hasAlarms" :id="alarmPopoverTargetId" :icon="alarmIcon" :title="$i18n.t('alarm')" class="alarmIcon" />
            <alarm-popover v-if="hasAlarms && !isLoading" :target="alarmPopoverTargetId" :alarmList="alarmPopoverList"/>

            <span v-b-tooltip.hover :title="$i18n.t('calculationMeasurer')" @click="toggleHistory">
                <b-icon :icon="historyIcon" />
            </span>

            <b-iconstack v-if="sendingAction.length" :id="actionPopoverTargetId">
                <b-icon-lightning :title="$i18n.t('action')"/>
                <b-icon v-if="isSendingAction" icon="circle-fill" class="throbeAnimation" scale="2.3" shift-v="2"/>
            </b-iconstack>
            <action-popover sending v-if="sendingAction.length && !isLoading" :target="actionPopoverTargetId" :actionList="sendingAction"/>

            <b-iconstack v-if="receivingAction.length" :id="actionReceiverPopoverTargetId" :title="$i18n.t('reciveAction')" shift-v="10" >
                <b-icon-lightning-fill shift-h="3"   scale="0.75" stacked/>
                <b-icon-box         shift-v="-15" scale="1" stacked/>
                <b-icon v-if="isRecievingAction" icon="circle-fill" class="throbeAnimation" scale="2.3" shift-v="-10"/>
            </b-iconstack >
            <action-popover v-if="receivingAction.length && !isLoading" :target="actionReceiverPopoverTargetId" :actionList="receivingAction"/>


        </div>

        <!-- Medidor -->
        <div v-if="!historyOpen" 
        v-th-resizeCard="onCardResize"
        class="w-100 h-100 d-flex justify-content-center align-items-center measurerContainer">

            <alarm-indicator v-if="isIndicatorsVisible" ref="alarm" :info="info" :alarms="alarmList"/>
            <component 
            ref="measurer"
            :value="computedValue"
            :info="info" 
            :is="type"
            :divisionsColorList="divisionsColorList"
            :fullscreen="fullscreen"
            :topic="topic"
            />
            
        </div>

        <!-- Histórico -->
        <card-body-history v-else :isLoading="isLoadingHistory" :data="history" @requestData="requestHistory"/>
    </div>
</template>

<script>


import Gauge                    from './CardMeasurerGauge.vue'
import Vertical                 from './CardMeasurerVertical.vue'
import DigitalOutput            from './CardMeasurerDigital.vue'
import Text                     from './CardMeasurerText.vue'
import Chart                    from './CardMeasurerChart.vue'
import Semaphore                from './CardMeasurerSemaphore.vue'
import Thermometer              from './CardMeasurerThermometer.vue'
import LinearGauge              from './CardMeasurerLinearGauge.vue'
import Cylinder                 from './CardMeasurerCylinder.vue'
import Table                    from './CardMeasurerTable.vue'
import Barchart                 from './CardMeasurerBarchart.vue' 
import DigitalInput             from './CardMeasurerDigitalInput.vue'
import Slider                   from "./CardMeasurerSlider.vue"

import ActionPopover            from './CardActionPopover.vue'

import AlarmPopover             from './CardAlarmPopover.vue'
import AlarmIndicator           from './CardAlarmIndicator.vue'


import CardBodyHistory          from './CardBodyHistory.vue'

export default {
    data(){
        return{
            alarm:              false,
            historyOpen:        false, 
            alarmList:             [],
            receivingAction:       [],
            sendingAction:         [],
            isSendingAction:    false,
            isRecievingAction:  false,
            isLoading:           true,

            isLoadingHistory:   false,
            history:         undefined
        }
    },

    props:{
        value: {
            type: Number || String,
            required: false,
            default: 80,
        },
        info:{
            type: Object,
            required: true
        },
        inputValue:{
            required:false,
        },
        fullscreen: {
            required:false,
            default:false,
            type:Boolean
        },
        inGroup:{
            type:Boolean,
            required:true,
            default: false
        },
        topic:{
            type:String,
            required:false,
            default: ''
        },
        isMinimized:{
            required:false,
            default: false,
            type: Boolean
        },
        alarmColors:{
            required: false,
            type:Object
        }
    },

    components:{
        CardBodyHistory,
        Gauge,
        Vertical,
        "text-measurer": Text,
        Chart,
        Semaphore,
        Cylinder,
        Barchart,
        "digital": DigitalOutput, 
        'table-measurer': Table,
        Thermometer,
        LinearGauge,
        DigitalOutput,
        DigitalInput,
        ActionPopover,
        AlarmIndicator,
        AlarmPopover,
        'analogic-output':Slider,

    },

    computed:{
        min(){
			return this.info.minimum != undefined ? this.info.minimum : 0
		},
		max(){
			return this.info.maximum  != undefined ? this.info.maximum : 100
		},
        hasAlarms(){
            return this.alarmList.length >= 1
        },
        alarmIcon(){
            return this.alarm ? "bell-fill" : "bell"
        },
        historyIcon(){
            return this.historyOpen ? "calculator-fill" : "calculator"
        },
        
        alarmPopoverTargetId(){
            var id = Math.floor(Math.random() * 1000 + 1)
            return "AlarmPopover-" + id + this.info.id
        }, 
        actionPopoverTargetId(){
            var id = Math.floor(Math.random() * 1000 + 1)
            return "ActionPopover-" + id + this.info.id
        },
        actionReceiverPopoverTargetId(){
            var id = Math.floor(Math.random() * 1000 + 1)
            return "ActionReciverPopover-" + id + this.info.id
        },
        alarmPopoverList(){
            return this.alarmList.filter(item => !item.hide) 
        },

        /** Verifica se o display tem um medidor associado a ele, caso contrário o selecionado será o gauge
         * Um display não tem nenhum medidor associado a ele quando ele ainda não foi criado.
         * Alem disso para os medidores de texto e tabela adiciona o sufixo '-measurer' para o navegador não se confundir
         * com os elementos padrão do HTML*/
        type(){
            const changeNameMeasurers = ["text", "table"] //Medidores que precisam ter seu nome modificado 
            if(this.info.type == undefined) return "gauge" // Caso nenhum medidor seja associado ao display, o medidor padrão a ser exibido será um gauge
            else if(changeNameMeasurers.includes(this.info.type)) return `${this.info.type}-measurer` //Adiciona sufixo
            return this.info.type // retorna o tipo do medidor
        },

        getPercentage() {
            // Garante que o valor esteja dentro do intervalo [minimo, maximo]
            let valor = Math.max(this.min, Math.min(this.computedValue, this.max));

            // Calcula a porcentagem com base no intervalo [minimo, maximo]
            const intervalo = this.max - this.min;
            const porcentagem = ((valor - this.min) / intervalo) * 100;

            return porcentagem;
        },


        /** Função para padronizar as divisões em 4 items: cor, começo, e texto fim*/
        divisionsColorList(){
			if(this.info.divisions != null && this.info.divisions.length >= 1){ // Verifica se há divisões criadas

                var color
                var text
                var value = this.getPercentage
                try{
                    const division = this.info.divisions.find(division => division.start <= value && value <= division.end)
                    color = division.color
                    text = division.text
                }catch{
                    var backupValue = this.findItemWithLargestEnd(this.info.divisions);
                    color = backupValue.color
                    text = backupValue.text
                }
                return [{start: this.min, end: this.max, color, text}]
			}else{
                //Se não ha divisoes criadas, retorna uma lista com divisões pré-determinadas, isso pode acontecer quando o usuário está criado o medidor 
				return [{start: 0, color: "red", end: 32},{start: 33, color: "yellow", end: 65},{start: 66, color: "green", end: 100}]
			}
		},

        /** Valida o valor pois ele pode ser lido um valor da telemetria ou simulado o valor na hora de criar um medidor */
        computedValue(){
            var value = 0
            try{
                value = this.inputValue ? parseFloat(this.inputValue) : (this.value);
                value = value.toFixed(2)
            }catch{value = 0}
            return parseFloat(value)
        },
        /** Função que determina se os indicadores serão, ou não, exibidos. */
        isIndicatorsVisible(){
            //Indica quais são os tipos de medidores que não apresentarão indicadores de alarme
            const hideIndicatorsInMeasurers = ["digital", "digital-input", "text-measurer", "table-measurer", "semaphore", "digital-output"]
            
            /** Retorna True se não estiver na lista e verifica se o medidor está num grupo ,
             * não sendo exibido no processo de criação e edição do medidor 
            */
            return !hideIndicatorsInMeasurers.includes(this.type) && this.inGroup  
        }
    },

    methods:{
        findItemWithLargestEnd(array) {
            if (array.length === 0) {
                return null;
            }
            let largestEndItem = array[0];
            for (let i = 1; i < array.length; i++) {
                if (array[i].end > largestEndItem.end) {
                    largestEndItem = array[i];
                }
            }
            return largestEndItem;
        },

        toggleHistory(){
            this.historyOpen = !this.historyOpen
        },
        async requestHistory(){
            const varId = this.topic.split("/")[2]
            const days = 7
            // Não fará novamente as requisiçõs se elas ainda estão sendo feitas
            if(this.isLoadingHistory) return
            this.isLoadingHistory = true
            await this.historyService
            .resume(varId, days)
            .then(res => {
                res.value['update'] = new Date().toISOString()
                this.history = res.value
            })
            .catch(e =>{
                this.history = undefined
                console.error(e)
            })
            this.isLoadingHistory = false
        },
        /*Posiciona e redimenciona os alarmes de acordo com a posição e tamanho do medidor */
        ajustAlarmPositionAndSize(width, height){
            try{
                //Primeiro verifica se o tipo de medidor possui indicadores de alarme e se não está exibindo o histórico
                if(this.isIndicatorsVisible && !this.historyOpen) this.$refs.alarm.updateEachAlarmPositionAndSize(width, height)
            }catch(e){ console.log(e) }
        },
        /*Função que captura os valores de altura e largura do card e passa para o medidor.
        Cada medidor tem sua forma de se redimencionar ao tamanho do card */
        onCardResize(width, height){
            // Ve se o elemento do medidor já foi criado 
            if(this.$refs.measurer){
                // A biblioteca vue-svg-gauge já o redimenciona sozinho, então não é necessário o redimencionar
                // O medidor só pode ser redimencionado quando estiver dentro de um grupo
                if(this.inGroup) this.$refs.measurer.resizeMeasurer(width, height) 
                this.ajustAlarmPositionAndSize(width, height) // ajusta as posições e dimensões do alarme junto com o medidor
            }
        },
        isAlarmsActive(){
            var alarms = this.alarmList.filter(alarm => !alarm.hide)
            var activeAlarms = []
            var recieving = []

            alarms.forEach(item => {
                var isActive = window.Alarm.GetAlarmMapValue(`alarm/${item.id}`).value == 2
                if(isActive) activeAlarms.push(item)
            })

            this.receivingAction.forEach(item => {
                var isRecieving = window.Alarm.GetAlarmMapValue(`alarm/${item.alarmId}`).value == 2
                if(isRecieving) recieving.push(item)
            })

            this.isRecievingAction = recieving.length >= 1
            this.isSendingAction = activeAlarms.length >= 1

            if(activeAlarms.length >= 1){
                this.alarm = true
                this.$emit("alarm", {value: true, color: activeAlarms[0].color, alarms: activeAlarms})
            }else {
                this.alarm = false
                this.$emit("alarm", {value: false, color: '', alarms: activeAlarms})
            }
        },
        /**
         * Função que verifica se algum alarme está ativo
         */
        listenToAlarms(){
            this.listenToAlarmsInterval = setInterval(this.isAlarmsActive, 500)
        },

        removeDuplicatesById(inputArray) {
            const uniqueItems = [];
            const idSet = new Set();

            for (const item of inputArray) {
                if (!idSet.has(item.id)) {
                    uniqueItems.push(item);
                    idSet.add(item.id);
                }
            }

            return uniqueItems;
        }
    },

    async mounted(){
        this.alarmService = new this.$alarmService();
        this.actionService = new this.$actionService();
        this.historyService = new this.$historyService();

        const variableId = parseInt(this.topic.split("/").pop())
        const actionsList = []
        await this.alarmService
        .listByUserId(this.$session.get('logged_id'))
        .then(async (alarms) => {
            for(var alarm of alarms){
                if(alarm.enable){
                    if(alarm.variable.id == variableId){
                        const alarmObject = {
                            id: alarm.id,
                            alarmRef: `alarm${alarm.id}`,
                            color: alarm.alarmPriorities ? this.alarmColors[alarm.alarmPriorities.name] : 'red',
                            alarmValue: alarm.highSetpoint,
                            alarmHigh: alarm.highSetpoint,
                            alarmLow: alarm.lowSetpoint,
                            alarmName: alarm.name,
                            alarmCondition: alarm.operator
                        }
                        this.alarmList.push(alarmObject)
                        if(alarm.operator == 106 || alarm.operator == 107){
                            var alarmObjectLowSetpoint = {alarmRef: `alarm${alarm.id}_2`,alarmValue: alarm.lowSetpoint, alarmName: alarm.name, color: this.alarmColors[alarm.alarmPriorities.name], hide: true}
                            this.alarmList.push(alarmObjectLowSetpoint)
                        }

                    }
                    if(alarm.actions != null && alarm.actions.length >= 1){
                        actionsList.push(alarm)
                    }
                    // Verifica se o alarme tem algum tipo de ação associado a ele.
                    if(alarm.actions && alarm.variable.id == variableId){
                        this.sendingAction = alarm.actions.map(action => {
                            return {id: action.id, name: action.name, alarmName: alarm.name, alarmId: alarm.id}
                        })
                    }
                }
            }
            this.isAlarmsActive()
        })

        await this.actionService
        .listByUserId(this.$session.get('logged_id'))
        .then(async (actions) => {
            actions.forEach(action =>{
                var actionAlarm = actionsList.find(a => a.actions.map(a => a.id).includes(action.id))
                if(actionAlarm != undefined && action.variable.id == variableId){
                    let alarm = actionAlarm
                    this.receivingAction.push({name: action.name, id: action.id, alarmName: alarm.name, alarmId: alarm.id})
                }
            })
        })

        
        this.isLoading = false
        this.listenToAlarms();

        // Espera os medidores carregarem
        setTimeout(()=>{
            this.ajustAlarmPositionAndSize()
        },100)

    },
    beforeDestroy(){
        clearInterval(this.listenToAlarmsInterval);
    },
    updated(){
        
        const measurerContainer = this.$refs.measurer
        //
        if(measurerContainer != undefined){
            // container envolvendo o medidor
            const measurer = measurerContainer.$el
            
            //Ajusta as dimenções do alarme quando o tipo de medidor muda ou exibe/oculta o histórico ou muda o tipo de medidor
            this.onCardResize(measurer.offsetWidth, measurer.offsetHeight)

            //Ajusta as posições do alarme quando o tipo de medidor mudaou exibe/oculta o histórico
            this.ajustAlarmPositionAndSize()
        }
    }
}
</script>

<style lang="scss" scoped>
    .cardBodyContainer{
        gap: 0.5rem;
        height: 100% !important;
        min-height: 12rem;
        min-width: 12rem;
        flex-direction: column-reverse;
        justify-content: center;
        .iconsContainer{
            padding: 1rem;
            height:auto;
            width:100%;
            display:flex;
            justify-content: space-around;
            align-items: center;
            gap: 1rem;
            svg{
                cursor: pointer;
            }
            svg:nth-child(4){
                transform: translateY(-0.55rem);
            }
        }
    }
    .measurerContainer{
        overflow: hidden;
    }
    .throbeAnimation > g > g{
        // stroke: white;
        transform-origin: center;
        animation: 0.75s infinite ease-in-out alternate actionIndicator
    }
    @keyframes actionIndicator{
        0%{
            opacity: 0.3;
            transform: scale(0.5)
        }100%{
            opacity: 0.5;
            transform: scale(1.5)
        }
    }
</style>

<style>
    .card-body{
        overflow-x: hidden;
    }
</style>
