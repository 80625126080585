import Vue from 'vue'

class CommentAlarmModalController {
    constructor() {
        this.name = "";
        this.id = 0;
        this.idDb = '';
        this.modalIsOpen = false;
        this.resolveCloseModal = () => {};
        this.rejectCloseModal = () => {};
    }

    show(name, id, idDb) {
        this.name = name;
        this.id = id;
        this.idDb = idDb;

        this.modalIsOpen = true;
        return new Promise((resolve, reject) => {
            this.resolveCloseModal = resolve;
            this.rejectCloseModal = reject;
        });
    }

    close(isSuccess, message = '') {
        this.name = "";
        this.id = 0;
        this.idDb = '';
        this.modalIsOpen = false;
        if (isSuccess) {
            this.resolveCloseModal(message);
        } else {
            this.rejectCloseModal(message);
        }
    }

    hasComment(id, idDb) {
        const service = new Vue.prototype.$alarmHistoryService();
        service.getAlarmComment(id, idDb).then(res => {
            console.log("[CommentAlarmModal]: \t", res.length);
        });
        return false;
    }

    async wasAlreadyCommented(id, idDb) {
        const service = new Vue.prototype.$alarmHistoryService();
        const hasComments = await service.getAlarmComment(id, idDb).then((res) => {
            return res;
        }).catch(e => {
            console.error(e);
            throw new Error(e);
            //return true;
        });
        return hasComments;
    }
}

var controller = new CommentAlarmModalController();
export default controller;
