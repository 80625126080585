<template>
    <b-form-group :input-name="name" :class="{ 'selectDisabled': disabled }" :disabled="disabled" :id="`form_${inputId}`"
        class="w-100 animate__animated" style="z-index: 1;" :required="required">
        <div class="d-flex w-100 justify-content-between align-items-start">
            <label id="label" :for="inputId" v-if="label.length > 0">
                <span class="infoIcon" v-b-tooltip.hover :title="infoText" v-if="infoText.length > 0">
                    <b-icon-info-circle-fill />
                </span> {{ label }} <span class="required" v-if="required" title="required">*</span>
            </label>

            <div ref="detailsButton" class="detailsButton" v-on:click="ondblclick" v-if="hasDetails && value">
                <b-icon-info-circle />
                <p v-on:dblclick="ondblclick">{{ $i18n.t('details') }}</p>
            </div>

            <div v-if="toggleItems" class="toggleItemsButton" @click="toggleItemsAction">
                <p><b-icon :icon="toggleIcon" />{{ toggleText }}</p>
            </div>
        </div>



        <vue-select append-to-body :disabled="disabled" v-model="value" :id="inputId" :get-option-label="optionLabel"
            :multiple="multiple" :options="computedOptions" @input="emitChanges" @search="changeSearch" :label="optionText"
            class="mtw-select">

            <template #option="option">
                <div class="custom-option">
                    {{  getText(option) }}
                </div>
            </template>


            <template #selected-option="{ }">
                <div class="custom-option">
                    {{ 
                        getText(value)
                    }}
                </div>
            </template>





            <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                    <div class="w-100" v-on:dblclick="ondblclick">
                        {{ $i18n.t("notFoundInSelect") }} <em>"{{ search }}"</em>.
                    </div>
                </template>
            </template>

            <template #search="{ attributes, events }">
                <input :id="`select-${inputId}`" class="vs__search select" :required="!value" v-bind="attributes"
                    v-on="events" @keydown="handleKeydown" />
            </template>

        </vue-select>


        <p v-if="!isValid" class="invalidMessage">{{ invalidMessage }}</p>

        <!-- Informações sobre o componente -->
        <transition name="fade" mode="out-in">
            <div v-if="component != '' && showComponent" class="w-100 detailsContainer component">
                <page-header :name="$i18n.t(name)" :button="false" small hideSearch />
                <component :is="component" :id="value.id" :key="componentKey" @cancel="cancelHandler"
                    @change="changeHandler" @addElement="addElement" @updateElement="updateElement" />
            </div>
        </transition>


    </b-form-group>
</template>

<script>
/* eslint-disable */
import 'vue-select/dist/vue-select.css';
export default {
    data() {
        return {
            value: '',
            searchText: '',
            componentKey: 0,
            showComponent: false,
            isToggled: false,
        }
    },
    props: {
        options: {
            type: Array,
            required: true
        },
        optionText: {
            type: String,
            required: false
        },
        label: {
            type: String,
            required: false,
            default: 'text',
        },
        name: {
            type: String,
            required: true
        },
        invalidMessage: {
            type: String,
            required: false,
            default: 'Invalid'
        },
        isValid: {
            type: Boolean,
            required: false,
            default: true
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        multiple: {
            type: Boolean,
            required: false,
            default: false
        },
        selected: {
            type: undefined,
            required: false,
            default: undefined
        },
        component: {
            type: String,
            required: false,
            default: ''
        },
        hasDetails: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        toggleItems: {
            type: Boolean,
            required: false,
            default: false
        },
        toggleLabels: {
            type: Array,
            required: false,
        },
        infoText: {
            type: String,
            required: false,
            default: ''
        },
        translate: {
            type: Boolean,
            required: false,
            default: false
        }

    },

    computed: {
        inputId() {
            return this.name + "Input"
        },

        toggleIcon() {
            return this.isToggled ? 'eye-slash-fill' : 'eye-fill'
        },

        toggleText() {

            return this.isToggled ? this.toggleLabels[0] : this.toggleLabels[1]
        },

        computedOptions() {
            var options = this.options;
            const optionText = this.optionText;
            const searchText = this.searchText.trim().toLowerCase();

            if (searchText.length === 0) return options;

            return options.filter(item => {
                const textValue = this.translate ? this.i18n.t(item[optionText]) : item[optionText];
                return textValue.toLowerCase().includes(searchText);
            });
        },


    },

    methods: {

        isObject(value) {
            return typeof value === "object" && value !== null;
        },

        getText(value) {
            console.log(value)
            if(this.isObject(value)) {
                return value[this.optionText] != undefined ? value[this.optionText] : ''
            }
            else {
                if(value == undefined) return ''
                if(this.optionText == undefined) return value
                return this.computedOptions[value][this.optionText]
            }
        },

        updateDisplay() {
            const toggle = this.$el.querySelector('.vs__dropdown-toggle');
            const dropdown = this.$el.querySelector('.vs__selected-options');
            const select = this.$el.querySelector('.vs__selected');
            const input = this.$el.querySelector('vs__search')
            if (select != null) {
                let height = select.offsetHeight;
                let minHeight = 28;
                if (input != undefined) {
                    minHeight = input.offsetHeight;
                }
                //console.log(height);
                dropdown.style.minHeight = `${minHeight}px`;
                dropdown.style.height = `${height}px`;
            }

            //toggle.style.width = `${width + 20}px`;
            //select.style.marginRight = "10px";
            //dropdown.style.margin = `${height + 10}px`
            //console.log(this.$el.children[0].children[1].children[0].children[0]);*/
        },

        handleKeydown(e) {
            if (e.key == "Enter") {
                e.preventDefault()
                e.stopPropagation()
                return false
            }
        },

        updateElement(element) {
            const index = this.options.map(item => item.id).indexOf(element.value.id)
            this.options[index] = element.value
            this.closeItems()
        },
        optionLabel(label) {
            const optionText = this.optionText
            return this.translate ? this.$i18n.t(label[optionText]) : label[optionText]
        },
        changeSearch(text) {
            this.searchText = text
        },
        ondblclick() {
            if (this.$refs.detailsButton != undefined) {
                this.showComponent = !this.showComponent;
                this.$refs.detailsButton.classList.toggle("open")
            }
        },
        toggleItemsAction() {
            this.isToggled = !this.isToggled
            this.$emit("toggle", this.isToggled)
        },

        closeItems() {
            this.showComponent = false;
            this.$refs.detailsButton.classList.toggle("open")
        },

        emitChanges(e) {
            if (e.id && e.id == 0) {
                this.showComponent = true;
                this.$refs.detailsButton.classList.toggle("open")
            }
            else {
                this.componentKey += 1;
                this.$emit("change", this.value)
            }

            this.$nextTick(() => {
                this.updateDisplay();
            })
        },


        changeHandler(e) {
            this.$emit("change", e)
        },
        cancelHandler() {
            if (this.$refs.detailsButton != undefined) {
                this.showComponent = false
                this.$refs.detailsButton.classList.remove("open")
            }
        },

        addElement(newElement) {
            this.options[this.options.length - 1] = newElement.value;
            this.options.push(newElement.element)
            this.componentKey += 1;
            this.showComponent = false;
            this.$refs.detailsButton.classList.remove("open");
        },
    },

    mounted() {
        this.value = this.selected
        
        this.$nextTick(() => {
            this.updateDisplay();
        })

        setTimeout(() => {
            if (this.selected != undefined) {
                if (this.selected) { // recebe id 
                    this.options.forEach(item => {
                        if (item.id == this.selected) {
                            this.value = item
                        }
                    })
                }
            }
        }, 500)



    },

    watch: {
        isValid() {
            this.$el.classList.remove("animate__headShake")
            if (!this.isValid) {
                this.$el.classList.add("animate__headShake")
            }
        },
        selected: {
            deep: true,
            handler() {
                this.value = this.selected
            }
        }
    }
}
</script>

<style scoped>
.mtw-select {
    height: auto;
}

.custom-option {
    white-space: normal;
    /* Allows line breaks */
    word-wrap: break-word;
    /* Break long words */
    overflow-wrap: break-word;
    width: auto;
    /* Ensures breaking in modern browsers */
}

.infoIcon {
    margin-right: 0.25rem;
    color: #e0900f;
}

.selectDisabled {
    opacity: 0.75;
}

.invalidMessage {
    color: #c43845;
    margin: 0;
    font-style: italic;
    font-weight: bolder;
    background: #b92e2e2b;
    opacity: 1;
    text-align: center;
}

.fade-enter-active,
.fade-leave-active {
    transition: all .5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.component {
    border: 0.1px dashed rgba(217, 217, 217, 0.89);
    border-radius: 8px;
    padding: 1rem;
    background: #1a2534;
}

#form>div>input {
    height: 1.5rem;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 1rem;
    border: 0.1px solid rgba(217, 217, 217, 0.89);
    color: white;
    border-radius: 8px;
    width: 100%;
}

label {
    text-align: left;
    color: white;
    color: rgb(var(--tmHubTheme-text-contrast));
    opacity: 0.75;
}

.input {
    height: 3rem;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 1rem;
    border: 0.1px solid rgba(255, 255, 255, 0.3);
    color: white;
    border-radius: 8px;
}

.required {
    color: rgba(255, 96, 96);
}

.control input {
    background-color: transparent;
}

.suggestionSpan {
    background-color: rgb(215, 221, 223) !important;
    width: 100% !important;
    height: 100% !important;
}
</style>

<style lang="scss">
.detailsButton {
    width: auto;
    // min-width: 80px;
    cursor: pointer;
    background-color: transparent;
    border: 1.75px solid #e08f0e;
    padding: 0.15rem 0.5rem;
    border-radius: 10rem;
    opacity: 50%;
    margin-bottom: 0.25rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
    transition: opacity 0.25s ease-in-out, background-color 1s ease-in;

    &.open {
        background-color: #e08f0e;
        transition: background-color 1s ease-in;
        opacity: 1;
    }

    p {
        opacity: 1;
        margin: 0;
    }

    &:hover {
        transition: opacity 0.5s ease-in-out;
        opacity: 1;
    }
}

.detailsContainer {
    margin: 0.5rem 0;
}

.toggleItemsButton {
    width: 100%;
    text-align: end;
    cursor: pointer;
}

#form>div {
    width: 100%;
}

* {
    --vs-dropdown-bg: #2a343e;
    --vs-dropdown-color: white;
    --vs-dropdown-option-color: white;
}




.v-select.vs--searchable {
    color: black !important;
    color: rgb(var(--tmHubTheme-text-contrast)) !important;

    &>div {
        background-color: rgba(255, 255, 255, 0.05);
        background-color: rgba(var(--tmHubTheme-primary-color), 0.05);
        padding: 0.65rem;
        border: 0.1px solid rgba(255, 255, 255, 0.3);
        border: 0.1px solid rgba(var(--tmHubTheme-text-contrast), 0.3);
        color: white;
        color: rgb(var(--tmHubTheme-text-contrast)) !important;
        border-radius: 8px;
    }

    .vs__selected {
        color: white;
        color: rgb(var(--tmHubTheme-text-contrast));
    }

    .vs__actions {
        .vs__clear {
            fill: #dc3545
        }

        .vs__open-indicator {
            fill: white;
            fill: rgb(var(--tmHubTheme-text-contrast))
        }
    }

    .vs__selected-options {
        max-width: 100%;

        .vs__selected {
            white-space: nowrap;
            overflow: hidden;
            background: rgba(22, 3, 3, 0.25);

            .vs__deselect {
                fill: #dc3545
            }
        }
    }

}

.vs--disabled .vs__clear,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator,
.vs--disabled .vs__search,
.vs--disabled .vs__selected {
    background: transparent !important;
}

.select {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.vs__dropdown-menu {
    z-index: 9999999 !important;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
}
</style>