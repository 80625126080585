<template>
    <div class="w-100 d-flex flex-column align-items-center justify-content-center">
        <h5>{{$i18n.t('calculationMeasurer')}} - <span>7 {{$i18n.t('days')}}</span></h5>
        <code v-if="loading">{{$i18n.t('loading')}}...</code>
        <div class="historyDataContainer" v-if="loading || data != undefined">
            <div class="d-flex align-items-center"><b-icon-sort-up/><h6 class="mb-0">{{$i18n.t('maximum')}}:</h6>
                <b-skeleton v-if="showSkeleton" class="history-skeleton"/>
                <code v-else>{{history.max}}</code>
            </div>
            <div class="d-flex align-items-center"><b-icon-sort-down/><h6 class="mb-0">{{$i18n.t('minimum')}}:</h6>
                <b-skeleton v-if="showSkeleton" class="history-skeleton"/>
                <code v-else>{{history.min}}</code>
            </div>
            <div class="d-flex align-items-center"><b-icon-bell-fill/><h6 class="mb-0">{{$i18n.t('events')}}:</h6>
                <b-skeleton v-if="showSkeleton" class="history-skeleton"/>
                <code v-else>{{history.events}}</code>
            </div>
            <div class="d-flex align-items-center"><b-icon-align-center/><h6 class="mb-0">{{$i18n.t('average')}}:</h6>
                <b-skeleton v-if="showSkeleton" class="history-skeleton"/>
                <code v-else>{{history.mean}}</code>
            </div>
            <div class="d-flex align-items-center"><b-icon-stack/><h6 class="mb-0">{{$i18n.t('accumulated')}}:</h6>
                <b-skeleton v-if="showSkeleton" class="history-skeleton"/>
                <code v-else>{{history.accumulated}}</code>
            </div>
        </div>
        <div class="w-100 h-100 d-flex flex-column justify-content-center align-items-center" v-else>
            <b-icon-x-circle font-scale="2" class="mt-2 mb-2"/>
            <h6>{{$i18n.t('noRecords').toUpperCase()}}</h6>
        </div>
        <small v-if="!showSkeleton" class="mt-2 pt-1 pb-1 text-center" v-b-tooltip.hover :title="$i18n.t('reload')" @click="$emit('requestData')"><b-icon-arrow-repeat class="mr-2"/>{{history.update}}</small>
    </div>
</template>

<script>
export default {

    props:{
        isLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        data:{
            type: Object || undefined,
            required:true
        }
    },

    computed:{
        loading(){
            return this.isLoading
        },
        history(){
            if(this.data == undefined) return undefined
            var keys = Object.keys(this.data)
            var formatedObject = {} // Para ficar com apenas duas casas decimais
            keys.forEach(k =>{
                var value = this.data[k]
                if(k == "update") {
                    const localDate = new Date(value);
                    const formattedDate = localDate.toLocaleDateString('pt-BR', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                    });
                    formatedObject[k] = formattedDate.replace(",", "")
                }
                else formatedObject[k] = value.toFixed(2)
            })
            return formatedObject
        },
        showSkeleton(){
            return this.isLoading || this.history == undefined
        }
    },

    mounted(){
        var hasData = this.data != undefined
        if(!hasData && !this.isLoading) this.$emit("requestData")
    }

}
</script>

<style lang="scss" scoped>
   .historyDataContainer{
        font-size: 1.25rem !important; 
        div{
            gap: 0.75rem
        }
        svg{
            opacity: 1;
            margin-left: 0.25rem;
            color: #af7213;
        }
        .history-skeleton{
            width: 4rem;
            background: #1a2936;
        }
    }
    .refreshButton{
        margin-left: 1rem;
        background-color: transparent;
        border: none;
        border-radius: 1rem;
        color: white;
        cursor: pointer;
        &:hover{
            background-color: #9a681985
        }
    }
    small{
        opacity: 1;
        background: rgb(0 0 0 / 50%);
        padding: 0 0.5rem;
        border-radius: 0.5rem;
        cursor: pointer;
        color: #ca3b7f;
        &:hover{
            opacity: 1;
        }
    }
</style>