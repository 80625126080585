<template>
    <div class="w-100 h-100 btnGrid">
        <div class="w-100 h-100 d-flex justify-content-center align-items-center">
            <toggle-button :value="toggle" :color="digitalColor" @change="changeStates" :sync="true" :height="height"
                :width="width" :fontSize="30" :disabled="isBtnDisabled" />
        </div>
        <h3>{{ activeLabel }}</h3>
        <span v-if="message.text.length > 1" class="w-100 d-flex align-items-center statusContainer" @click="closeMessage">
            <code>{{ message.text }}</code>
            <b-icon-x class="closeMessageBtn" />
        </span>
    </div>
</template>

<script>
/* eslint-disable */

import { ToggleButton } from 'vue-js-toggle-button'
export default {
    components: {
        ToggleButton
    },
    props: {
        info: {
            required: true,
            type: Object,
        },
        divisionsColorList: {
            type: Array,
            required: true
        },
        topic: {
            type: String,
            required: false,
            default: ''
        },
        value: {
            type: Number,
            required: false,
        },
    },
    data() {
        return {
            height: 60,
            width: 150,
            isOn: undefined,
            message: { text: '', status: '' }
        }
    },
    computed: {
        isBtnDisabled() {
            return this.message.status == 'isLoading'
        },
        minMax() {
            var min
            var max
            this.divisionsColorList.forEach((item, index) => {
                if (!index) {
                    min = item
                    max = item
                }
                else {
                    if (item.start < min.start) min = item
                    else if (item.start > max.start) max = item
                }
            })
            return [min, max]
        },
        computedValue() {
            return this.value
        },
        digitalLabels() {
            const list = this.minMax
            const uncheckedLabel = list[0].text ? list[0].text : 'O'
            const checkedLabel = list[1].text ? list[1].text : "I"
            return { checked: checkedLabel, unchecked: uncheckedLabel }
        },
        activeLabel() {
            return this.isOn ? this.digitalLabels.checked : this.digitalLabels.unchecked
        },
        digitalColor() {
            const offColor = this.minMax[0].color
            const onColor = this.minMax[1].color
            return {
                "checked": onColor,
                "unchecked": offColor
            }
        },
        statusColor() {
            if (this.message.text.length > 1) {
                var status = this.message.status
                if (status == 'isLoading') return '#481dec24'
                else if (status == 'success') return '#268d2624'
                else return '#ff1e0c24'
            }
        },
        toggle() {
            return this.isOn == 1
        },
        fontSize(){
            return `${(this.height * this.width)/300}px`
        }
    },
    methods: {
        closeMessage() {
            this.message = { text: '', status: this.message.status }
        },
        async changeStates(){
            var variableId = this.topic.split('/')[2]
            const hide = () => {
                setTimeout(this.closeMessage, 5000);
            }
            const newState = this.isOn ? 0 : 1
            this.message = { text: 'enviando...', status: "isLoading" }
            this.changeToggle()
            await this.dataService
            .sendData(variableId, newState)
            .then(()=>{ 
                this.message = {text: "Comando enviado com sucesso!", status: "success"}
                hide();
            }).catch(()=>{
                this.changeToggle()
                this.message = {text: "Não foi possivel confirmar.", status: "failed"}
                hide();
            })
        },
        changeToggle() {
            this.isOn = !this.isOn
        },
        resizeMeasurer(w, h) {
            const width = w * 0.25
            const height = h * 0.125

            this.width = width < 100 ? 100 : width
            this.height = height < 40 ? 40 : height

        }
    },
    mounted() {
        this.dataService = new this.$dataService();
        this.isOn = parseInt(this.value)
    },
    watch: {
        value() {
            this.isOn = parseInt(this.value)
        }
    }
}
</script>

<style scoped lang="scss">
.btnGrid {
    display: grid !important;
    grid-template-rows: 70% 20% 10%;
    text-align: center;
    justify-items: center;
}

h3{
    font-size: max(1.75rem, v-bind(fontSize))
}

.statusContainer {
    background-color: v-bind(statusColor);
    justify-content: center;
    cursor: pointer;

    code {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .closeMessageBtn {
        cursor: pointer;
        display: none;
    }

    &:hover {
        .closeMessageBtn {
            display: initial
        }
    }

}

span.v-switch-label.v-right {
    overflow: hidden;
    width: 75%;
    white-space: nowrap;
    text-overflow: ellipsis;
}

span.v-switch-label.v-left {
    overflow: hidden;
    width: 75%;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>