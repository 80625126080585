<template>
    <b-button 
        :size="size" 
        :to="pageLink" 
        :variant="buttonStyle" 
        :type="type" 
        @click="triggerAction" 
        class="button">
        {{ computedTitle }}
        <b-icon v-if="icon.length > 0" :icon="icon"/>
    </b-button>
</template>

<script>
export default {
    props: {
        type: {
            required: true, 
            type: String
        },
        title: {
            required: false, 
            type: String,
            default: ""
        }, 
        size:{
            type:String,
            required:false,
            default: ""
        },
        buttonStyle:{
            type:String,
            required:false,
            default: "primary"
        },
        pageLink: {
            type:String,
            required:false,
            default: ""
        },
        icon:{
            required:false,
            default: "",
            type: String
        }
    },

    computed: {
        computedTitle() {
            return this.title
        }
    },

    methods: {
        triggerAction(e) {
            // ! TODO: FIXME: Deletar!!
            // e.preventDefault();
            //
            console.log(e);
            this.$emit('buttonAction');
        }
    },
}
</script>

<style scoped lang="scss">
    .button {
        width: 100%;
        max-width: 300px;
        padding: 10px;
        border-radius: 10px;
        font-size: 1.2em;
        padding: 5px;
    }
    .button-danger {
        background: firebrick;
        color: white;
    }
    .primary {
        background-color: transparent;
        border-color: #0d6efd;
        color: #0d6efd;
        border-radius: 20px;
        min-width: 200px;
    }
</style>