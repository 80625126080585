<template>
	<div class="w-100 h-100 d-flex justify-content-center align-items-center" :style="fontSize">
		<div no-drag class="sliderWrapper" @mousedown="mousedown" @mouseup="mouseup" @mousemove="drag">
			<round-slider
				v-model="sliderValue"
				start-angle="290"
				end-angle="250"
				line-cap="round"
				:radius="radius"
				path-color="gray"
				tooltipColor="white"
				:min="min"
				:max="max"
				:rangeColor="color"
				:update="change"
				:tooltipFormat="label"
				:readOnly="isUpdating"
				:animation="false"
			/>
		</div>
	</div>
</template>

<script>


import RoundSlider from 'vue-round-slider'
export default {
	components:{RoundSlider},
	props: {
        value: {
			type: Number,
			required: false,
			default: 80,
		},
		info:{
			type: Object,
			required: true
		},
		divisionsColorList:{
            type:Array,
            required:true
        },
		topic: {
            type: String,
            required: false,
            default: ''
        },
		fullscreen:{
			required:false,
			type:Boolean,
			default:false,
		},
    },
	data(){
		return{
			svgFontSize: 0,
			svgHeightObserver: null,

			radius: 100,
			isUpdating: false,
			isPressing:false,
			sliderValue:0,
			currentValue: 0,
			divisions: [
				{color: 'red', start: 0, end: 8},
				{color: 'yellow', start: 8, end: 16},
				{color: 'green', start: 16, end: 25},
			]
		}
	},
	computed:{
		color(){
			return this.divisionsColorList[0].color
		},
		min(){
			return this.info.minimum != undefined ? parseInt(this.info.minimum) : 0
		},
		max(){
			return this.info.maximum  != undefined ? parseInt(this.info.maximum) : 100
		},

		fontSize(){
			return { "--font-size": `${this.svgFontSize}px`}
		}
	},
	methods:{
		drag(event){
			event.stopPropagation();
		},
		resizeMeasurer(height, width){
			var h = parseFloat(height)/2
			var w = parseFloat(width) / 2
			this.radius = Math.min(h,w)
		},
		label(e){
			return `${e.value} ${this.info.unit}`
		},
		mousedown(){
			this.isPressing = true
		},
		mouseup(){
			this.isPressing = false
			if(this.hadChanges){
				this.atualiza()
			}
		},
		change(e){
			this.hadChanges = true
			if(!this.isPressing){
				this.atualiza()
			}

			var variableId = this.topic.split('/')[2]
			this.dataService
			.sendData(variableId, e.value)
			.then(()=>{ 
				this.message = {text: "Comando enviado com sucesso!", status: "success"}
			});
		},
		atualiza(){
			this.isPressing = false
			this.isUpdating = false
		}
	},
	created(){
		this.dataService = new this.$dataService();
		this.sliderValue = this.value
	},

	mounted(){
		const svgElement = this.$el.querySelector("svg")

		const resizeObserverHandler = () => {
			this.svgFontSize = svgElement.getBoundingClientRect().height * 0.15
		}

		this.svgHeightObserver = new ResizeObserver(resizeObserverHandler).observe(svgElement)


	},
	beforeDestroy() {
		if(this.svgHeightObserver) this.svgHeightObserver.disconnect();
	},
	watch:{
		value(){
			this.sliderValue = this.value
		}
	}
}
</script>


<style scoped>
    .sliderWrapper{
        height: max-content;
		width: max-content;
    }
</style>

<style lang="scss">
	.sliderWrapper{

		.rs-svg-mode{
			position: relative;
			.rs-tooltip.rs-tooltip-text.rs-edit{
				position: absolute;
				margin: 0 !important;
				transform: translate(-50% ,-50%);
				font-size: var(--font-size);
			}
		}
	}
</style>