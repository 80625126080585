<template>
  <div id="sidebar" :style="sidebar">
    <b-sidebar id="sidebar-variant" text-variant="secondary" no-header bg-variant="transparent" shadow="sm"
      @change="changeSideBar" @hidden="hideSideBar" @shown="showSideBar"  aria-hidden="false">
      <b-nav vertical class="w-120">
        <div class="w-120 mb-1">
          <b-nav-item class="colapsable d-flex justify-content-between align-items-center topic" @click="toggleDashboard">
            <b-icon-layout-wtf font-scale="0.9" />
            {{ dashboardTitle }}
            <b-icon-triangle v-if="hasDashboards" font-scale="0.6" class="arrowDropdown" :style="dArrowRotation" />
          </b-nav-item>

          <b-collapse id="collapse-dashboard">
            <b-nav-item exact exact-active-class="active" v-for="dashboard in dashboardRoutes"
              @click.stop="redirectDashboard(`/dashboard/${dashboard.id}`)" :key="dashboard.id"
              :class="{ 'activeRoute': isDashboardRouteOpen(dashboard.id) }">
              {{ $i18n.t(dashboard.name) }}
            </b-nav-item>
          </b-collapse>

          <span class="d-flex align-items-center">
            <b-nav-item to="/Map" exact exact-active-class="active" class="topic">
              <b-icon-map font-scale="0.9" />
              {{ $i18n.t("map") }}
            </b-nav-item>
          </span>

          <span class="d-flex align-items-center">
            <b-nav-item to="/blueprint" exact exact-active-class="active" class="topic">
              <b-icon-card-image font-scale="0.9" />
              {{ $i18n.t("blueprints") }}
            </b-nav-item>
          </span>

          <hr class="division">

          <span class="d-flex align-items-center" @click="openUserCollapse">
            <b-nav-item v-if="!isMobile" class="topic">
              <b-icon-people-fill font-scale="0.9" />
              {{ $i18n.t("accesses") }}
              <b-icon-triangle font-scale="0.6" class="historyArrowDropdown ml-2" :style="userArrowRotation" />
            </b-nav-item>
          </span>

          <b-collapse id="collapse-user">
            <b-nav-item v-if="!isUserSecundary" to="/user" exact exact-active-class="active">
              <b-icon-person-circle font-scale="1" />
              {{ $i18n.t("users") }}
            </b-nav-item>
            
            <b-nav-item v-if="!isUserSecundary" to="/aggregation" exact exact-active-class="active">
              <b-icon-diagram3-fill font-scale="1" />
              {{ $i18n.t('aggregation') }}
            </b-nav-item>

            <b-nav-item v-if="!isUserSecundary" to="/tree" exact exact-active-class="active">
              <b-icon-tree-fill font-scale="1" />
              {{ $i18n.t('tree') }}
            </b-nav-item>
          </b-collapse>

          <hr class="division">

          <span class="d-flex align-items-center" @click="openHistoryCollapse">
            <b-nav-item v-if="!isMobile" class="topic">
              <b-icon-clock font-scale="0.9" />
              {{ $i18n.t("History") }}
              <b-icon-triangle font-scale="0.6" class="historyArrowDropdown ml-2" :style="historyArrowRotation" />
            </b-nav-item>
          </span>

          <b-collapse id="collapse-history">
            <b-nav-item to="/history" exact exact-active-class="active">
              <b-icon-bar-chart-fill font-scale="0.9" />
              {{ $i18n.t('data') }}
            </b-nav-item>
            <b-nav-item to="/HistoryEvents" exact exact-active-class="active">
              <b-icon-bell-fill font-scale="0.9" />
              {{ $i18n.t('events') }}
            </b-nav-item>

            <b-nav-item to="/report" exact exact-active-class="active">
              <b-icon-file-earmark-post font-scale="0.9" />
              {{ $i18n.t('report') }}
            </b-nav-item>

            <b-nav-item to="/reportSchedule" exact exact-active-class="active">
              <b-icon-clock-history font-scale="0.9" />
              {{ $i18n.t('reportSchedule') }}
            </b-nav-item>

            <b-nav-item v-if="$session.get('privilege') == '0'" to="/audit" exact exact-active-class="active">
              <b-iconstack>
                <b-icon-file-earmark-diff-fill stacked scale="0.7" />
                <b-icon-search stacked scale="0.55" shift-h="8" shift-v="-8" />
              </b-iconstack>
              {{ $i18n.t('audit') }}
            </b-nav-item>
          </b-collapse>

          <hr class="division">


          <b-nav-item to="/presentation" id="presentation-link" exact exact-active-class="active" class="topic">
            <b-iconstack shift-v="-3">
              <b-icon-back font-scale="0.4" shift-h="-4" shift-v="4" />
              <b-icon-back font-scale="0.4" shift-h="4" shift-v="-4" />
            </b-iconstack>
            <i style="margin-left:-6px">
              {{ $i18n.t("presentation") }}
            </i>
          </b-nav-item>

          <hr class="division">
        </div>

        <div class="w-120 mb-1" v-if="!isMobile">
          <b-nav-item id="config-link" class="colapsable d-flex justify-content-between align-items-center topic" style="width: 40%"
            @click="toggleConfig" v-b-toggle.collapse-config>
            <b-icon-gear-wide-connected font-scale="0.9" />
            {{ $i18n.t("configuration") }}
            <b-icon-triangle font-scale="0.6" class="arrowDropdown" :style="arrowRotation" />
          </b-nav-item>


          <b-collapse id="collapse-config">

            <b-nav-item  id="sn-link" v-if="$session.get('privilege') == 0" to="/serialNumber" exact exact-active-class="active">
              <b-icon-upc font-scale="0.9" />
              {{ $i18n.t("serialNumbers") }}
            </b-nav-item>





            <b-nav-item v-if="!isUserSecundary" to="/network" exact exact-active-class="active">
              <b-icon-globe font-scale="0.8" />
              {{ $i18n.t('networks') }}
            </b-nav-item>

            <b-nav-item id="devices-link" v-if="!isUserSecundary" to="/device" exact exact-active-class="active">
              <b-icon-hdd-network-fill font-scale="0.9" />
              {{ $i18n.t("devices") }}
            </b-nav-item>

            <b-nav-item id="variables-link" v-if="!isUserSecundary" to="/variable" exact exact-active-class="active">
              <b-iconstack>
                <b-icon-soundwave font-scale="0.6" shift-h="-8" shift-v="0" />
                <b-icon-soundwave font-scale="0.6" shift-h="4" shift-v="0" />
              </b-iconstack>
              {{ $i18n.t("variables") }}
            </b-nav-item>

            <b-nav-item id="display-link" v-if="!isUserSecundary" to="/display" exact exact-active-class="active">
              <b-iconstack shift-v="-3">
                <b-icon-palette-fill font-scale="0.5" shift-h="8" shift-v="7" />
                <b-icon-calculator-fill font-scale="0.5" shift-h="-8" shift-v="-7" />
              </b-iconstack>
              {{ $i18n.t("displays") }}
            </b-nav-item>

            <b-nav-item v-if="!isUserSecundary" to="/measurer" exact exact-active-class="active"><b-icon-speedometer2 />{{
              $i18n.t("measurers")
            }}</b-nav-item>






            <b-nav-item v-if="!isUserSecundary" to="/group" exact exact-active-class="active">
              <b-iconstack shift-v="-3">
                <b-icon-power font-scale="0.4" shift-h="-8" shift-v="7" />
                <b-icon-speedometer font-scale="0.4" shift-h="0" shift-v="-7" />
                <b-icon-speedometer2 font-scale="0.4" shift-h="8" shift-v="7" />
                <b-icon-thermometer-half font-scale="0.4" shift-h="14" shift-v="-7" />
              </b-iconstack>
              {{ $i18n.t("groups") }}
            </b-nav-item>

            <b-nav-item id="dashboards-link" to="/dashboard" exact exact-active-class="active">
              <b-icon-grid1x2-fill font-scale="0.8" />
              {{ $i18n.t('dashboards') }}
            </b-nav-item>

            <b-nav-item v-if="!isUserSecundary" to="/maplayers" exact exact-active-class="active">
              <b-iconstack shift-h="-2">
                <b-icon-layers font-scale="0.75" />
                <b-icon-map-fill font-scale="0.4" shift-h="25" shift-v="-25" />
              </b-iconstack>
              {{ $i18n.t('layers-map') }}
            </b-nav-item>

            <b-nav-item v-if="!isUserSecundary" to="/blueprintlayers" exact exact-active-class="active">
              <b-iconstack shift-h="-2">
                <b-icon-layers font-scale="0.75" />
                <b-icon-card-image font-scale="0.4" shift-h="25" shift-v="-25" />
              </b-iconstack>
              {{ $i18n.t('layers-blueprint') }}
            </b-nav-item>



          </b-collapse>
        </div>

        <span class="d-flex align-items-center" @click="openAlarmCollapse">
          <b-nav-item id="events-link" v-if="!isMobile" class="topic">
            <b-icon-toggles font-scale="0.9" />
            {{ $i18n.t("triggers") }}
            <b-icon-triangle font-scale="0.6" class="historyArrowDropdown ml-2" :style="alarmArrowRotation" />
          </b-nav-item>
        </span>

        <b-collapse id="collapse-alarm">
          <b-nav-item v-if="!isUserSecundary" to="/recipient" exact exact-active-class="active">
            <b-icon-mailbox font-scale="0.9" />
            {{ $i18n.t("recipients") }}
          </b-nav-item>
          <b-nav-item v-if="!isUserSecundary" to="/recipientGroup" exact exact-active-class="active">
            <b-iconstack shift-v="-3">
              <b-icon-person-fill font-scale="0.5" shift-h="-8" shift-v="-3" style="opacity: 0.66" />
              <b-icon-person-fill font-scale="0.5" shift-h="11" shift-v="-3" style="opacity: 0.66" />
              <b-icon-person-fill font-scale="0.6" />
            </b-iconstack>
            {{ $i18n.t('teams') }}
          </b-nav-item>
          <b-nav-item v-if="!isUserSecundary" to="/schedule" exact exact-active-class="active">
            <b-icon-calendar3 font-scale="0.9" />
            {{ $i18n.t("schedules") }}
          </b-nav-item>
          <b-nav-item v-if="!isUserSecundary" to="/action" exact exact-active-class="active">
            <b-icon-lightning font-scale="0.9" />
            {{ $i18n.t("actions") }}
          </b-nav-item>
          <b-nav-item id="alarms-link" v-if="!isUserSecundary" to="/alarm" exact exact-active-class="active">
            <b-icon-bell font-scale="0.9" />
            {{ $i18n.t("alarms") }}
          </b-nav-item>
        </b-collapse>

        <span class="d-flex align-items-center" @click="openVideoCollapse">
          <b-nav-item v-if="!isMobile" class="topic">
            <b-icon-collection-play font-scale="0.9" />
            {{ $i18n.t("video") }}
            <b-icon-triangle font-scale="0.6" class="historyArrowDropdown ml-2" :style="videoArrowRotation" />
          </b-nav-item>
        </span>

        <b-collapse id="collapse-video">
          <b-nav-item v-if="!isUserSecundary" to="/videoServerConfig" exact exact-active-class="active">
            <b-iconstack shift-v="-5" shift-h="5">
              <b-icon-camera-reels-fill font-scale="0.4" shift-h="-8" shift-v="7" />
              <b-icon-gear-fill font-scale="0.4" shift-h="0" shift-v="-7" />
            </b-iconstack>
            {{ $i18n.t('videoServerConfig') }}
          </b-nav-item>

          <b-nav-item v-if="!isUserSecundary" to="/videoConfig" exact exact-active-class="active">
            <b-iconstack>
              <b-icon-camera-reels-fill shift-v="-4" font-scale="0.5" />
            </b-iconstack>

            {{ $i18n.t('videoConfig') }}
          </b-nav-item>

          <b-nav-item v-if="!isUserSecundary" to="/mosaic" exact exact-active-class="active">
            <b-iconstack>
              <b-icon-border-all shift-v="-4" font-scale="0.5" />
            </b-iconstack>
            {{ $i18n.t('mosaic') }}
          </b-nav-item>
        </b-collapse>


      </b-nav>
    </b-sidebar>
    <!-- <router-view class="w-100"></router-view> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      sidebar: {
        width: '6rem',
        bottom: 0,
        position: 'fixed',
        top: '3.25rem',
        'z-index': 1010,
        left: 0,
        display: 'flex',
        'margin-left': '-20rem',
      },
      configIsOpen: false,
      dashboardIsOpen: false,
      isHistoryOpen: false,
      isMapOpen: false,
      isVideoOpen: false,
      isAlarmOpen: false,
      isUserOpen: false,
      dashboardRoutes: []
    }
  },
  computed: {
    isUserSecundary() {
      return this.$session.get("user").privilege == 2
    },
    isMobile() {
      return this.$session.get("isMobile")
    },
    dashboardTitle() {
      return this.isUserSecundary ? this.$i18n.t('dashboards') : this.$i18n.t("allDashboards")
    },
    hasDashboards() {
      return this.dashboardRoutes.length >= 1
    },
    arrowRotation() {
      return this.configIsOpen ? { "--rotate": "180deg" } : { "--rotate": "90deg" }
    },

    dArrowRotation() {
      return this.dashboardIsOpen ? { "--rotate": "180deg" } : { "--rotate": "90deg" }
    },

    historyArrowRotation() {
      return this.isHistoryOpen ? { "--rotate": "180deg" } : { "--rotate": "90deg" }
    },

    videoArrowRotation() {
      return this.isVideoOpen ? { "--rotate": "180deg" } : { "--rotate": "90deg" }
    },

    alarmArrowRotation() {
      return this.isAlarmOpen ? { "--rotate": "180deg" } : { "--rotate": "90deg" }
    },

    userArrowRotation() {
      return this.isUserOpen ? { "--rotate": "180deg"} : { "--rotate": "90deg" }
    }



  },
  methods: {
    isDashboardRouteOpen(id) {
      var url = window.location.href
      return url.includes(`dashboard/${id}`)
    },
    /** Navega dessa forma para poder fazer com que o dashboard se descarregue e carrgue novamente
     * Assim não repete o dashboard
     */
    async redirectDashboard(route) {
      this.$router.push('/dashboard').catch(err => { console.log(err) }); // se entrar no erro quer dizer que está no /dashboard
      await this.$nextTick()
      await this.$nextTick()
      console.log(route);
      this.$router.push(route);
    },
    toggleConfig() {
      this.configIsOpen = !this.configIsOpen
    },

    toggleDashboard() {
      if (this.hasDashboards) {
        this.$root.$emit('bv::toggle::collapse', 'collapse-dashboard')
        this.dashboardIsOpen = !this.dashboardIsOpen
        console.log(this.$session.get("user").dashboards)
        this.dashboardRoutes = this.$session.get("user").dashboards.sort((a, b) => a.name.localeCompare(b.name))
      } else {
        this.$router.push('/dashboard').catch(err => { console.log(err) }); // se entrar no erro quer dizer que está no /dashboard
      }
    },

    openHistoryCollapse() {
      this.isHistoryOpen = !this.isHistoryOpen
      this.$root.$emit('bv::toggle::collapse', 'collapse-history')
    },

    openVideoCollapse() {
      this.isVideoOpen = !this.isVideoOpen
      this.$root.$emit('bv::toggle::collapse', 'collapse-video')
    },

    openAlarmCollapse() {
      this.isAlarmOpen = !this.isAlarmOpen;
      this.$root.$emit('bv::toggle::collapse', 'collapse-alarm')
    },

    openLayerCollapse() {
      this.isLayerOpen = !this.isLayerOpen;
      this.$root.$emit('bv::toggle::collapse', 'collapse-layer')
    },

    openUserCollapse() {
      this.isUserOpen = !this.isUserOpen;
      this.$root.$emit('bv::toggle::collapse', 'collapse-user')
    },

    changeSideBar(visible) {
      if (visible) {
        this.$emit('showSideBar');
      }
      else {
        this.$emit('hideSideBar');
      }
    },

    hideSideBar() {
      this.sidebar = {
        width: '13rem',
        bottom: 0,
        position: 'fixed',
        top: '3.25rem',
        left: 0,
        display: 'flex',
        opacity: 0,
        // transition: '0.5s',
        'margin-left': '-20rem',
      };
    },

    showSideBar() {
      this.sidebar = {
        width: '15rem',
        bottom: 0,
        position: 'fixed',
        top: '3.25rem',
        left: 0,
        display: 'flex',
        opacity: 1,
        transition: '0.2s',
        'margin-left': '0',
      };
    }
  },
  created() {
    this.hideSideBar()
    this.dashboardRoutes = this.$session.get("user").dashboards || []
  },
  beforeUnmount() {
    this.hideSideBar()
  }
}
</script>

<style lang="scss" scoped>  
  .division {
    width: 200px;
    height: 1px;
    margin-bottom: 10px;
  }

  .mapArrowDropdown {
    color: white;
    opacity: 1;
    cursor: pointer;
    transform: rotate(var(--rotate));
    transition: transform 0.5s ease-in-out;
  }

  .historyArrowDropdown {
    color: white;
    opacity: 1;
    cursor: pointer;
    transform: rotate(var(--rotate));
    transition: transform 0.5s ease-in-out;
  }

  small {
    font-size: 0.75rem;
    color: white;
    opacity: 0.8;
  }

  .colapsable {
    font-weight: bolder;
  }

  #sidebar {
    padding-bottom: 3rem;
  }

  .nav-dashboard-link {
    padding: 0.5rem 0rem;
    color: aliceblue;
    border: 1px solid transparent;
    display: flex;
    gap: 1rem;
    font-size: 1.15rem;
    align-items: center;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: white;
      transition: opacity 0.4s ease;
    }
  }

  .activeRoute {
    a {
      color: #e0900f !important;
      text-shadow: 0px 0px 6px rgba(224, 144, 15, 1);
    }
  }

  .nav-item a {
    padding-left: 0;
    padding-right: 0;
    color: aliceblue;
    border: 1px solid transparent;
    display: flex;
    gap: 1rem;
    align-items: center;
    // background-color: rgba(255, 255, 255, .03);
  }

  .nav-item a::hover {
    text-decoration: underline;
    color: burlywood;
  }

  .nav-item {
    font-size: 1.15rem;
    opacity: 0.75;
    color: initial;
    transition: opacity 0.4s ease;
    width: 15rem;


    &:hover {
      opacity: 1;
      color: white;
      transition: opacity 0.4s ease;

    }
  }

  .arrowDropdown {
    font-size: 0.6rem;
    transform: rotate(var(--rotate));
    transition: transform 0.5s ease-in-out;
  }

  #collapse-dashboard,
  #collapse-config,
  #collapse-layers,
  #collapse-history,
  #collapse-video,
  #collapse-alarm,
  #collapse-user {
    margin-left: 1rem
  }

  #collapse-config {
    li {
      a {
        display: grid;
        grid-template-columns: 1rem auto;
        max-width: 19ch;
      }
    }
  }
</style>
<style lang="scss" >
@media (min-width: 320px) and (max-width: 991px) {
  #sidebar {
    z-index: 9999999999;
  }

  .b-sidebar {
    width: auto !important;
    // z-index: 9999;
    height: 100vh;
    left: -20px !important;
    padding-right: 2rem;
    top: 1rem;
    background-color: rgb(31, 42, 57) !important;
  }

  div#sidebar-variant {
    width: auto !important;
  }
}

div#sidebar-variant {
  margin: 4rem 1rem
}

.b-sidebar-body {
  padding-bottom: 5rem !important;
  direction: rtl;
  padding-left: 0.5rem;

  &::-webkit-scrollbar {
    width: 8px !important
  }

  ul {
    direction: ltr;
  }

}

div#collapse-dashboard>li.nav-item>a.nav-link {
  overflow: hidden;
  max-width: 15ch;
  text-overflow: ellipsis;
}

li.nav-item {
  &.topic {
   width: 30rem;
    a {
      font-size: 1.125rem;
      font-weight: 500;
    }

  }
  
  a {
    font-size: 1rem;
  }
}

#events-link{
  width: 15rem;
}

</style>