import {
    GridLayout,
    GridItem
} from "vue-grid-layout"

export default {
    components: {
        GridLayout,
        GridItem,
    },
    props: {
        id: {
            type: Number,
            default: 0,
        },
        groupEl: {
            type: Object,
            required: true
        },
        dashboardId: {
            type: String,
            default: 0
        },
        isShowingAllIcons: {
            type: Boolean,
            required: true
        },
        alarmColors: {
            required: false,
            type: Object
        },
        hideCardFooter:{
            required:false,
            default:false,
            type:Boolean
        },
        hideFooter:{
            type:Boolean,
            required:false,
            default:false
        },
        zoomLevel: {
            type: Number,
            required: false,
            default: 100,
        },
        cols: {
            type: Number,
            required:false,
            default: 6
        },
        rows: {
            type: Number || undefined,
            required:false,
            default: undefined
        },
        searchVariableValueById:{
            type:Function,
            required:true
        }
    },
    data() {
        return {
            group: {},
            groupPositions: [],
            groupMeasurers: [],
            toBeDeleted: {},
            dataLoaded: false,
            
            showGrid: true,
            
            minCardWidth: 260,
            gridKey: 0,
            isLoading: true,

            currentPage: 0,
            splitGroupPositions: [],
            currentGroupPosition: [],
            isHoveringGrid: false
        }
    },
    computed: { 

        measurers(){
            if(!this.isADashboardDisplay) return this.groupMeasurers
            var elements = []
            this.groupMeasurers.forEach((el, index) => {
                var item = this.groupPositions[index]
                let current = this.currentGroupPosition.find(p => p.i == item.i)
                if(current) elements.push(el)

            })

            return elements
        },
        isADashboardDisplay(){
            return this.numOfRow != undefined
        },
        numOfCol(){
            return this.isMobile ? 1 : this.cols
        },
        numOfRow(){
            return this.rows
        },
        isMobile() {
            return this.$session.get("isMobile")
        },
        rowHeight(){
            var multiplier = (1 - (this.zoomLevel/100)) + 1
            return 360 * (multiplier*0.95)
        }
    },
    methods: {

        hoveringGrid(){
            this.isHoveringGrid = true
        },
        leaveGrid(){
            this.isHoveringGrid = false
        },

        handleKeyboard(e){
            if(this.isHoveringGrid && this.splitGroupPositions.length > 1){
                const key = e.key
                if(key == "ArrowRight") this.nextPage()
                else if(key == "ArrowLeft") this.lastPage()
            }
        },

        nextPage(){
            if(this.currentPage + 1 <= this.splitGroupPositions.length -1){
                this.currentPage += 1
                this.changePage()
            }
        },

        lastPage(){
            if(this.currentPage - 1 >= 0){
                this.currentPage -= 1
                this.changePage()
            }
        },

        changePage(){
            var list = this.splitGroupPositions[this.currentPage] || []
            this.currentGroupPosition = list

            if(this.numOfRow != undefined){
                let height = `${this.rowHeight * this.numOfRow + 20 + (this.numOfRow - 1) * 20}px`
                this.$el.parentNode.style.height = height
                this.$nextTick(()=>{
                    this.$nextTick(()=>{
                        this.$el.style.height = height
                    })
                })

            }else{
                this.$el.style.height = 'auto'
                this.$el.parentNode.style.height = 'auto'
            }
        },

        getGridItem(index){
            var element = this.groupPositions[index];
            if(!this.isADashboardDisplay) return element
            try{
                var posId = this.measurers[index].id
                var list = this.currentGroupPosition || list
                var pageItem = list.find(p => p.i == posId)
                if(pageItem) return pageItem
                else {
                    return element
                }
            }catch{
                return element
            }

        },

        groupByRow(items){
            if(this.numOfRow == undefined) {
                return [items]
            }
			const allLines = Array.from(new Set(items.map(i => i.y)))
			const lines = Array(Math.max(...allLines) + 1).fill(0).map((_, index) => index)
			const quantOfLines = lines.length
			const groups = Array.from({ length: Math.ceil(quantOfLines/this.numOfRow) }, () => []);
			
			items.forEach(item => {
				let index = Math.floor(item.y / this.numOfRow)
				groups[index].push(item)
			})
			
			return groups
		},

        groupByCol(items){
			const allColumns = Array.from(new Set(items.map(i => i.x)))
			const columns = Array(Math.max(...allColumns, 1)).fill(0).map((_, index) => index)
			const quantOfCols = columns.length
			const groups = Array.from({ length: Math.ceil(quantOfCols/this.numOfCol) }, () => []);
			
			items.forEach(item => {
				let index = Math.floor(item.x / this.numOfCol)
				groups[index].push(item)
			})
			
			return groups
		},

        sortItems(items) {
        // Ordena os itens primeiro por coordenada y, depois por x se as coordenadas y forem iguais
          return items.sort((a, b) => {
            if (a.y !== b.y) {
              return a.y - b.y;
            } else {
              return a.x - b.x;
            }
          });
        },

        orderLayout(layout){
            const groupLayout = this.sortItems(layout)
            const numOfRow = this.numOfRow;
            const numOfCol = this.numOfCol
            
            let updatedLayout = []
            let occupiedSpaces = []
            
            let nextX = 0;
            let nextY = 0;
            
            const hasSpaceInLine = (width) => {
                let spaceLeft = numOfCol - nextX
                return width <= spaceLeft
            }
            
            for (let item of groupLayout){
                const w = item.w > numOfCol ? numOfCol : item.w
                const h = item.h > numOfRow ? numOfRow : item.h
                let x;
                let y;
                
                const updatePos = () => {
                    if(hasSpaceInLine(w)){
                        x = nextX
                        y = nextY
                        nextX += w
                    }else {
                        nextY += 1
                        nextX = 0;
                        x = nextX
                        y = nextY
                        nextX += w
                    }
                }
                updatePos();
                
                let isInAOcupiedSpace = occupiedSpaces.find(i => i.x == x && i.y == y) != undefined
                while(isInAOcupiedSpace){
                    updatePos()
                    isInAOcupiedSpace = occupiedSpaces.find(i => i.x == x && i.y == y) != undefined
                }
                
                if(h > 1){
                    for (let ySpace = 1; ySpace < h ; ySpace++){
                        for (let xSpace = 0; xSpace < w; xSpace++){
                            occupiedSpaces.push({y: ySpace + y, x: xSpace + x})
                        }
                    }
                }
                updatedLayout.push({...item, x, y, h, w})
                
            }

            return updatedLayout
        },

        splitLayoutIntoPages(){
            if(this.groupPositions.length == 0) return
            var layout = this.orderLayout([...JSON.parse(JSON.stringify(this.groupPositions))])
            var rowSplit = this.groupByRow(layout)
            var colSplit = rowSplit.map(item => this.groupByCol(item))
            this.splitGroupPositions = colSplit.flat(1).filter(item => item.length > 0)
            this.changePage()

        },
        update(element = this.group) {
            this.service.update(element).then(() => { console.log("UPDATE") }, (err) => console.error(err))
        },
        deleteCard(measurer) { //Armazena as informações do medidor a ser deletado e emite um evento para DashboardPanel para mostrar modal 
            this.$emit("showModal", { id: measurer.id })
        },
        async changeGroup(group, index) {
            var groupMove = {}
            await this.service.search(group.id).then(response => {
                response.measurers.push(this.groupMeasurers[index])
                groupMove = response
            })
            this.group.measurers = this.group.measurers.filter(measurer => measurer.id != this.groupMeasurers[index].id)
            this.update(groupMove)
            this.update()
        },
        editMeasurer(measurer = this.toBeDeleted) {
            this.$emit('editMeasurer', measurer)
        },
        /** Função que adapta as colunas do grid layout do grupo */
        windowResize() {
            this.reajustPositions(1) // reajusta as posições dos medidores
        },
        /** Fução que reajusta as posições dos medidores no grid */
        reajustPositions(maxCol) {
            var colNum = 0
            var lineNum = 0
            this.groupPositions.forEach(position => {
                position.x = colNum
                position.y = lineNum
                colNum += 1
                if (colNum % maxCol == 0) {
                    colNum = 0
                    lineNum += 1
                }
            })
            console.log(this.groupPositions)
            this.refreshGrid()
        },
        async refreshGrid() {
            this.showGrid = false
            await this.$nextTick()
            this.showGrid = true
        },
    },

    async mounted() {
        this.service = new this.$groupService();
        this.isLoading = true

        this.group = this.groupEl
        this.groupMeasurers = [];

        
        for(let element of this.group.measurers){
            let device = element.variable.device
            
            if(device.ip.length > 0){
                element.variable.module = 0
            }

            element.display.cardName = element.name
            element.display.constant = element.variable.constant
            element.display.multiplier = element.variable.multiplier
            element.display.unit = element.variable.unit
            element.display.recovery = element.recovery

            var el = { 
                x: element.positionX,
                y: element.positionY,
                w: element.width,   
                h: element.height,
                maxH: 3,
                minH: 1,
                i: element.id,
            }
            this.groupPositions.push(el);
            this.groupMeasurers.push(element)
        }

        this.isLoading = false

        if(this.isMobile){
            this.windowResize()
        }else{
            this.splitLayoutIntoPages()
        }

        this.$emit("loaded")

        window.addEventListener("keydown", this.handleKeyboard)


        
    },

    beforeDestroy() {
        window.removeEventListener("keydown", this.handleKeyboard)
        window.removeEventListener("resize", () => { this.windowResize() });
    },

    watch: {
        groupPositions: {
            deep: true,
            handler() {
                if (!this.isLoading) {
                    this.groupPositions.forEach((position, index) => {
                        this.groupMeasurers[index].height = position.h
                        this.groupMeasurers[index].width = position.w
                        this.groupMeasurers[index].positionX = position.x
                        this.groupMeasurers[index].positionY = position.y
                    })
                    this.$emit("change", this.groupMeasurers)
                }
                this.dataLoaded = true
            }
        },
        numOfCol(){
            if(this.isLoading) return
            this.splitLayoutIntoPages();
        },
        numOfRow(){
            if(this.isLoading) return
            this.splitLayoutIntoPages();
        },
    }
}