<template>
    <div ref="container" class="w-100 h-100 container">
        <ApexCharts v-if="showChart" :height="height" :width="width" ref="realtimeChart" class="grafico d-flex justify-content-center" type="area" :options="options" :series="dados"></ApexCharts>
        <div class="label w-100 text-center d-flex justify-content-center align-items-end" style="gap: 0.5rem">
            <h3>{{value}}</h3><h6 style="position:relative;top:-3px">{{unit}}</h6>
        </div>
    </div>
</template>

<script>
import ApexCharts from 'vue-apexcharts'
export default {
    components:{
        ApexCharts
    },
    props:{
        info:{
            type:Object,
            required:true
        },
        value:{
            type:Number,
            required:false,
        },
        fullscreen:{
			required:false,
			type:Boolean,
			default:false,
		}
    },
    computed:{
        unit(){
            return this.info.unit ? this.info.unit : 'unidade'
        },
        options(){
            return {
                title:{text: ''},
                chart:{
                    redrawOnParentResize: false,
                    toolbar:{show:false},
                    type: 'line',
                    zoom:{enabled:false},
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 1000,
                        dynamicAnimation: {
                            enabled: false
                        },
                        animateGradually:{enabled: true}
                    },
                },
                dataLabels:{
                    enabled: false
                },

                stroke:{
                    curve:'smooth'
                },

                colors: ["#FF9E04"],
                
                fill:{
                    shade: 'dark',
                    colors: ["#FF9E04"]
                },
                grid:{
                    borderColor: ["#FF9E04"],
                    clipMarkers: false,
                    yaxis:{
                        lines:{show:false}
                    },
                },
                markers: {
                    size: 0,
                },
                tooltip:{
                    theme: "dark"
                },
                labels: Array.from({length: this.info.points}, (_, i) => i),
                xaxis:{
                    labels:{
                        show:false
                    }
                },
                yaxis:{
                    show: true,
                    forceNiceScale: false,
                    tickAmount: 3,
                    showAlways: true,
                    max: this.info.maximum,
                    min :this.info.minimum,
                    labels:{show:true, style:{colors:['white']}}
                },
                

            }
        },

        min(){
            return this.info.minimum != undefined ? this.info.minimum : 0;
        },

        max(){
            return this.info.maximum != undefined ? this.info.maximum : 100;
        },

        valueInPercent(){
            var lastValue = this.valuesArray[this.valuesArray.length - 1]
            let valor = Math.max(this.min, Math.min(lastValue, this.max));

            // Calcula a porcentagem com base no intervalo [minimo, maximo]
            const intervalo = this.max - this.min;
            const porcentagem = ((valor - this.min) / intervalo) * 100;

            return porcentagem;
        },
        color(){
            var lastValue = this.valueInPercent
            var color = "#FF9E04"
            this.info.divisions.forEach(el => {
                if(el.start <= lastValue && lastValue <= el.end) color = el.color
            })
            return color
        },
        fontSize(){
            return `${(this.width * this.height) / 6000}px`
        },
    },
    methods:{
        resizeMeasurer(width, height){
            if(this.fullscreen){
                this.height = window.innerHeight / 100 * 50
                this.width = window.innerWidth   / 100 * 75
            }
            else{
                this.width  = width * 0.8
                this.height = height * 0.7
            }
        },
        updateSeriesLine() {

            var area = document.querySelector(".apexcharts-area-series")
            if(area == null) this.refreshChart()
            this.$refs.realtimeChart.updateOptions({
                series: [{
                    data: this.valuesArray,
                }],
                colors: [this.color],
                
                fill:{
                    shade: 'dark',
                    colors: [this.color]
                },
                grid:{
                    borderColor: [this.color],
                    clipMarkers: false,
                    yaxis:{
                        lines:{show:false}
                    },
                    xaxis: {
                        lines: {show:false}
                    }
                },
                xaxis:{
                    labels:{
                        show:false
                    }
                },
            })
        },
        async refreshChart(){
            this.showChart = false
            await this.$nextTick()
            this.showChart = true
        }
    },
    mounted(){
        this.counter = 0
        // const refresh = 10000
        const refresh = (this.info.refreshRate * 1000) 
        const timeout = setTimeout(()=>{
            clearTimeout(timeout)
        },500)
        this.updateInterval = setInterval(()=>{
            this.counter += 1
            if(this.valuesArray.length == this.info.points) this.valuesArray.shift()
            this.valuesArray.push(this.value )
            try{
                // if(this.counter == this.info.points){
                    this.counter = 0;
                    this.updateSeriesLine()
                // }
            }catch (e){
                console.error(e)
            }

        }, refresh)
    },
    beforeDestroy(){
        clearInterval(this.updateInterval)
    },
    data(){
        return{
            showChart: true,
            counter: 0,
            width: 200,
            height: 200,
            valuesArray: [],
            dados: [{
                data: Array.from({length: this.info.points}, () => 0)
            }],
        }
    },
    
}
</script>

<style lang="scss" scoped>
    h3{
        font-size: max(v-bind(fontSize), 1.5rem);
    }
    h6{
        font-size: max(calc(v-bind(fontSize)/2), 0.75rem);
    }
    .container{
        overflow: hidden !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
</style>