<template>
    <div class="w-100" :key="tableKey" :id="tableId">
        <div class="w-100" :class="{ 'isAddBtnSticky': isSticky }">
            <b-container>
                <b-row>
                    <b-col v-if="(showForSecundary || $session.get('privilege') != 2) && showAddButton"
                        class="d-flex flex-start align-items-center">
                        <button type="button" class="expandAddButton" @click="toggleNew()">
                            <b-icon-plus-lg />
                            <span id = 'new-btn' class="text">{{ $i18n.t('new') }}</span>
                        </button>
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <transition name="fade">
            <div id="new-form" class="w-100 new-item-container mb-2" v-if="computedOpenTable">
                <div class="newItem details-container w-100">
                    <b-icon-x-lg class="close-btn" @click="closeComponent" style="z-index: 9 !important;" />
                    <component class="text-center" :id="0" :is="componentDetails" :parameter="parameter"
                        @addElement="addElement" @updateElement="updateElement" @removeElement="removeElement"
                        @cancel="closeComponent" :showCancel="true" />
                </div>
            </div>
        </transition>

        <b-table striped primary-key="id" thead-class="green-bg bg-dark text-white" style="text-align: center"
            :per-page="perPage" :current-page="currentPage" :responsive="!showStacked" :stacked="showStacked"
            :items="computedItems" :fields="computedFields" :filter="filter" :busy="isLoading" @row-hovered="onRowHover"
            @row-unhovered="onRowUnhover" @row-dblclicked="onRowDoubleClick" fixed>

            <template #table-busy>
                <b-skeleton class="skeleton-card" />
                <b-skeleton class="skeleton-card" />
                <b-skeleton class="skeleton-card" />
            </template>

            <template #cell(details)="row">
                <b-button :id="row.item.id" class="showDetailsButton" variant="outline-light" size="sm"
                    @click="toggleFirstDetails(row)">
                    <b-icon :icon="toggleRowButtonLabel(row)" />
                </b-button>
                <b-button v-if="secondDetails" :id="row.item.id" variant="outline-light" size="sm"
                    @click="toggleSecondDetails(row)" class="ml-1">
                    {{ $i18n.t(secondDetailsText) }}
                </b-button>
            </template>

            <template #cell(actions)="row">
                <div class="w-100 d-flex justify-content-center">
                    <div class="ml-2 mr-2" v-for="btn in filteredActions" :key="btn.id"  v-b-tooltip.hover
                        :title="$i18n.t(btn.name)">
                        <b-icon :icon="btn.icon" @click="pressIconButton(btn, row)" class="actionBtnIcon"
                            :style="{ '--hover-color': btn.color }" />
                    </div>
                </div>
            </template>

            <template #cell(status)="data">
                <div v-if="Array.isArray(data.item.status)" class="d-flex justify-content-center" style="gap: 0.5rem">
                    <div v-for="(status, index) in data.item.status" v-b-tooltip.hover :title="status.name"
                        :key="`${data.item.id}_${index}_${status.name}`">
                        <div class="status-ball" :class="{ 'all-active': status.value, 'none-active': !status.value }" />
                    </div>
                </div>
                <div v-else class="d-flex justify-content-center">
                    <div class="status-ball" :class="statusBallClass(data.item.status)" v-b-tooltip.hover
                        :title="statusTitle(data.item.status)" />
                </div>
            </template>

            <template #cell(module)="data">
                <div class="d-flex justify-content-center align-items-center"
                    :style="isNaN(data.item.module) ? 'justify-content: flex-start !important' : ''">
                    <span class="ellipsisSpan" :title="getSlotName(data)">{{ getSlotName(data) }}</span>
                    <span v-if="data.item.device.networkType != null" class="variableTypeIndicator"
                        :class="{ 'isModbus': data.item.device.networkType == 1 }">
                        {{ data.item.device.networkType == 1 ? "MODBUS" : 'SMNP' }}
                    </span>
                </div>
            </template>

            <template #cell(goToDashboard)="row">
                <div class="w-100 d-flex justify-content-center">
                    <b-button class="goToDashboardButton" variant="outline-light" size="sm" @click="redirectToRoute(row)">
                        <b-icon-display />
                    </b-button>
                </div>
            </template>

            <template #cell(exhibit)="data">
                <div class="w-100 d-flex justify-content-center">
                    <b-button class="goToDashboardButton" variant="outline-light" size="sm"
                        @click="$emit('loadPresentation', data.item.id)">
                        <b-icon-play-fill />
                    </b-button>
                </div>
            </template>

            <template #cell(operator)="data">
                {{ $i18n.t(data.item.operator) }}
            </template>

            <template #cell(priority)="data">
                {{ data.item.alarmPriorities ? $i18n.t(data.item.alarmPriorities.name) : "" }}
            </template>

            <template #cell(operation)="data">
                {{ $i18n.t(data.item.operation) }}
            </template>

            <template #cell(color)="data">
                <div class="w-100 h-100"
                    :style="`background-color: ${data.item.color}; color: ${data.item.color} !important;`">
                    {{ data.item.color }}</div>
            </template>

            <!-- Flags Do Recipient Group -->
            <template #cell(alarmsEnable)="data">
                <b-icon :icon="isEnabledIcon(data.item.alarmsEnable)" />
            </template>
            <template #cell(faultsEnable)="data">
                <b-icon :icon="isEnabledIcon(data.item.faultsEnable)" />
            </template>
            <template #cell(statusEnable)="data">
                <b-icon :icon="isEnabledIcon(data.item.statusEnable)" />
            </template>

            <template #row-details="row">
                <div class="w-100 p-0 pt-1">
                    <div class="details-container w-100">
                        <b-icon-x-lg class="close-btn" @click="row.toggleDetails" />
                        <component class="text-center" :is="componentDetails" :parentId="parentId" :row=row :id="row.item.id"
                            :parameter="parameter" @addElement="addElement" @updateElement="updateElement"
                            @cancel="row.toggleDetails" @removeElement="removeElement" @loading="loading"
                            @loaded="loaded" />
                    </div>
                </div>
            </template>
        </b-table>

        <section class="w-100 d-flex justify-content-end" v-if="!isLoading">
            <div class="paginationContainer">
                <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="computedItems.length" size="sm"
                    page-class="tableBorderedPage" first-class="tableBorderedGoToFirst" last-class="tableBorderedGoToLast"
                    next-class="tableBorderedGoToNext" prev-class="tableBorderedGoToPrev" hide-ellipsis></b-pagination>
                <div class="paginationNumberOfRowsContainer">
                    <label for="paginationNumberOfRows" class="numberOfRowsLabel">Número de linhas por página: </label>
                    <input id="paginationNumberOfRows" type="number" v-model="perPage"
                        @change="changedNumberOfRowsPerPage" />
                </div>
            </div>
        </section>

        <info-modal v-if="openAsModal" id="infoModal" ref="modal">
            <template #header>
                <div class="d-flex flex-column justify-content-between w-100">
                    <h4 class="w-100 d-flex">{{ $i18n.t(component) }}
                        <div v-if="component === 'user-relation'" id="userRelationIndicator" class="ml-1">
                            <!-- Os dados são alterados em UserRelation.vue no fim de created -->
                            <span>Pai</span>
                            <b-icon-arrow-left-right />
                            <span>Fiho</span>
                        </div>
                    </h4>
                </div>
            </template>

            <template #body>
                <component class="text-center" :is="componentDetails" :parentId="parentId" :row=componentRow
                    :id="componentRow.item.id" :parameter="parameter"  @addElement="addElement" @updateElement="updateElement" @loading="loading"
                    @removeElement="removeElement" @loaded="loaded" isInModal />
            </template>

            <template #footer>
                <span />
            </template>
        </info-modal>

    </div>
</template>

<script>
/* eslint-disable */
import ActionButton from '../button/MtwButton';

export default {
    components: {
        ActionButton,
    },
    props: {
        parentId: {
            type: Number,
            default: 0,
            required: false,
        },
        tableId: {
            type: String,
            default: "table",
        },
        showForSecundary: {
            type: Boolean,
            required: false,
            default: false,
        },
        showAddButton: {
            type: Boolean,
            required: false,
            default: true,
        },
        title: {
            type: String,
            default: "",
            required: false
        },
        hover: {
            type: Boolean,
            required: false,
            default: true,
        },
        items: {
            type: Array,
            required: true
        },
        fields: {
            type: Array,
            required: true
        },
        componentDetails: {
            type: String,
            required: true
        },
        parameter: {
            type: Object,
            required: false,
        },
        route: {
            type: String,
            required: false,
            default: 'dashboard',
        },
        secondDetails: {
            type: Boolean,
            required: false,
            default: false,
        },
        secondDetailsText: {
            type: String,
            required: false,
            default: "show",
        },
        secondComponentDetails: {
            type: String,
            required: false
        },
        actionsButton: {
            type: Array,
            required: false,
            default() {
                return []
            }
        },
        openAsModal: {
            type: Boolean,
            required: false,
            default: false
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        textFilter: {
            type: String,
            required: false,
            default: ""
        },
        sticky: {
            type: Boolean,
            required: false,
            default: false
        },
    },

    data() {
        return {
            currentPage: 1,
            perPage: 15,

            showIdToggle: false,

            localItems: [],
            newElement: false,
            component: "",
            detailsIds: [],
            tableKey: 0,
            transProps: {
                name: 'fade',
                mode: 'out-in'
            },
            showFirtsDetails: false,
            showSecondDatails: false,
            isBusy: false,
            showStacked: false,
            componentRow: { item: { id: 0 } },
        }
    },
    methods: {
        changedNumberOfRowsPerPage() {
            if (this.$setNumberOfRowsPerPage) this.$setNumberOfRowsPerPage(this.perPage)
            // this.$emit("changeNumberOfRows", this.perPage)
        },

        getSlotName(data) {
            return isNaN(data.item.module) ? data.item.module : `Slot ${parseInt(data.item.module) + 1}`
        },

        isEnabledIcon(state) {
            return state ? "check2-circle" : "x-circle"
        },

        closeComponent() {
            this.newElement = false
        },

        onRowHover(item, index, event) {
            if (this.hover) this.$set(item, '_rowVariant', 'info')
        },

        onRowUnhover(item, index) {
            if (this.hover) this.$delete(item, '_rowVariant')
        },

        onRowDoubleClick(item, index) {
            const id = item.id
            this.$emit('onRowDoubleClick', item);
            //this.$router.push(`/${this.route}/${id}`);
        },

        redirectToRoute(row) {
            const id = row.item.id
            this.$router.push(`/${this.route}/${id}`);
        },
        pressIconButton(btn, row) {
            const item = row.item
            this.$emit('action', btn)
            if (btn.component != undefined) {
                this.component = btn.component
                this.componentRow = row
                this.$bvModal.show('infoModal')
            }
            else {
                btn.action(item)
            }
        },
        toggleNew() {
            if (this.openAsModal) {
                this.componentRow = { item: { id: 0 } },
                this.$bvModal.show('infoModal')
            } else {
                this.newElement = !this.newElement;
                if (this.newElement) {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    })
                }
            }

        },

        toggleFirstDetails(row) {
            this.component = this.componentDetails;
            if (this.openAsModal) {
                this.$bvModal.show('infoModal')
                this.componentRow = row
            }
            else {
                row.toggleDetails();
                if (row.detailsShowing)
                    this.detailsIds = this.detailsIds.filter(el => { return el != row.item.id })
                else
                    this.detailsIds.push(row.item.id)
            }
        },
        toggleRowButtonLabel(row) {
            return row.detailsShowing ? 'arrows-fullscreen' : 'arrows-fullscreen'
        },

        toggleSecondDetails(row) {
            this.component = this.secondComponentDetails;
            row.toggleDetails();
            if (row.detailsShowing)
                this.detailsIds = this.detailsIds.filter(el => { return el != row.item.id })
            else
                this.detailsIds.push(row.item.id)
        },
        
        async addElement(element) {
            this.localItems.push(element.value);
            
            //if (this.$refs.modal) {
            //    this.$refs.modal.closeModal();
            //}

            const sleep = () => { return new Promise(resolve => setTimeout(resolve, 1000)) }
            await sleep()
            this.toggleNew(element.row)

            this.$emit('addAction', element);
            this.tableKey += 1;
            this.$nextTick();

        },

        closeModal() {
            this.$refs.modalInstance.hide(); // Fecha APENAS essa instância da modal
        },

        updateElement(element) {
            this.localItems.forEach((el, index, array) => {
                if (el.id != null && el.id != undefined) {
                    if (el.id === element.value.id) {
                        array[index] = element.value;
                    }
                }
            });
            
            setTimeout(() => {
                try {
                    this.toggleFirstDetails(element.row)
                } catch {
                    if (this.$refs.modal) {
                        this.$refs.modal.closeModal();
                    }
                }

                this.$emit('addAction', element);
                this.tableKey += 1;
                this.$nextTick();
            }, 1000);

            if (!(element.element instanceof this.$colorConfig)){
                if (this.$refs.modal) {
                    this.$refs.modal.closeModal();
                }
            }
        },

        removeElement(element) {
            this.toggleFirstDetails(element.row);
            this.localItems = this.localItems.filter(el => {
                if (el.id != null && el.id != undefined) {
                    if (el.id != element.value.id) {
                        return el;
                    }
                }
            });
            
            this.$bvModal.hide('infoModal');
            this.$emit('removeAction', element);
            this.tableKey += 1;
            this.$nextTick();
        },

        ballStatus(status) {
            var allActive = false
            var noneActive = false
            var someActive = false
            var noItems = false
            if (status == undefined) noItems = true
            else {
                var { amount, active } = status
                if (amount == 0) noItems = true
                else if (amount == active) allActive = true
                else if (active == 0) noneActive = true
                else someActive = true
            }
            return [allActive, noneActive, someActive, noItems]
        },

        statusTitle(status) {
            var [allActive, noneActive, someActive, noItems] = this.ballStatus(status)
            if (noItems) return 'Indisponivel'
            else if (allActive) return 'Todos os módulos ligados'
            else if (noneActive) return 'Todos os módulos desligados'
            else return 'Alguns modulos ligados'
        },

        statusBallClass(status) {
            var [allActive, noneActive, someActive, noItems] = this.ballStatus(status)
            return { 'all-active': allActive, 'none-active': noneActive, 'some-active': someActive, 'no-items': noItems }
        },

        hasItems(status) {
            return !isNaN(status)
        },

        loading() {
            this.isBusy = true;
        },

        loaded() {
            this.isBusy = false;
        },
        windowResize() {
            const screenWidth = window.innerWidth
            this.showStacked = screenWidth >= 320 && screenWidth <= 991
        }

    },

    computed: {
        filter() {
            return this.textFilter
        },

        filteredActions(){
            return this.actions.filter(btn => btn.show);
        },

        isSticky() {
            return this.sticky
        },
        computedOpenTable() {
            return this.newElement;
        },

        computedItems() {
            return this.localItems.map(item => {
                if (this.componentDetails == 'alarm-info') {
                    var isAlarmed = window.Alarm.GetAlarmMapValue(`alarm/${item.id}`).value == 2
                    if (isAlarmed) item._rowVariant = 'danger'
                    else item._rowVariant = ''
                } else if (this.componentDetails == 'dashboard-info') {
                    var alarmsId = item.alarmsInDashboard
                    var isAlarmed = false
                    if (alarmsId) {
                        alarmsId.forEach(alarm => {
                            if (!isAlarmed) {
                                isAlarmed = window.Alarm.GetAlarmMapValue(`alarm/${alarm}`).value == 2
                            }
                        })
                    }
                    if (isAlarmed) item._rowVariant = 'danger'
                    else item._rowVariant = ''

                }
                return item
            })
        },

        computedFields() {
            var translatedFields = [];
            if (this.showIdToggle) {
                translatedFields.push({
                    key: 'id',
                    label: this.$i18n.t('id'),
                    sortable: true,
                });
            }
            this.fields.forEach((field) => {
                if (!(this.$session.get('privilege') == 2 && (field.key == 'actions' || field.key == 'details'))) {
                    translatedFields.push({
                        key: field.key,
                        label: this.$i18n.t(field.label),
                        thStyle: field.thStyle,
                        sortable: field.sortable || false
                    });
                } else if (this.$session.get('privilege') == 2 && field.key == 'details' && (this.componentDetails == 'presentation-info' || this.componentDetails == 'dashboard-info')) {
                    translatedFields.push({
                        key: field.key,
                        label: this.$i18n.t(field.label),
                        thStyle: field.thStyle,
                        sortable: field.sortable || false
                    });
                }
            });
            return translatedFields;
        },

        actions() {
            return this.actionsButton
        }
    },

    created() {
        this.counter = 0
        this.perPage = this.$getNumberOfRowsPerPage ? this.$getNumberOfRowsPerPage() : 15

        const keydown = (evt) => {
            if (evt.shiftKey && evt.keyCode === 78) {
                this.toggleNew()
            }
            if (evt.shiftKey && evt.keyCode === 73) {
                this.showIdToggle = !this.showIdToggle
            }
        }

        document.onkeydown = keydown;
        this.interval = setInterval(() => {
            if (this.detailsIds.length == 0) {
                if (this.localItems.length == 0) {
                    this.localItems = this.items;
                } else {
                    var itemsMap = this.items.map(a => a.id);
                    var localItemsMap = this.localItems.map(a => a.id);

                    if (this.localItems.length == this.items.length) {
                        itemsMap.forEach(el => {
                            if (!localItemsMap.find(a => a == el)) {
                                this.localItems = [];
                            } else {
                                this.localItems = this.items
                            }
                        })
                    }
                    else {
                        if (this.items.length > this.localItems.length) {
                            this.items.forEach(el => {
                                if (!localItemsMap.find(a => a == el.id)) {
                                    this.localItems.push(el)
                                }
                            })
                        }

                        if (this.items.length < this.localItems.length) {
                            var itemsToRemove = []
                            this.localItems.forEach((el, index) => {
                                if (!itemsMap.find(a => a == el.id))
                                    itemsToRemove.push(index);
                            })
                            var removeCounter = 0
                            itemsToRemove.forEach(element => {
                                this.localItems.splice(element - removeCounter, 1);
                                removeCounter += 1;
                            });

                        }
                    }

                    this.localItems = this.localItems.map((localItem, index) => {
                        var statusUpdateItem = this.items[index]
                        if (statusUpdateItem.status != undefined) {
                            localItem.status = statusUpdateItem.status
                            return localItem
                        }
                        return localItem
                    })
                }
            }
        }, 1000)
        this.windowResize()
        window.addEventListener("resize", e => {
            this.windowResize()
        });
    },

    watch: {
        filter() {
            this.currentPage = 1
        },
    },

    beforeDestroy() {
        clearInterval(this.interval)
        document.onkeydown = null
    },


}
</script>



<style lang="scss">
.paginationContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    ul {
        min-width: 16rem;
        justify-content: space-evenly;
        padding: 0.25rem 1rem;
        background: rgb(var(--tmHubTheme-table-border-color, 222, 224, 227));
        border-radius: 1rem;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        margin: 0;
        align-items: center;
        gap: 0.125rem;

        .tableBorderedGoToFirst,
        .tableBorderedPagEllipsis,
        .tableBorderedPage,
        .tableBorderedGoToLast,
        .tableBorderedGoToNext,
        .tableBorderedGoToPrev {
            display: flex;
            justify-content: center;
            align-items: center;

            &>span,
            &>button {
                height: 1.5rem;
                width: 1.5rem;
                border-radius: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: transparent !important;
                color: #6b6b6b !important;
                border: none;
                user-select: none;
            }

            &.active {
                button {
                    background-color: rgb(var(--tmHubTheme-table-background-color, 0, 123, 255)) !important;
                    color: white !important;
                }
            }

            &.disabled {
                cursor: not-allowed;

                &>* {
                    opacity: 0.66;
                }
            }

            &:hover {
                &:not(.active):not(.disabled) {

                    button,
                    span {
                        background-color: #00000061 !important;
                        color: white !important;
                    }
                }
            }
        }
    }

    .paginationNumberOfRowsContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 0.5rem;
        margin-top: 0.25rem;
        opacity: 0.75;

        .numberOfRowsLabel {
            font-size: 0.7em;
            font-weight: 400;
            margin-top: 0.12rem;
        }

        input {
            height: 1rem;
            width: 3rem;
            background: transparent;
            border: none;
            border-bottom: 1px solid white;
            color: white;
            text-align: center;
            outline: none
        }
    }
}

#userRelationIndicator {
    padding: 0 1rem;
    background: #8080808a;
    border-radius: 0.5rem;
    display: flex;
    border: 1px solid gray;
    gap: 0.5rem;
    align-items: center;
    visibility: hidden;
}

table {
    overflow: hidden;
}

.b-table-has-details {
    display: none;
}

table.b-table-stacked>tbody {

    &>tr>td {
        padding: 0;
        display: flex !important;
        justify-content: center;
        align-items: center;

        &>div {
            padding: 1rem !important;
            border-left: 2px solid white;
            background-color: rgba(0, 0, 0, 0.2);
        }
    }

    &>tr.b-table-details>td {
        &>div {
            padding: 0rem !important;
            border-left: none !important;
            background-color: transparent;
        }
    }
}

@media (min-width: 320px) and (max-width: 991px) {
    .searchContainer {
        display: flex;
        justify-content: center;

        &>div[role="group"] {
            margin: 0 !important;
            width: 100%;
            margin-bottom: 1rem !important;
        }
    }
}

tr.table-info>* {
    background-color: rgba(55, 189, 182, 0.33);
}

.fade-move {
    transition: transform .5s;
}

.fade-enter-active,
.fade-leave-active {
    transition: all 1s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
    transform: translateY(30px);
}

.table {
    margin-bottom: 0;
}

.container {
    max-width: 100% !important;
    width: 100%;
    height: 100%;
}

#relationBetweenUsersName {
    margin-left: 0.5rem;
    gap: 0.5rem;
    display: flex;
    align-items: center;

    .relation_username {
        color: #FF9800
    }

    svg {
        color: #F44336
    }
}

hr {
    width: 100%;
    margin: 0;
    padding: 0;
    background: #acacac;
    height: 2px;
    margin-bottom: 2rem;
}

p {
    opacity: 0.5;
}

tbody {
    color: white
}

.table thead th {
    background: rgb(var(--tmHubTheme-table-background-color, 255, 255, 255));
    border-color: rgb(var(--tmHubTheme-table-background-color, 255, 255, 255));
    color: rgb(var(--tmHubTheme-table-text-color, 0, 0, 0));
}

tr.table-success,
tr.table-success>td {
    background: #38ba953d !important;
}

td {
    max-width: 15ch;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    border-color: rgb(var(--tmHubTheme-table-border-color, 255, 255, 255)) !important;
}

.expandAddButton {
    margin-bottom: 0.5rem;
    background: #2e6d3e;
    border: 1px solid #279544;
    color: white;
    border-radius: 1rem;
    font-size: 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    max-width: 2rem;
    gap: 1.25rem;
    overflow: hidden;
    transition: max-width 0.5s;

    svg {
        margin-left: 0.25rem;
    }

    &:hover {
        max-width: 10rem;
    }
}

.isAddBtnSticky {
    position: sticky;
    top: 84px;
    background-color: #1d252f;
    z-index: 9;
    border-radius: 0 0 1rem 1rem;
    border: 2px solid #5c738b;
    border-top: 0;
    box-shadow: 4px 36px 63px 3px rgba(0, 0, 0, 0.75);
}

.search-group {
    right: 0px;
    max-width: 30% !important;
    min-width: 200px;
    margin: 12px auto;
}

.search-group>.form-control {
    justify-content: flex-end !important;
    border: none;
    color: white;
    background-color: rgba(255, 255, 255, 0.2);

    // border: 0.1px solid rgb(217 217 217 / 89%);
    &:active,
    &:focus {
        color: white;
        background-color: rgba(255, 255, 255, 0.2);
        ;
    }
}

.actionBtnIcon {
    transition: color 0.5s ease;

    &:hover {
        color: var(--hover-color);
        opacity: 0.5;
        transition: color 0.5s ease;
    }

    &:active {
        color: var(--hover-color);
        opacity: 1;
        transition: color 0.5s ease;
    }

}

.input-group-text {
    border: none !important;
    background-color: rgb(26 26 26 / 21%) !important;
    color: white !important;
}

.newItem.details-container {
    position: relative;

    .close-btn {
        position: absolute;
        right: 1rem;
        cursor: pointer;

        &:hover {
            color: red;
        }
    }
}

.details-container {
    -webkit-box-shadow: inset 0px 0px 0px 1px white;
    -moz-box-shadow: inset 0px 0px 0px 1px white;
    box-shadow: inset 0px 0px 0px 1px white;
    background:
        linear-gradient(to right, white 4px, transparent 4px) 0 0,
        linear-gradient(to right, white 4px, transparent 4px) 0 100%,
        linear-gradient(to left, white 4px, transparent 4px) 100% 0,
        linear-gradient(to left, white 4px, transparent 4px) 100% 100%,
        linear-gradient(to bottom, white 4px, transparent 4px) 0 0,
        linear-gradient(to bottom, white 4px, transparent 4px) 100% 0,
        linear-gradient(to top, white 4px, transparent 4px) 0 100%,
        linear-gradient(to top, white 4px, transparent 4px) 100% 100%;

    background-repeat: no-repeat;
    background-size: 20px 20px;

    padding: 1rem;
    background-color: hsla(206, 29%, 17%, 0.66);
    background-color: rgba(var(--tmHubTheme-primary-color), 0.66);
    position: relative;

    .close-btn {
        position: absolute;
        right: 1rem;
        z-index: 9 !important;
        cursor: pointer;

        &:hover {
            color: red;
        }
    }
}

.new-item-container:has(.detailsContainer) {
    padding: 1rem;
    border-top: 1px solid white
}

.new-item {
    transform: scaleY(0);
    transition: transform 0.25s ease-in-out;
    transform-origin: top;
    height: 0;

}

.new-item__details-container {
    height: auto;
    transform: scaleY(1);
    transition: transform 0.25s ease-in-out;
    transform-origin: top;
    outline: 1px solid limegreen;
    outline-offset: -6px;

}

.status-ball {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    border: 2px solid #666d73;
    opacity: 1;
    animation: blink 0.75s ease-in-out alternate-reverse infinite;

    &.none-active {
        background: #dc3545;
    }

    &.all-active {
        background: #8BC34A;
    }

    &.some-active {
        background: #FFEB3B;
    }

    &.no-items {
        background: #424242;
        animation: none
    }
}

.goToDashboardButton {
    // w-75 d-flex justify-content-center align-items-center
    text-align: center;
}

.table-danger>td {
    background-color: #7d2831c7;
    border-color: #F44336;
}

table.b-table[aria-busy='true'] {
    opacity: 1 !important;
}

tr.b-table-busy-slot {
    td {
        padding: 0;

        div.b-skeleton {
            border-radius: 0;
            border-bottom: 2px solid white;
            height: 4rem;
            margin-bottom: 0
        }
    }
}

.variableTypeIndicator {
    background-color: #2f7a58;
    position: absolute;
    left: -3rem;
    padding: 0.25rem;
    transform: translateY(20%);
    border-radius: 0.25rem 0 0 0.25rem;
    font-size: 0.8rem;

    &:after {
        position: absolute;
        display: flex;
        height: 0;
        width: 0;
        border-top: solid 0.8rem transparent;
        content: "";
        border-left: solid 0.8rem #2f7a58;
        border-bottom: solid 1rem transparent;
        left: 100%;
        top: 0;
    }

    &.isModbus {
        background-color: #2f7888;

        &:after {
            border-left-color: #2f7888;
        }
    }
}

.ellipsisSpan {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@keyframes blink {
    0% {
        opacity: 0.625;
    }

    100% {
        opacity: 1;
    }
}</style>