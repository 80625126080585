import Shepherd from 'shepherd.js';
import 'shepherd.js/dist/css/shepherd.css';
//import Steps from './tourSteps';
//import Vue from 'vue';
//import { checkElement, createModal} from './func.js';

export function createTour() {

    const tour = new Shepherd.Tour({
        useModalOverlay: true, 				
        confirmCancel: true,
        confirmCancelMessage: 'Sair do tutorial?',
        tourName: 'starterTutorial',
        defaultStepOptions: {     //Default options for Steps created through addStep. Saves you from repeating step related options each time.          
            cancelIcon: {
                enabled: true
            },
            scrollTo: true,
        }
    });

    console.log(tour);
//      tour.addStep({
//          title: translate('welcomeTour'),
//          id: 'step0',
//          text: translate('tutorialStep0'),
//          buttons: [
//              {
//                  text: translate('next'),
//                  action: tour.next
//              }
//          ]
//      }),

//      tour.addStep({
//          title: 'Passo 1',
//          id: 'step1',
//          text: translate('tutorialStep1'),
//          attachTo : {
//              element: '.navbar',
//              on: 'bottom'
//          },
//          buttons: [
//              {
//                  text: translate('next'),
//                  action: tour.next
//              }
//          ]
//      }),

//      tour.addStep({
//          title: 'Passo 2',
//          id: 'step2',
//          text: translate('tutorialStep2'),
//          attachTo : {
//              element: '#btn-sidenav',
//              on: 'bottom'
//          },
//          advanceOn: { selector: '#btn-sidenav', event: 'click' }, //click event to advance tour
//      }),

//      tour.addStep({
//          title: 'Passo 3',
//          id: 'step3',
//          text: translate('tutorialStep3'),
//          attachTo : {
//              element: '#sidebar',
//              on: 'right'
//          },
//          buttons: [
//              {
//                  text: translate('next'),
//                  action: tour.next
//              }
//          ]
//      }),

//      tour.addStep({
//          title: 'Passo 4',
//          id : 'step4',
//          text: translate('tutorialStep4'),
//          attachTo: {
//              element: '#config-link', 
//              on: 'top'
//          },
//          advanceOn: { selector: '#config-link', event: 'click' },
//      }),

//      //DEVICE/DISPOSITIVO STEPS

//      tour.addStep({
//          title: 'Passo 5',
//          id : 'step5',
//          text: translate('tutorialStep5'),
//          attachTo: {
//              element: '#devices-link',
//              on: 'top'
//          },
//          advanceOn: { selector: '#devices-link', event: 'click' },
//      }),

//      tour.addStep({
//          title: 'Passo 6',
//          id : 'step6',
//          text: translate('tutorialStep6'),
//          beforeShowPromise: () => {
//              return checkElement('#table-device > div.w-100 > div > div > div > button')},
//          attachTo: {
//              element: '#table-device > div.w-100 > div > div > div > button', 		
//              on: 'bottom'
//          },
//          advanceOn: { selector: '#table-device > div.w-100 > div > div > div > button', event: 'click' },
//      }),

//      tour.addStep({
//          title: 'Passo 7',
//          id : 'step7',
//          text: translate('tutorialStep7'),
//          beforeShowPromise: () => {
//              return checkElement('#serialNumberInput')},
//          attachTo: {
//              element: '#serialNumberInput', 		
//              on: 'bottom'
//          },
//          //usar click para testes
//          advanceOn: { selector: '#serialNumberInput', event: 'click' },
//      }), 

//      tour.addStep({
//          title: 'Passo 8',
//          id : 'step8',
//          text: translate('tutorialStep8'),
//          beforeShowPromise: () => {
//              return checkElement('#new-form > div')},
//          attachTo: { 
//              element: '#new-form > div',
//              on: 'bottom',
//          },
//          advanceOn: { selector: '#new-form > div', event: 'click' },
//      }), 

//      tour.addStep({
//          title: 'Passo 9',
//          id : 'step9',
//          text: translate('tutorialStep9'),
//          attachTo: { element: '#btn-sidenav', on: 'right' },
//          advanceOn: { selector: '#btn-sidenav', event: 'click' },
//      }),

//     //--------- VARIABLES STEPS
    
//      tour.addStep({
//          title: 'Passo 10',
//          id: 'step10',
//          text: translate('tutorialStep10'),
//          attachTo: { element: '#variables-link', on: 'right' },
//          advanceOn: { selector: '#variables-link', event: 'click' },
//      }),

//      tour.addStep({
//          title: 'Passo 11',
//          id: 'step11',
//          text: translate('tutorialStep11'),
//          beforeShowPromise: () => {
//              return checkElement('#table-variable > div.w-100 > div > div > div > button')},
//          attachTo: { element: '#table-variable > div.w-100 > div > div > div > button', on: 'bottom' },
//          advanceOn: { selector: '#table-variable > div.w-100 > div > div > div > button', event: 'click' },
//      }),
//      ///explicar mask, mult, const, amostra e unidade

//      tour.addStep({
//          title: 'Passo 12',
//          id: 'step12',
//          text: translate('tutorialStep12'),
//          beforeShowPromise: () => {
//              return checkElement('#maskInput')
//          },
//          attachTo: { element: '#maskInput', on: 'top' },
//          buttons: [
//              {
//                  text: translate('next'),
//                  action: tour.next
//              }
//          ]
//      }),

//      tour.addStep({
//          title: 'Passo 13',
//          id: 'step13',
//          text: translate('tutorialStep13'),
//          beforeShowPromise: () => {
//             return createModal();
//          },
//          attachTo: { element: '#multiplierInput', on: 'bottom' },
//          buttons: [
//              {
//                  text: translate('next'),
//                  action: tour.next,
//              },
//          ],
//      });

//      tour.addStep({
//          title: 'Passo 14',
//          id: 'step14',
//          text: translate('tutorialStep14'),
//          attachTo: { element: '#constantInput', on: 'top' },
//          buttons: [
//              {
//                  text: translate('next'),
//                  action: tour.next
//              }
//          ]
      
//      }),

//      tour.addStep({
//          title: 'Passo 15',
//          id: 'step15',
//          text: translate('tutorialStep15'),
//          attachTo: { element: '#meanInput', on: 'top' },
//          buttons: [
//              {
//                  text: translate('next'),
//                  action: tour.next
//              }
//          ]
//      }),

//      tour.addStep({
//          title: 'Passo 16',
//          id: 'step16',
//          text: translate('tutorialStep16'),
//          beforeShowPromise: () => { 
//              return checkElement('#moduleInput')
//          },
//          attachTo: { element: '#moduleInput', on: 'top' },
//          buttons: [
//              {
//                  text: translate('next'),
//                  action: tour.next
//              }
//          ]
//      }),

//      tour.addStep({
//          title: 'Passo 17',
//          id: 'step17',
//          text: translate('tutorialStep17'),
//          beforeShowPromise: () => { 
//              return checkElement('#variableInput')
//          },
//          attachTo: { element: '#variableInput', on: 'top' },
//          buttons: [
//              {
//                  text: translate('next'),
//                  action: tour.next
//              }
//          ]
//      }),

//      tour.addStep({
//          title: 'Passo 18',
//          id: 'step18',
//          text: translate('tutorialStep18'),
//          attachTo: { element: '#btn-grid > div.btn.upload', on: 'bottom' },
//          advanceOn: { selector: '#btn-grid > div.btn.upload', event: 'click' },
       
//      }),

//      tour.addStep({
//           title: 'Passo 19',
//           id: 'Step19',
//           text: translate('tutorialStep19'),
//           attachTo: {
//               element: '#infoModal___BV_modal_header_ > button', 		
//               on: 'right'
//           },
//           advanceOn: { selector: '#infoModal___BV_modal_header_ > button', event: 'click' },
//       }),
// //--------FIM VARIABLES STEPS

// //-------DISPLAY STEPS

//       tour.addStep({
//           title: 'Passo 20',
//           id: 'Step20',
//           text: translate('tutorialStep20'),
//           attachTo: {
//               element: "#btn-sidenav", 		
//               on: 'right'
//           },
//           advanceOn: { selector: '#btn-sidenav', event: 'click' },
//       }),

//       tour.addStep({
//           title: 'Passo 21',
//           id: 'Step21',
//           text: translate('tutorialStep21'),
//          // beforeshowPromise para testes, apagar depois
//         //   beforeShowPromise: () => {
//         //      const configBtn = document.querySelector('#config-link');
//         //      if (configBtn) {
//         //          configBtn.click();
//         //      }
//         //  },
//           attachTo: {
//               element: "#display-link", 		
//               on: 'top'
//           },
//           advanceOn: { selector: '#display-link', event: 'click' },
//       }),

//       tour.addStep({
//           title: 'Passo 22',
//           id: 'Step22',
//           text: translate('tutorialStep22'),
//           beforeShowPromise: () => {
//                  return checkElement('#table > div.w-100 > div > div > div > button')},
//           attachTo: {
//               element: "#table > div.w-100 > div > div > div > button", 		
//               on: 'top'
//           },
//           advanceOn: { selector: '#table > div.w-100 > div > div > div > button', event: 'click' },
//       }),

//       tour.addStep({
//           title: 'Passo 23',
//           id: 'Step23',
//           text: translate('tutorialStep23'),
//           attachTo: {
//              element: "#nameInput", 		
//              on: 'top'
//           },
//              buttons: [
//                  {
//                      text: translate('next'),
//                      action: tour.next
//                  }
//              ]
//       })

//       tour.addStep({
//           title: 'Passo 24',
//           id: 'Step24',
//           text: translate('tutorialStep24'),
//           attachTo: {
//               element: "#typeInput", 		
//               on: 'right'
//           },
//           buttons: [
//               {
//                   text: translate('next'),
//                   action: tour.next
//               }
//           ]
//       }),

//       tour.addStep({
//           title: 'Passo 25',
//           id: 'Step25',
//           text: translate('tutorialStep25'),
//           attachTo: {
//               element: "#minInput", 		
//               on: 'right'
//           },
//           buttons: [
//               {
//                   text: translate('next'),
//                   action: tour.next
//               }
//           ]
//       }),

//       tour.addStep({
//           title: 'Passo 26',
//           id: 'Step26',
//           text: translate('tutorialStep26'),
//           attachTo: {
//               element: "#maxInput", 		
//               on: 'right'
//           },
//           buttons: [
//               {
//                   text: translate('next'),
//                   action: tour.next
//               }
//           ]
//       }),

//       tour.addStep({
//           title: 'Passo 27',
//           id: 'Step27',
//           text: translate('tutorialStep27'),
//          // Avançar esse passo apos salvar o modelo criado - interceptor
//           extraHighlights: [ '#infoModal' ],
//           attachTo: {
//               element: "#btn-grid > div.btn.upload", 		
//               on: 'right'
//           },
//          advanceOn: { selector: '#btn-grid > div.btn.upload', event: 'click' },
//       }),

    //     tour.addStep({
    //         title: 'Passo 28',
    //         id: 'Step28',
    //         text: translate('tutorialStep28'),
    //         beforeShowPromise: () => {
    //             const userService = new this.$userService();
    //             console.log('userService: ', userService);
    //             let step = userService.getStep(1);
    //             console.log('Step: ', step);

    //             return checkElement('#search-table')  
    //         },
    //         attachTo: {
    //             element: "#search-table", 		
    //             on: 'right'
    //         },
    //         buttons: [
    //             {
    //                 text: translate('next'),
    //                 action: tour.next
    //             }
    //         ]
    //     }),

    //     tour.addStep({
    //         title: 'Passo 29',
    //         id: 'Step29',
    //         text: translate('tutorialStep29'),
    //         beforeShowPromise: () => {
    //             return checkElement("#table > div.table-responsive")
    //         },
    //         when: {
    //             show: () => {
    //                 const arrow = document.querySelector('.shepherd-arrow');
    //                 if (arrow) {
    //                     arrow.style.left = '350px';
    //                 }
    //             }
    //         },
    //         attachTo: {
    //             element: "#table > div.table-responsive", 		
    //             on: 'top-end'
    //         },
    //         advanceOn: { selector: "#table > div.table-responsive", event: 'click' },

    //     }),
    //     tour.addStep({
    //         title: 'Passo 30',
    //         id: 'Step30',
    //         text: translate('tutorialStep30'),
    //         beforeShowPromise: () => {
    //             return checkElement("#infoModal___BV_modal_content_")
    //         },
    //         attachTo: {
    //             element: "#infoModal___BV_modal_body_ > div > form > div.w-100.btnGroup > div > div.relationItem", 		
    //             on: 'top'
    //         },
    //         advanceOn: { selector: "#infoModal___BV_modal_body_ > div > form > div.w-100.btnGroup > div > div.relationItem", event: 'click' },
    //     }),

    //     tour.addStep({
    //         title: 'Passo 31',
    //         id: 'Step31',
    //         text: translate('tutorialStep31'),
    //         beforeShowPromise: () => {
    //             return checkElement("#colorConfigTable > div.w-100 > div > div > div > button")
    //         },
    //         attachTo: {
    //             element: "#colorConfigTable > div.w-100 > div > div > div > button", 		
    //             on: 'top'
    //         },
    //         advanceOn: { selector: "#colorConfigTable > div.w-100 > div > div > div > button", event: 'click' },
    //     }),

    //     tour.addStep({
    //         title: 'Passo 32',
    //         id: 'Step32',
    //         text: translate('tutorialStep32'),
    //         extraHighlights: ['#minInput'],
    //         canClickTarget: false,
    //         attachTo: {
    //             element: "#maxInput", 		
    //             on: 'right'
    //         },
    //         buttons: [
    //             {
    //                 text: translate('next'),
    //                 action: tour.next
    //             }
    //         ]
    //     }),

    //     tour.addStep({
    //         title: 'Passo 33',
    //         id: 'Step33',
    //         text: translate('tutorialStep33'),
    //         extraHighlights: ['#startInput, #endInput'],
    //         attachTo: {
    //             element: "#textInput", 		
    //             on: 'bottom-start'
    //         },
    //         buttons: [
    //             {
    //                 text: translate('next'),
    //                 action: tour.next
    //             }
    //         ]
    //     }),

    //     tour.addStep({
    //         title: 'Passo 34',
    //         id: 'Step34',
    //         text: translate('tutorialStep34'),
    //         attachTo: {
    //             element: "#new-form > div > div > form > div.config > div.control.d-flex.flex-column.align-items-start > input", 		
    //             on: 'top'
    //         },
    //         advanceOn: { selector: "#new-form > div > div > form > div.config > div.control.d-flex.flex-column.align-items-start > input", event: 'click' },
    //     }),

    //     tour.addStep({
    //         title: 'Passo 35',
    //         id: 'Step35',
    //         text: translate('tutorialStep35'),
    //         attachTo: {
    //             element: "#new-form > div", 		
    //             on: 'top'
    //         },
    //     }),

    // tour.start();
}