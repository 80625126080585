<template> 
    <div class="w-100 h-100 toastContainer">
        <div class="toastHeader">
            <div class="iconAlign">
                <b-icon :icon="iconName"/>
                <h5 class="m-0">{{notificationType}} <strong>{{name}}</strong> {{message.value}}</h5>
            </div>
            <div class="iconAlign">
                <b-icon-clock-fill/>
                <p class="m-0">{{message.time}}</p>
            </div>
            <b-icon-x-lg class="closeToastIcon" @click="close"/>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
        }
    },
    props: {
        message: {
            required:true,
            type: Object
        },
        i18n:{
            required: true,
            type: undefined
        },
    },
    computed:{
        notificationType(){
            return this.i18n.t(this.message.isAlarm ? "alarm" : "module")
        },
        notificationStatus(){
            return this.i18n.t(this.message.value.toLocaleLowerCase())
        },
        alarmId(){
            return this.message.id || 0
        },
        name(){
            return this.message.target
        },
        descriptionColor(){
            return this.message.value == 'Online' || this.message.value == "Normal"  ? "#83ecc2" : "#df3e20"
        },
        itemNameColor(){
            return this.message.value == 'Online' || this.message.value == "Normal"  ? '#c4ecc4' : '#fab3a5'
        },
        iconName(){
            if(this.message.isAlarm) return this.message.value == 'Normal' ? "check-circle-fill" : "bell-fill"
            return this.message.value == 'Online' ? "check-circle-fill" : "x-circle-fill"
        },

    },
    methods:{
        close(){
            this.$emit("close")
        },

    },

    created(){
        if(this.alarmDbId != undefined){
            window.addEventListener(`close-toast-${this.alarmDbId}`, ()=> this.$emit("close", true))
        }
    },

    beforeDestroy(){

    }
}
</script>

<style lang="scss">
.Vue-Toastification__toast.bottom-left{
	zoom: 0.8 !important;
}

.Vue-Toastification__toast.Vue-Toastification__toast--success.bottom-left {
	background: #0f400f !important;
	border: 1px solid #158b15 !important;

	.Vue-Toastification__progress-bar {
		background: #158b15 !important;
	}
}

.Vue-Toastification__toast.Vue-Toastification__toast--warning.bottom-left {
	background: #1e1d1c !important;
	border: 1px solid #df3e20 !important;

	.Vue-Toastification__progress-bar {
		background: #df3e20 !important;
	}
}

.Vue-Toastification__toast.Vue-Toastification__toast--error.bottom-left{
	border: 2px solid #df3131 !important;
	background: #410808 !important;

	.Vue-Toastification__progress-bar {
		background: #df3131 !important;
	}
}
    .Vue-Toastification__toast{
        overflow: visible !important;
        user-select: none;
    }
    .Vue-Toastification__container.bottom-left{
        .bottom-left{
            .toastContainer{
                position: relative;
                .closeToastIcon{
                    opacity: 0;
                    position: absolute;
                    right: -0.75rem;
                    top: -0.75rem;
                    transition: 0.5s opacity ease;
                    cursor:pointer;
                }
            }
            &:hover{
                .toastContainer{
                    .closeToastIcon{
                        opacity: 1;
                        transition: 0.5s opacity ease;
                    }
                }
            }
        }
    }
    .toastHeader{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        .iconAlign{
            display:flex;
            align-items:center;
            gap: 0.5rem;
            h5{
                font-size: 1rem;
                font-weight: 700;
                strong{
                    text-decoration: underline;
                    color: v-bind(descriptionColor)
                }
            }
        }
    }
</style>