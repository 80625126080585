<template>
    <b-form-group :input-name="name" class="form w-100" :class="{'disabled': disabled}" :invalid-feedback="invalidMessage" :state="validate ? state : null"
        :required="required && !disabled">
        <div class="d-flex">
            <span class="infoIcon mr-1" v-b-tooltip.top tabindex="0" :title="infoText" v-if="infoText.length > 0">
                <b-icon-info-circle-fill/>
            </span>
            <label v-if="label.length" id="label" :for="inputId" class="d-flex align-items-center">
                <b-icon v-if="icon.length" :icon="icon" class="ml-1 mr-1"/>{{ label }} <span class="required" v-if="required && !disabled" title="required">*</span>
            </label>
        </div>
        <b-input-group size="lg" class="d-flex flex-column">

            <b-form-file
                class="w-100"
                v-model="file"
                :state="Boolean(file)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                ></b-form-file>
                <div class="mt-3">Selected file: {{ file ? file.name : '' }}</div>
                <img id="preview"  alt="Imagem Preview" style="max-width: 100%; max-height: 200px">
        </b-input-group>
        
                 


    </b-form-group>
</template>

<script>

export default {
    data() {
        return {
            file: null,
        }
    },
    props: {
        label: {
            type: String,
            required: false,
            default: ''
        },
        name: {
            type: String,
            required: true
        },
        invalidMessage: {
            type: String,
            required: false,
            default: ''
        },
        validate: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        isValid: {
            type: Boolean,
            required: false,
            default: null
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        value: {
            type: undefined,
            required: false,
            default(props) {
                if (props == undefined) return 0
                else return props
            }
        },

        infoText:{
            type:String,
            required:false,
            default: ''
        },
        inputClass:{
            required: false,
            default: '',
            type:String
        },
        icon:{
            type:String,
            required:false,
            default: ''
        }
    },
    computed: {
        state() {
            return this.isValid
        },
        inputId() {
            return this.name + "Input"
        },
        disabledColor() {
            return { "--background-color": "#32323233" }
        },

    },

    mounted() {
        setTimeout(() => {
            this.loaded = true;
            document.getElementById('preview').src = `data:image/png;base64,${this.value}`;
        }, 1000)
    },
    watch: {
        state() {
            this.$el.classList.remove("animate__headShake")
            if (!this.state) {
                setTimeout(() => {
                    this.$el.classList.add("animate__headShake")
                }, 100)
            }
        },

        file() {
            console.log(this.file);
            var preview = document.getElementById('preview');
            if (this.file) {
                var reader = new FileReader();

                reader.onload = e => {

                    var arrayBuffer = e.target.result;
                    var binary = '';
                    var bytes = new Uint8Array( arrayBuffer );
                    var len = bytes.byteLength;
                    for (var i = 0; i < len; i++) {
                        binary += String.fromCharCode( bytes[ i ] );
                    }
                    var base64String = btoa(binary);
                    preview.src = `data:image/png;base64,${base64String}`;
                    this.$emit("changeValue", base64String)
                };

                reader.readAsArrayBuffer(this.file);
            }


       
        }
    }
}
</script>

<style scoped lang="scss">

.disabled{
    opacity: 0.25;
    & > {
        cursor: not-allowed !important;
    }
}
.infoIcon{
    color: #ff990097
}
.form {
    min-height: 0px;
    max-width: 90vw;
}

.text-input{
    text-overflow: ellipsis
}

.form>input {
    height: 3rem;
    background-color: var(--background-color);
    padding: 1rem;
    border: 0.1px solid rgb(217 217 217 / 89%);
    color: white;
    border-radius: 8px;
    width: 100%;
    text-overflow: ellipsis;
}

input.form-control {
    background: #ffffff0d;
    color: white;
    padding: 0 0.25rem !important;
    box-sizing: border-box;
    margin: 0 !important;
    text-align: center;
}

input:focus {
    background-color: var(--background-color);
    color: white;

}

input.hasAppend {
    text-align: end;
}

label {
    text-align: left;
    color: white;
    opacity: 0.75;
}

.input {
    height: 3rem;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 1rem;
    border: 0.1px solid rgba(255, 255, 255, 0.3);
    color: white;
    border-radius: 8px;
}

.required {
    color: rgba(255, 96, 96);
}

.input-group-text {
    border: 1px solid white !important;
}
</style>

<style lang="scss">
.form>div {
    width: 100%;
}

.invalid-feedback,
.is-invalid {
    position: relative !important;
    top: 0px !important;

    .d-block,
    .invalid-feedback {
        font-size: 1rem;
        color: #c43845;
        margin: 0;
        font-style: italic;
        font-weight: bolder;
        background: #b92e2e2b;
        opacity: 1;
        text-align: center;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
</style>