
<template>
    <select v-model="$i18n.locale" @change="changeLanguage">
        <option value="en">{{ $i18n.t('english') }}</option>
        <option value="br">{{ $i18n.t('portuguese') }}</option>
    </select>
</template>

<script>
export default {
    methods: {
        changeLanguage(obj) {
            localStorage.setItem('language', obj.target.value)
        }
    }
}
</script>


<style scoped>
select {
    background: initial;
    color: gray;
    color: rgba(var(--tmHubTheme-light-color), 0.5);
    text-align: center;
    border: 1px solid ;
    padding: 0.33rem 0.5rem !important;
    border-radius: 0.25rem;
    width: 8rem;
}

select>option {
    background: initial;
}
</style>