<template>
    <svg :id="chartId"/>
</template>

<script>
/* eslint-disable */
import * as d3 from "d3";
export default {
    data(){
        return{
            marginLeft: 50,
            marginRight: 20,
            marginTop: 20,
            marginBottom: 50,
        }
    },
    props:{
        datesFilter: {
            type: Array,
            required:false,
            default: []
        },
        chartTitle:{
            type:String,
            required:false,
            default: 'chart'
        },
        chartData:{
            required:false,
            type:Array,
            default(props){
                var resp = props ? props : []
                return resp
            }
        },
        isReport:{
            required: false,
            type: Boolean
        },
        height:{
            type:Number,
            required:false,
            default: 500
        },
        width:{
            type:Number,
            required:false,
            default: 600
        },
        keys:{
            required:false,
            type:Array
        },
        selectArea: {
            required: false,
            type: Object,
            default: () => {return {id: -1}}
        },
        comments: {
            required:false,
            type: Array,
            default: () => []
        },
        missingData: {
            required:false,
            type: Object,
            default: () => ({})
        }
    },

    computed:{
        chartId(){
            return `${this.chartTitle}-multipleLineChart`
        },
        
        dates(){
            return d3.map(this.data, d=>{
                return d.time;
            })
        },

        values(){
            return d3.map(this.data, d=>{
                return d.value
            })
        },

        oValues(){
            return d3.map(this.data, d=> d)
        },

        definedValues(){
            return this.data.map((i)=>{
                return i.value != null
            })
        },

        xDomain(){
            var dates = d3.map(this.datesFilter, d=>{
                let date = new Date(d)
                var gmtMinus3Date = new Date(date.getTime());
                gmtMinus3Date.setUTCHours(gmtMinus3Date.getUTCHours() - 3);
                return gmtMinus3Date;
            })
            return [dates[0], dates[dates.length -1]]
        },

        xRange(){
            return [this.marginLeft, this.width - this.marginRight]
        },

        xScale(){
            return d3.scaleUtc(this.xDomain, this.xRange)
        },

        yDomain(){
            // Filter the data array to only include elements where show = true
            let filteredData = this.data.filter(d => {
                let keyValue = this.getKeyValue(`${d.measurerName} (${d.division})`);
                return keyValue && keyValue.show;
            });

            // Calculate the minimum and maximum values from the filtered array
            let min = d3.min(filteredData, d => typeof d.value === "string" ? +d.value : d.value);
            if(min > 0)
                min = min - (min * 0.1);
            else min = min + (min * 0.1)
            return [min, d3.max(filteredData, d => typeof d.value === "string" ? +d.value : d.value)];
        },

        yRange(){
            return [this.height - this.marginBottom, this.marginTop]
        },

        yScale(){
            return d3.scaleLinear(this.yDomain, this.yRange)
        },

        zDomain(){
            return new d3.InternSet(this.zValues)
        },

        line(){
            return d3.line()
                .defined(i => {
                    return this.definedValues[i]
                })
                .curve(d3.curveLinear)
                .x(i => {
                    return this.xScale(this.dates[i])
                })
                .y(i => {
                    return this.yScale(this.values[i])
                })
            
        },

        index(){
            return d3.range(this.dates.length).filter(i=>this.zDomain.has(this.zValues[i]))
        },

        title(){
            var titles = []
            this.data.forEach(el=>{
                titles.push(`${el.measurerName} (${el.division})`)    
            })
            return titles
        },

        zValues(){
            return this.title
        },

        strokeWidth(){
            return this.isReport ? 1 : 2
        },

        isMeasurerWithAreaSelectActive(){
            return this.selectArea.id != -1
        }
    },

    methods:{

        buildChart(){

            const xAxis = d3.axisBottom(this.xScale).ticks(this.width / 60);
            const yAxis = d3.axisLeft(this.yScale).ticks(this.height  / 60);

            //SVG
            this.svg = d3.select(`#${this.chartId}`)
                        .attr("height", this.height)
                        .attr("width", this.width)
                        .attr("viewBox", [0, 0, this.width, this.height])
                        .attr("style", "max-width: 100%; height: auto; height: intrinsic;")

            
            //Eixos
            this.svg.append("g")
                .attr("transform", `translate(0, ${this.height - this.marginBottom})`)
                .attr("class", "x-axis")
                .call(xAxis)
                
            this.svg.append("g")
                .attr("transform", `translate(${this.marginLeft}, 0)`)
                .attr("class", "y-axis")
                .call(yAxis)
                .call(g=> g.selectAll(".tick line").clone().attr("x2", this.width - this.marginLeft - this.marginRight).attr("stroke-opacity", 0.1))
                .call(g => g.append("text")
                    .attr("x", -this.marginLeft)
                    .attr("y", 10)
                    .attr("fill", "#97a4aa")
                    .attr("text-anchor", "start")
                    .text("Valores"))

            if(this.data.length <= 1){
                this.svg.append("g")
                    .attr("transform", `translate(${this.width * 0.35}, ${this.height * 0.5})`)
                    .append("text")
                        .attr("font-size", '4rem')
                        .attr("fill", '#dc3545')
                        .text(this.$i18n.t('noDataMeasurer'))
            }

            else{
                this.svg.on("pointermove", this.focusInHoverLine)
                        .on("pointerenter", this.pointerentered)
                        .on("pointerleave", this.unfocusInHoverLine)
                        .on("touchstart", event => event.preventDefault());
                

                //oculta as linhas que estão fora do gráfico, isso é util para o zoom in/out
                this.svg.append("defs").append("clipPath")
                    .attr("id", `${this.chartId}-clip`)
                    .append("rect")
                    .attr("x", this.marginLeft)
                    .attr("width", this.width - this.marginRight)
                    .attr("height", this.height);


                /**
                 * Criação das linhas
                 * Missing Data Path: Apresenta todos os dados, remove os valores nulos, assim o D3js irá conectar os pontos anteriores e posteriores a ele 
                 * Path: Apresenta apenas os dados com valores não nulos, quando o valor for nulo fica um buraco até o proximo ponto não nulo
                 * 
                 * Path é criada depois pois ele precisa ficar por cima do Missing Data Path
                 * 
                 */
                this.missingDataPath = this.createPath({stroke:1,dash: 6, class:"missing"})
                            .attr('class', 'line')
                            .attr("stroke", this.getPathStrokeColor)
                            .attr("d", this.getPathMissingData)
                            .style("display", ([z]) => {
                                var show = this.getKeyValue(z).show
                                return show ? "initial" : "none"
                            })
                
                
                this.path = this.createPath()
                    .attr('class', 'line')
                            .attr("stroke", this.getPathStrokeColor)
                            .attr("stroke-width", this.strokeWidth)
                            .attr("d", this.getPathData)
                            .style("display", ([z]) => {
                                var show = this.getKeyValue(z).show
                                return show ? "initial" : "none"
                            })
                
                // Tooltip que aparece mostrando o valor quando passa o mouse por cima da linha
                this.dot = this.svg.append("g").attr("display", "none")
    
                this.dot.append("circle")
                    .attr("r", 2.5)
                    .attr("fill", "#3cd2a5");
    
                this.dot.append("text")
                    .attr("font-family", "sans-serif")
                    .attr("font-size", 10)
                    .attr("stroke", "#3cd2a5")
                    .attr("text-anchor", "middle")
                    .attr("y", -8);

                    
                    
                if(this.isMeasurerWithAreaSelectActive){
                    this.svg
                        .call( d3.brush()                 
                            .extent( [ [0,0], [this.width, this.height] ] ) 
                            .on("end", this.brushEnd) 
                        )
                    
                    this.renderComments()
                }else if(this.isReport) this.renderComments()
                    
                

                this.svg.call(this.zoom);
            }
        },

        // Preset para as linhas que serão criadas, tanto a Missing Data Path quanto a Path
        createPath(config={stroke:2, dash: 0, class: ""}) {
            return this.svg.append("g")
                            .attr("class", `path ${config.class}`)
                            .attr("fill", "none")
                            .attr("stroke", null)
                            .attr("stroke-linejoin", "round")
                            .attr("stroke-width", config.stroke)
                            .attr("stroke-dasharray", config.dash)
                            .attr("clip-path", `url(#${this.chartId}-clip)`)
                            .attr("stroke-opacity", 1)
                        .selectAll("path")
                        .data(d3.group(this.index, i => this.zValues[i]))
                        .join("path") 
        },

        /**
         * getPathMissingData e getPathData usa o this.line que tem a propriedade defined
         * Quando um valor em defined é falso, ele não desenha um ponto, então ele só vai conectar quando o proximo valor for nao nulo
         * Ex: [2, 3, NaN, NaN, NaN, 5] => Conecta os pontos 2->3->5   
         */

        // Remove os dados nulos, assim ele conectará os pontos entre eles
        getPathMissingData([, I]){
            return this.line([...I].filter(i => this.definedValues[i]))
        },

        // Não remove os dados nulos, assim ele cria um buraco entre os pontos nulos
        getPathData([, I]){
            return this.line(I)
        },

        /**
         * Cada medidor tem uma configuração própria no gráfico, sendo a chave o seu nome
         * Nele voce pode definir se sua linha será, ou nao exibida, e a cor da linha
         */
        getKeyValue(key){

            key = key || ""

            let keyWithoutVariable = key.slice(0, key.indexOf(" ("))
            for (let i = 0; i < this.keys.length; i++) {
                if (keyWithoutVariable in this.keys[i]) {
                    return this.keys[i][keyWithoutVariable];
                }
            }
            return null; // Retorna null se a chave não for encontrada
        },

        getPathStrokeColor(info){
            try{
                return this.getKeyValue(info[0]).color
            }catch(e){
                console.error(e)
                return 'white'
            }
        },

        brushEnd(event){
            const {selection} = event

            if(selection != null){
                const [[x0, y0], [x1, y1]] = selection
                const x = [this.xScale.invert(x0), this.xScale.invert(x1)]
                const y = [this.yScale.invert(y1), this.yScale.invert(y0)]
                this.$emit("updateSelection", {x,y})
            }
            else this.$emit("updateSelection")


        },

        renderComments() {
            const measurerComments = this.comments;

            const rectangles = this.svg.selectAll(".selection-rect")
                .data(measurerComments, d => d.id);

            rectangles.exit().remove();

            rectangles.enter().append("rect")
                .attr("class", "selection-rect")
                .attr("cursor", "pointer")
                .attr("x", d => this.xScale(d.selection.x[0]))
                .attr("y", d => this.yScale(d.selection.y[1]))
                .attr("width", d => this.xScale(d.selection.x[1]) - this.xScale(d.selection.x[0]))
                .attr("height", d => this.yScale(d.selection.y[0]) - this.yScale(d.selection.y[1]))
                .attr("fill", d => this.isReport ? "rgb(126 210 241)" : d.color)
                .attr("stroke", d => this.isReport ? "rgb(126 210 241)" : d.color)
                .attr("stroke-width", 2)
                .attr("stroke-dasharray", this.isReport ? 0 : 2)
                .attr("fill-opacity", 0.2)
                .attr("rx", 5)
                .attr("ry", 5)
                .attr("pointer-events", "all")
                .on("click", (event, d) => {
                    this.$emit("openComment", d);
                });
            
            if(this.isReport) this.renderCommentIndexes()
        },

        renderCommentIndexes(){
            const measurerComments = this.comments;

            const circles = this.svg.selectAll(".selection-circle")
                .data(measurerComments, d => d.id);

            circles.exit().remove();

            circles.enter().append("circle")
                .attr("class", "selection-circle")
                .attr("cx", d => this.xScale(d.selection.x[0]) + (this.xScale(d.selection.x[1]) - this.xScale(d.selection.x[0])) / 2)
                .attr("cy", d => this.yScale(d.selection.y[1]) + (this.yScale(d.selection.y[0]) - this.yScale(d.selection.y[1])) / 2)
                .attr("r", d => Math.min(this.xScale(d.selection.x[1]) - this.xScale(d.selection.x[0]), this.yScale(d.selection.y[0]) - this.yScale(d.selection.y[1])) / 4)
                .attr("fill", "rgb(126 210 241)")
                .attr("stroke", "white")
                .attr("stroke-width", 1)
                .attr("pointer-events", "none");

            const texts = this.svg.selectAll(".selection-text")
                .data(measurerComments, d => d.id);

            texts.exit().remove();

            texts.enter().append("text")
                .attr("class", "selection-text")
                .attr("x", d => this.xScale(d.selection.x[0]) + (this.xScale(d.selection.x[1]) - this.xScale(d.selection.x[0])) / 2)
                .attr("y", d => this.yScale(d.selection.y[1]) + (this.yScale(d.selection.y[0]) - this.yScale(d.selection.y[1])) / 2)
                .attr("text-anchor", "middle")
                .attr("dominant-baseline", "central")
                .attr("fill", () => "white")
                .text((d, i) => i + 1); 
        },

        zoom(svg){
            // Limites
            var extent = [
                        [this.marginLeft, this.marginTop], 
                        [this.width - this.marginRight, this.height - this.marginTop]
            ];

            var zooming = d3.zoom()
                .scaleExtent([1, 200])
                .translateExtent(extent)
                .extent(extent)
                .on("zoom", (event) => this.zoomed(event))

            
            svg.call(zooming);
        },

        //Ajusta os paths e a escala do eixo X, mostrando mais/menos datas conforme o zoom in/out
        zoomed(event) {
            this.xScale.range([this.marginLeft, this.width - this.marginRight]
				.map(d => event.transform.applyX(d)));

			this.path.join("path").attr("d", this.getPathData)
			this.missingDataPath.join("path").attr("d", this.getPathMissingData)
			this.svg.select(".x-axis")
				.call(d3.axisBottom(this.xScale).ticks((this.width * parseInt(event.transform.k)) /60).tickSizeOuter(0));
            
            this.svg.selectAll(".selection-rect").remove();
            this.renderComments();
		},

        pointerentered(){
            // Deixa todas as linhas meio apagadas
            this.path.style("stroke", "#29333d"); 
            this.missingDataPath.style("stroke", "#29333d"); 
            this.dot.attr("display", null);
        },

        showTooltip(e){
            const [xm, ym] = d3.pointer(e);
            const i = d3.least(this.index, i => Math.hypot(this.xScale(this.dates[i]) - xm, this.yScale(this.values[i]) - ym)); 

            this.tooltip.style("display", null);
            this.tooltip.attr("transform", `translate(${this.xScale(this.dates[i])},${this.yScale(this.values[i])})`);
            
            const path = this.tooltip.selectAll("path")
            .data([,])
            .join("path")
                .attr("fill", "#29333d")
                .attr("stroke", "#495057");

            const formatDate = this.xScale.tickFormat(null, "%b %-d, %Y");
            const formatValue = this.yScale.tickFormat(100);
            const tooltipTitle = `${formatDate(this.dates[i])}\n${formatValue(this.values[i])}`


            const text = this.tooltip.selectAll("text")
            .data([,])
            .join("text")
            .call(text => text
                .selectAll("tspan")
                    .data(`${tooltipTitle}`.split(/\n/))
                    .join("tspan")
                    .attr("x", 0)
                    .attr("y", (_, i) => `${i * 1.1}em`)
                    .attr("font-weight", (_, i) => i ? null : "bold")
                    .attr("fill", "white")
                    .text(d => d));

            const {x, y, width: w, height: h} = text.node().getBBox();
            console.log(x);
            console.log(y);

            text.attr("transform", `translate(${-w / 2},${15 - y})`);
            path.attr("d", `M${-w / 2 - 10},5H-5l5,-5l5,5H${w / 2 + 10}v${h + 20}h-${w + 20}z`);
        },

        hightlightPath(i=undefined){
            //Deixa a linha em hover branca 
            const hightlight = (path) => {
                path.style("stroke", ([z]) => {
                    var color = this.getPathStrokeColor([z])
                    const keyWithoutVariable = z.slice(0, z.indexOf(" ("))
                    return (this.zValues[i] === z && !this.isMeasurerWithAreaSelectActive)  || this.selectArea.name == keyWithoutVariable ? color : "#29333d"
                }).filter(([z]) => {
                    if(this.selectArea.id != -1){
                        const keyWithoutVariable = z.slice(0, z.indexOf(" ("))
                        return this.selectArea.name == keyWithoutVariable
                    }
                    return this.zValues[i] === z && !this.isMeasurerWithAreaSelectActive
                }).raise();
            }
            hightlight(this.path)
            hightlight(this.missingDataPath)
        },

        focusInHoverLine(event){
            const [xm, ym] = d3.pointer(event); // posicao de onde o mouse está
            // 'Id' do ponto mais proximo do mouse
            const i = d3.least(this.index, i => {return Math.hypot(this.xScale(this.dates[i]) - xm, this.yScale(this.values[i]) - ym)}); 
            this.hightlightPath(i)

            var showDot = this.getKeyValue(this.title[i]).show ? 'initial' : 'none'
            var element = this.chartData.find(item => `${item.measurerName} (${item.name})` == this.title[i])
            var unit = element ? element.unit : ''
            this.dot.attr("display", showDot).select("text").text(`${this.values[i]} ${unit}`)
            this.dot.attr("transform", `translate(${this.xScale(this.dates[i])},${this.yScale(this.values[i])})`);
            this.svg.property("value", this.oValues[i]).dispatch("input", {bubbles: true});
        },

        unfocusInHoverLine(){
            const returnPathToDefaultColor = (path) => {
                path.style("stroke", (z) => {
                    return this.getPathStrokeColor(z)
                })
            }
            if(!this.isMeasurerWithAreaSelectActive){

                returnPathToDefaultColor(this.path)
                returnPathToDefaultColor(this.missingDataPath)
            }

            this.dot.attr("display", "none");
            this.svg.node().value = null;
            this.svg.dispatch("input", {bubbles: true});
        },

        filterData(){

            const dataArray = []
            const limitSizeData = this.chartData
            limitSizeData.forEach((el, index) => {
                let dataObj = {}
                let {name, dateTime, value, measurerName, sn} = el
                dataObj.division = name;
                dataObj.measurerName = measurerName;
                dataObj.sn = sn;
                dataObj.time = new Date(dateTime);
                dataObj.value = value
                dataArray.push(dataObj)
                



            })
            return dataArray

        },

        removeDataFromOfflineIntervals(data){
            const serialNumbers = data.map(item => item.sn).filter((item, index, self) => {
                return self.indexOf(item) === index
            }).filter(item => Object.keys(this.missingData).includes(item))
            const dataPath = [];

            for(let sn of serialNumbers){
                let snItems = data.filter(item => item.sn === sn)
                for (let offlineIntervals of this.missingData[sn]){

                    const {startTime, endTime} = offlineIntervals

                    let converterdValues = snItems.map(item => {
                        let itemDatetime = new Date(item.time)
                        let isOffRange = itemDatetime <= new Date(startTime) || itemDatetime >= new Date(endTime) 
                        if(!isOffRange) item.value = null
                        return item
                    })
                    dataPath.push(...converterdValues)
                }
            }
            

            return dataPath
        }
    },

    watch:{
        keys:{
            deep: true,
            handler(){
                document.querySelector(`#${this.chartId}`).innerHTML = '' // reseta o svg
                this.buildChart()
            }
        },

        selectArea: {
            deep: true,
            handler(){
                document.querySelector(`#${this.chartId}`).innerHTML = '' // reseta o svg
                this.buildChart()

                if(this.selectArea.id != -1) this.hightlightPath()
                else {
                    this.unfocusInHoverLine()
                    this.svg.call(d3.brush().clear)
                }
            }
        
        }
    },


    mounted(){
        this.data = this.filterData()
        this.data.sort((a,b)=>{
            return b.time - a.time
        })
        this.removeDataFromOfflineIntervals(this.data)


        this.buildChart()
    },
}

</script>
