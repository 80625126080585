/* eslint-disable */
import exportFromJSON from 'export-from-json'
export default function exportToCSV(name, fields, element, references){
    var exportDataObj = (obj, fields) => {
        let filteredObject = {};
        fields.forEach(field => {
            if (obj.hasOwnProperty(field)) {
                filteredObject[field] = obj[field];
            }
        });
        return filteredObject;
    }

    var data = exportDataObj(element, fields)
    if(references){
        data = {...data, ...references}
    }

    const fileName = `${name}_${new Date().toISOString()}`
    const exportType =  exportFromJSON.types.csv
    exportFromJSON({ data: [data], fileName, exportType })
} 