<template>
    <div>
        <div class="grid">
            <div class="ml-3 mr-3 mb-3 tableContainer" style="grid-column: 1/2">
                <text-input name="availableFilter" :value="availableFilter" :prepend="$t('filter')" @changeValue="(value) =>availableFilter = value"/>
                <div class="tableHeader text-center p-2">
                    <checkbox 
                    v-if="avaliableItems.length>=1" 
                    class="mb-0 checkboxSelectAll" 
                    label=""
                    name="checkAllAvaliable" 
                    :value="isAllAvailableChecked"
                    :disabled="isAllAvailableChecked"
                    @change="checkAllAvaliable"

                     />
                    {{ $i18n.t("avaliable") }}
                </div>
                <div class="tableBody p-3">
                    <div v-for="item in computedAvaliableItems" :key="item.id" class="">
                        <div class="d-flex align-items-center p-2" style="gap: 0.5rem">
                            <checkbox class="checkbox m-0" label="" :name="'checkbox' + item.id"
                                @change="selectedAdd(item)" :value="isSelectedToAdd(item)"/>
                            <div v-if="item.nickname">{{ item.nickname }}</div>
                            <div v-else-if="item.text">{{ item.text }}</div>
                            <div v-else-if="item.name">{{ item.name }}</div>
                        </div>
                        <hr>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center flex-column align-items-center btnArea" style="gap: 1rem">
                <button type="button" @click="update">
                    <b-icon-arrow-left-right class="arrow" /><br>
                </button>
            </div>
            <div class="ml-3 mr-3 mb-3 tableContainer" style="grid-column: 3/4">
                <text-input name="insertedFilter" :value="insertedFilter" :prepend="$t('filter')" @changeValue="(value) => insertedFilter = value"/>
                <div class="tableHeader text-center p-2">
                    <checkbox 
                    v-if="selectedItems.length>=1" 
                    class="mb-0 checkboxSelectAll" 
                    label=""
                    name="checkAllSelected" 
                    :value="isAllSelectedChecked"
                    :disabled="isAllSelectedChecked"
                    @change="checkAllSelected"
                     />
                    {{ $i18n.t("selected") }}
                </div>
                <div class="tableBody p-3">
                    <div v-for="selected in computedSelectedItems" :key="selected.id">
                        <div class="d-flex align-items-center p-2" style="gap: 0.5rem">
                            <checkbox class="checkbox m-0" label="" :name="'checkbox' + selected.id"
                                @change="selectedRemove(selected)" :value="isSelectedToRemove(selected)"/>
                            <div v-if="selected.nickname">{{ selected.nickname }}</div>
                            <div v-else-if="selected.text">{{ selected.text }}</div>
                            <div v-else-if="selected.name">{{ selected.name }}</div>
                            <time-badge v-if="showTimeInput" :time="selected.time" @change="updateSelectedTime(...arguments, selected.id)"/>
                            <div v-if="showMap" class="pinContainer" @click="openPositionModal(selected)" :class="{'isPositioned': map(selected)}">
                                <b-icon-map-fill/>
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import Checkbox from '../checkbox/Checkbox.vue';
import TimeBadge from '../misc/TimeBadge.vue';
export default {
    components: { Checkbox, TimeBadge },
    data() {
        return {
            addItems: [],
            removeItems: [],
            selectedItems: [],
            avaliableItems: [],
            availableFilter: '',
            insertedFilter: '',
        }
    },
    props: {
        id: {
            type: [Number, String],
            required: false,
            default: -1,
        },
        name: {
            type: String,
            required: false,
            default: "element"
        },
        items: {
            type: Array,
            required: true
        },
        insertedItems: {
            type: Array,
            required: true
        },
        showTimeInput:{
            type:Boolean,
            required:false,
            default: false
        },
        map:{
            type:Function | undefined,
            required:false,
            default: undefined
        }
    },

    computed: {

        showMap(){
            return this.map != undefined
        },
        computedSelectedItems() {
            return this.selectedItems.filter(el => {
                return el.name.includes(this.insertedFilter)
            });
        },

        computedAvaliableItems() {
            return this.avaliableItems.filter(el => {
                return el.name.includes(this.availableFilter)
            });
        },

        /* id que serve para referenciar o collapse do dispositivo e não influenciar em outros collapses */
        collapseId() {
            return this.id != -1 ? `CollapseVariables-${this.id}` : 'relation'
        },

        isAllAvailableChecked() {
            return this.computedAvaliableItems.length == this.addItems.length
        },

        isAllSelectedChecked() {
            return this.computedSelectedItems.length == this.removeItems.length
        }
    },


    methods: {
        isPositioned(item){
            console.warn(item)
            return item.geoPlan.length > 0
        },

        selectedAdd(item) {
            if (!this.isSelectedToAdd(item)) this.addItems.push(item)
            else this.addItems = this.addItems.filter(i => { if (item != i) return i })
        },

        isSelectedToAdd(item) {
            return this.addItems.includes(item)
        },

        checkAllAvaliable(){
            this.addItems = this.computedAvaliableItems
        },

        isSelectedToRemove(item) {
            return this.removeItems.includes(item)
        },

        selectedRemove(item) {
            if (!this.isSelectedToRemove(item)) this.removeItems.push(item)
            else this.removeItems = this.removeItems.filter(i => { if (item != i) return i })
        },

        checkAllSelected(){
            this.removeItems  = this.computedSelectedItems
        },

        updateSelectedTime(time, id){
            this.$emit("changeTime", {time, id})
        },

        update() {

            this.addItems.forEach(el => {
                this.selectedItems.push(el);
                this.removeFromAvaliableList(el);
            })

            this.removeItems.forEach(el => {
                this.avaliableItems.push(el);
                this.removeFromSelectedList(el);
            })

            this.addItems = [];
            this.removeItems = [];

            this.$emit("selectedItems", this.selectedItems)
        },

        openPositionModal(measurer){
            this.$emit("openPositionModal", measurer)
        },

        removeFromAvaliableList(item) {
            this.avaliableItems = this.avaliableItems.filter(i => {
                if (item != i) {
                    return i
                }
            })
        },

        removeFromSelectedList(item) {
            this.selectedItems = this.selectedItems.filter(i => {
                if (item != i) {
                    return i
                }
            })
        }
    },

    created() {
        setTimeout(() => {
            this.avaliableItems = this.items;
            this.selectedItems = this.insertedItems;
        }, 1000)
    }
}
</script>

<style scoped lang="scss">
.tableToggle {
    text-align: start;
    cursor: pointer;

    h4 {
        //background-color: rgb(80 99 99);
        height: 3px;
        vertical-align: middle;
        line-height: 1px;

        span {
            padding: 10px;
            background-color: #1f2c38;

            svg {
                font-size: 0.8rem;
            }
        }
    }
}

.btnArea {
    grid-column: 2/3;
}

.grid {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 45% 10% 45%;
    @media (min-width: 320px) and (max-width: 991px){
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .tableContainer{
            margin: 0;
            width: 90%;
        }
        .btnArea{
            gap: 1rem;
            height: 4rem;
            width: 4rem;
            button > svg{
                rotate: 90deg;
            }
        }
    }
}

.tableContainer:hover{
    transition: background-color 0.5s ease;
    .tableHeader{
        background-color: #262940;
    }
    .tableBody{
        background-color: #444866;
    }
}

.tableHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 4rem;
    -webkit-box-shadow: inset 0px 0px 0px 1px white;
    -moz-box-shadow: inset 0px 0px 0px 1px white;
    box-shadow: inset 0px 0px 0px 1px white;
    background:
        linear-gradient(to right, white 4px, transparent 4px) 0 0,
        linear-gradient(to right, white 4px, transparent 4px) 0 100%,
        linear-gradient(to left, white 4px, transparent 4px) 100% 0,
        linear-gradient(to left, white 4px, transparent 4px) 100% 100%,
        linear-gradient(to bottom, white 4px, transparent 4px) 0 0,
        linear-gradient(to bottom, white 4px, transparent 4px) 100% 0,
        linear-gradient(to top, white 4px, transparent 4px) 0 100%,
        linear-gradient(to top, white 4px, transparent 4px) 100% 100%;

    background-repeat: no-repeat;
    background-size: 20px 20px;
    padding: 1rem;
    background-color: hsl(206deg 37% 14% / 91%);
    background-color: rgba(var(--tmHubTheme-darken-primary-color), 0.91);
    position: relative;

    .checkboxSelectAll{
        position: absolute;
        left: 1.5rem;
    }

}

.tableBody {
    max-height: 25rem;
    height: auto;
    overflow-y: auto;
    border: 1px solid white;
    background-color: hsla(206deg, 29%, 17%, 0.66);
    background-color: rgba(var(--tmHubTheme-primary-color), 0.66);
    border-top: none;
}
.pinContainer{
    padding: 0.25rem;
    border-radius: 1rem;
    font-size: 0.5rem;
    cursor: pointer;
    background: #af4c4c6e;
    border: 1px solid #af4c4c;
    &.isPositioned{
        background: #4caf506e;
        border: 1px solid #4CAF50;
    }
}
button {
    width: 100%;
    background: transparent;
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.7);
    border-radius: 7px;
    outline: none;
    overflow: hidden;
    box-sizing: border-box;
    transition: color 0.5s ease, border 0.5s ease;

    &:hover {
        transition: color 0.5s ease, border 0.5s ease;
        border-color: white;
        color: white;

        .arrow {
            transition: color 0.75s ease;
            color: rgba(245, 245, 245, 0.3);
        }
    }

    &:active {
        border-color: #f8d7a2;

        .arrow {

            color: #e08f0e8e;
        }

        // color: #ebb35a
    }
}

.arrow {
    transition: color 0.75s ease;
    z-index: -2;
    font-size: 2rem;
    color: rgba(245, 245, 245, 0.438);
}

span {
    position: relative;
    top: -15px
}

.checkbox {
    top: -5px;
    position: relative;
}
</style>