<template>
    <b-popover :target="target" triggers="hover focus" custom-class="popoverAction">
        <template #title>{{$i18n.t("actions")}}</template>
        <div class="actionList">
            <div class="actionItem" v-for="group in groupByAlarmId" :key="group.alarmId">
                <h5>{{label(group)}}</h5>
            </div>
        </div>
    </b-popover>
</template>

<script>
export default {
    props:{
        target: {
            type:String,
            required:true
        },
        actionList:{
            type: Array,
            required:true
        },
        sending:{
            type:Boolean,
            required:false,
            default:false
        }
    },
    computed: {
        groupByAlarmId() {
            const groupedData = {};
            
            this.actionList.forEach(item => {
                const { alarmId, alarmName, id, name } = item;
                
                if (!groupedData[alarmId]) {
                groupedData[alarmId] = {
                    alarmId,
                    alarmName,
                    actions: []
                };
                }
                
                groupedData[alarmId].actions.push({ id, name });
            });
            
            return Object.values(groupedData);
        }
    },
    methods:{
        label(group){
            const {actions, alarmName} = group
            if(actions.length == 1){
                var action = actions[0]
                if(this.sending){
                    return `Quando ativo, o alarme "${alarmName}" acionará a ação "${action.name}"`
                }else{
                    return `A ação "${action.name}" vai ser acionada quando o Alarme "${alarmName}" estiver ativo`
                }
            }
            var actionsText = ""
            actions.forEach((action, index) => {
                if(index == actions.length - 1) actionsText += ` e "${action.name}"`
                else if(index) actionsText += `, "${action.name}"`
                else actionsText = `"${action.name}"`
            })
            if(this.sending){
                return `Quando ativo, o alarme "${alarmName}" acionará as ações ${actionsText}.`
            }else{
                return `As ações ${actionsText} serão acionadas quando o Alarme "${alarmName}" estiver ativo`
            }
        }
    },
}
</script>

<style scoped lang="scss">
    div[role="tooltip"].popoverAction{
        background-color: rgba(0, 0, 0, 0.822);
        border: 1px solid white; 
        .popover-body{
            padding: 0 !important;
        }
        .actionItem{
            padding: 0.5rem;
            h5{
                color: white;
                font-size: 0.95rem;
                font-weight: 400;
                text-align: justify;
                .name{
                    color: #3cd2a5;
                    font-weight: 600
                }
                .condition{
                    color: rgb(235, 71, 71);
                    font-weight: 600
                }
                strong{
                    font-weight: 700
                }
            }
            border-bottom: 1px solid rgb(44, 44, 44);
        }

    }
    div[role="tooltip"].popoverAction > .popover-body{
        padding: 0 !important;
    }

</style>