import Vue from "vue";
import DashboardPanel from "./UtilsTelemetry/components/dashboard/DashboardPanel/index.vue";
import MobileHome from "./pages/Mobile.vue";
import Presentation from "./pages/Presentation.vue";
import User from "./pages/User.vue";


const mobilePrivilege = ["Dashboard", "Map", "BlueprintMap"];

const mtwPrivilege = [
  "BlueprintMap",
  "Servers",
  "Dashboard",
  "Group",
  "Display",
  "ColorConfig",
  "Map",
  "Device",
  "History",
  "Variable",
  "Schedule",
  "Measurer",
  "Alarm",
  "AlarmConfiguration",
  "VideoServerConfig",
  "VideoConfig",
  "Blueprint",
  "Action",
  "Recipient",
  "User",
  "SerialNumber",
  "RecipientGroup",
  "Presentation",
  "Profile",
  "Audit",
  "Layer",
  "MapLayers",
  "BlueprintLayers",
  "HistoryEvents",
  "Mosaic",
  "Network",
  "Report",
  "Aggregation",
  "Tree",
  "ReportSchedule"
];

const adminPrivilege = [
  "BlueprintMap",
  "Dashboard",
  "User",
  "Group",
  "Display",
  "ColorConfig",
  "Map",
  "Device",
  "History",
  "Variable",
  "Schedule",
  "Measurer",
  "Alarm",
  "AlarmConfiguration",
  "VideoServerConfig",
  "VideoConfig",
  "Blueprint",
  "Action",
  "Recipient",
  "Presentation",
  "RecipientGroup",
  "Presentation",
  "Profile",
  "Layer",
  "MapLayers",
  "BlueprintLayers",
  "HistoryEvents",
  "Mosaic",
  "Network",
  "Report",
  "Aggregation",
  "Tree",
  "ReportSchedule"
];

const operatorPrivilege = [
  "BlueprintMap",
  "Dashboard",
  "Group",
  "Display",
  "ColorConfig",
  "Map",
  "Device",
  "Blueprint",
  "History",
  "Measurer",
  "AlarmConfiguration",
  "Presentation",
  "RecipientGroup",
  "Profile",
  "Layer",
  "MapLayers",
  "BlueprintLayers",
  "HistoryEvents",
  "Report",
  "ReportSchedule"
];

function addRoute(router, route) {
  router.addRoute(route);
  router.options.routes.push(route);
}

function addBaseRoute(router, isMobile, privilege) {
  var homepage;
  if (isMobile) homepage = MobileHome;
  else if (privilege == "2") homepage = Presentation;
  else homepage = User;

  let route = {
    path: `/`,
    name: "home",
    component: homepage,
    title: "home",
    menu: false,
    props: true,
  };

  addRoute(router, route);
}

function addRouteWithParameters(router) {
  let mapRoute = {
    path: `/blueprint/map/:blueprintId?`,
    name: `BlueprintMap`,
    component: () => import(`./pages/BlueprintMap.vue`),
    title: `BlueprintMap`,
    menu: false,
  };

  let layerRoute = {
    path: `/Map/:layerId?`,
    name: `Map`,
    component: () => import(`./pages/Map.vue`),
    title: `Map`,
    menu: false,
  };

  let dashboardRoute = {
    path: `/Dashboard/:id?`,
    name: "Dashboard",
    component: DashboardPanel,
    title: "dashboardPanel",
    menu: true,
    props: true,
  };

  addRoute(router, mapRoute);
  addRoute(router, layerRoute);
  addRoute(router, dashboardRoute);
}

function addStaticRoute(name, router) {


  let route = {
    path: `/${name}`,
    name: `${name}`,
    component: () => import(`./pages/${name}.vue`),
    title: `${name}`,
    menu: false,
  };

  addRoute(router, route);
}

export function loadRoutes(router) {
  let privilege = Vue.prototype.$session.get("privilege");
  let isMobile = Vue.prototype.$session.get("isMobile");

  router.options.routes = [];

  if (isMobile) mobilePrivilege.forEach((el) => addStaticRoute(el, router));
  else if (privilege == "0")
    mtwPrivilege.forEach((el) => addStaticRoute(el, router));
  else if (privilege == "1")
    adminPrivilege.forEach((el) => addStaticRoute(el, router));
  else if (privilege == "2")
    operatorPrivilege.forEach((el) => addStaticRoute(el, router));

  addBaseRoute(router, isMobile, privilege);
  addRouteWithParameters(router);

  if (privilege == "0") return;
  var route = {
    path: `/presentation/:begin`,
    name: "presentation",
    params: { begin: "disable" },
    component: Presentation,
    title: "presentation",
    menu: true,
    props: true,
  };

  router.addRoute(route);
  router.options.routes.push(route);
}
