al<template>
	<div :style="[responsiveSizing, barColor, barHeight]" ref="container" class="h-100 d-flex justify-content-center align-items-center container">
		<div class="d-flex flex-column justify-content-between infoContainer">
			<p class="text-success">{{max}}</p>
			<p class="text-danger" style="position:relative; top:20px">{{min}}</p>
		</div>
		<div class="verticalContainer">
			<div class="verticalFill" ></div>
		</div>
		<div class="d-flex flex-column justify-content-center infoContainer">
			<div class="w-100">
				<h2>{{value}}</h2>
				<h6>{{unit}}</h6>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				valuesArray: [],
				ratio: 1,
				cardWidth: 100,
				barColorStatus: "red",
				refreshBarHeight: 0
			}
		},
		
		props:{
			value: {
				type: Number,
				required: false,
				default: 80,
			},
			info:{
				type: Object,
				required: true
			},
			fullscreen:{
				required:false,
				type:Boolean,
				default:false,
			},
			divisionsColorList:{
				type: Array,
				required:true
			}
		},

		computed:{
			responsiveSizing(){
		
				
				var barWidth = this.cardWidth * 0.12
				var labelsSize = this.cardWidth * 0.09
				var valueSize = this.cardWidth * 0.10
				if(this.ratio < 0.34){
					labelsSize = labelsSize /2.5
					barWidth = barWidth /2.5
					valueSize = valueSize /2.5
				}

				var verticalContainerHeight = this.fullscreen ? `${window.innerHeight / 100 * 70}px` : "80%"
				
				barWidth = this.fullscreen ? `${window.innerWidth / 100 * 10}` : barWidth
				labelsSize = this.fullscreen ? `${window.innerWidth / 100 * 2.5}` : labelsSize	
				valueSize = this.fullscreen ? `${window.innerWidth / 100 * 5}` : valueSize
				
				return {
					"--verticalContainerHeight": verticalContainerHeight,
					"--barWidth" : `${barWidth}px`,
					"--labelsSize" : `${labelsSize}px`,
					"--valueSize" : `${valueSize}px`,
				}
			},

			getPercentage() {
				// Garante que o valor esteja dentro do intervalo [minimo, maximo]
				let valor = Math.max(this.min, Math.min(this.value, this.max));

				// Calcula a porcentagem com base no intervalo [minimo, maximo]
				const intervalo = this.max - this.min;
				const porcentagem = ((valor - this.min) / intervalo) * 100;

				return porcentagem;
			},

			barHeight(){
				// Utiliza essa variável para forçar a atualização da altura da barra 
				this.refreshBarHeight;

				var height = undefined
				const percentage = 100 - this.getPercentage
				try{
					let totalHeight = this.$el.querySelector('.verticalContainer').offsetHeight
					height = percentage*totalHeight/100
					return{"--barHeight" : `${height}px`}
				}catch{
					return{"--barHeight" : `${percentage}%`}
				}

			},
			
			unit(){
				return this.info.unit ? this.info.unit : 'unidade'
			},
			min(){
				return this.info.minimum != undefined ? this.info.minimum : 0
			},
			max(){
				return this.info.maximum  != undefined ? this.info.maximum : 100
			},
			barColor(){
				return {"--barColor": this.divisionsColorList[0].color}
			},
		},
		methods:{
			resizeMeasurer(w, h){
				this.cardWidth = w
				this.ratio = h/w

				// Utiliza essa variável para forçar a atualização do computed da altura da barra 
				this.refreshBarHeight++;
			}
		},
		mounted(){
			this.cardWidth = this.$refs.container.offsetWidth * 0.5
		}
	}
</script>

<style scoped>
	.grid{
		display: grid;
		grid-template-columns: 70% 30%;
	}
	.container{
		gap: 1rem
	}
	.verticalContainer{
		min-height: 10rem;
		height: 100%;
		width: 20%;
		background: var(--barColor);
		/* border-radius: 12px; */

	}
	.verticalFill{
		height: var(--barHeight);
		max-height: 100%;
		width: 100%;
		background: gray;
		transition: height 0.5s ease;
		/* background: red; */
	}
	.infoContainer{
		/* height: var(--verticalContainerHeight); */
		height: 100%;
		min-height:10rem;
	}
	h2{
		font-size: max(1em, var(--valueSize)) !important;
	}
	h6{
		font-size: max(1em, var(--labelSize)) !important;
	}
	p{
		font-size: max(1em, var(--labelSize)) !important;
		opacity: 1;
	}
</style>