export default {

    data() {
        return {
            filter: '',
            selected: [],
        }
    },

    mounted() {
        console.log(this.selectedSource)
        this.selectedSource.forEach((el) => {
            let sourceElement = this.source.find(sourceEl => sourceEl.id == el.id)
            if(sourceElement)
                this.selected.push(sourceElement);
        })
    },

    props: {
        source: {
            type: Array,
            default: () => [],
            required: true,
        },

        selectedSource: {
            type: Array,
            default: () => [],
            required: false,
        },

        rules: {
            type: Object,
            default: () => {},
            required: false,
        },

        type : {
            type: String,
            default: 'user',
            required: false,
        },

        enableAdd : {
            type : Boolean,
            default: false,
            required: false,
        }
    },

    methods: {
        toggleNew(){
            this.$bvModal.show('infoModal')
        },

        addElement(value) {
            this.$emit('addElement', value)
            this.$bvModal.hide('infoModal')
        },
    },


    computed: {
        computedItems() {
            return this.source.filter(el => {
                return el.name.includes(this.filter)
            });
        },

        isAllChecked() {
            return this.computedItems.length == this.selected.length
        },

        isAddEnabled() {
            console.log(this.enableAdd)
            return this.enableAdd;
        }
    },

    watch: {
        selected: {
            deep: true, // Required to detect changes within the array
            handler(newVal) {
                console.log(newVal);
                this.$emit('itemsChanged', newVal); // Emit the updated array
            },
        },
    },
}
