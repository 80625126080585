<template>
    <b-modal :id="id" class="modal" fullscreen :nopadding="nopadding" title="Modal with Popover" ok-only :size="size"
        centered ref="modal" style="display: flex !important" modal-class="fullscreenModal" @show="$emit('show')" @shown="$emit('shown')" @hide="$emit('hide')">
        <template #modal-title>
            <slot name="header"></slot>
        </template>
        <slot name="body"></slot>
        <template #modal-footer>
            <slot name="footer"></slot>
        </template>
    </b-modal>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            required: true,
        },
        size: {
            required: false,
            default: "xl",
            type: String
        },
        nopadding: {
            required:false,
            default:false,
            type: Boolean
        },
    },


}
</script>

<style lang="scss">
div.fullscreenModal {
    background-color: rgb(29, 40, 53, 0.95);
    background-color: rgb(var(--tmHubTheme-primary-color), 0.95);
    display: flex !important;
    justify-content: center;
    align-items: center;
}

div[fullscreen] {
    .modal-content {
        height: max-content !important;
        max-height: 90vh !important;
        width: 90vw !important;
        box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    }
    .modal-xl{
        @media (min-width: 1200px){
            max-width: max-content;
        }
    }
    .modal-header{
        background-color: #121c26;
        background-color: rgb(var(--tmHubTheme-darken-primary-color));
    }

    .modal-body {
        background-color: #1c2734;
        background-color: rgb(var(--tmHubTheme-lighten-primary-color));
        height: max-content !important;
        max-height: 80vh;
        overflow: auto;
    }

    .modal-footer {
        padding: 0;
        background-color: #121c26;
        background-color: rgb(var(--tmHubTheme-darken-primary-color));
        .btnGroup {
            gap: 0.5rem;
            margin: 0 !important;
            justify-content: flex-end;

            button {
                margin: 0 !important;
            }
        }
    }
}

div[nopadding]{
    .modal-body {
        padding: 0 !important;
    }
}

div.modal-content {
    color: white;
    background: transparent;
    border-color: rgba(255, 255, 255, 0.3);
}

button.close {
    color: white;
}

button.close:hover {
    color: red
}

div.modal-content>* {
    border-color: rgba(255, 255, 255, 0.3);
    color: white;
}

.modal-dialog.modal-xl.modal-dialog-centered {
    margin: 0;
}</style>