<template>
    <b-modal id="modalConfirmDelete" modal-class="modalDeleteConfirmation" hide-backdrop>
        <template #modal-title>
            <h3>{{ $i18n.t('disable') + " " + $i18n.t(tableName) }}</h3>
        </template>
        <p>
            {{
                $i18n.t("confirmDisable", {
                    title: $i18n.t(tableName),
                    name: name
                })
            }}
        </p>

        <template #modal-footer>
            <button @click="confirm" :en="isLabelInEnglish">
                <b-icon-trash-fill/>
                <strong>{{$i18n.t('disable')}}</strong>
            </button>
        </template>
    </b-modal>
</template>

<script>
export default {

    props:{
        tableName:{
            required:true,
            type:String
        },
        name: {
            required: true,
            type: String
        },
    },

    methods:{
        confirm(){
            this.$emit('confirm')
        }
    },
    computed:{
        isLabelInEnglish(){
            var label = this.$i18n.t('disable')
            return label == "Disable"
        }
    }

}
</script>

<style lang="scss">
    .modalDeleteConfirmation{
        .modal-content{
            border-radius: 1rem;
            width: max-content;
            box-shadow: rgb(157 35 52 / 25%) 0px 54px 55px, rgb(118 53 62 / 12%) 0px -12px 30px, rgb(189 19 42 / 12%) 0px 4px 6px, rgb(189 19 42 / 17%) 0px 12px 13px, rgb(189 19 42 / 9%) 0px -3px 5px;
            &, &>*{
                border-color: rgb(186 49 69)
            }
            .modal-header{
                background-color: #41181e;
                color: white;
                border-radius: 1rem 1rem 0 0;
            }
            .modal-body{
                background-color: #341c27;
                color: white;
                p{opacity: 1;}
            }
            .modal-footer{
                background-color: #41181e;
                border-radius: 0 0 1rem 1rem;
                padding: 0.25rem 0.5rem;
                button{
                    height: 2rem;
                    width: 2rem;
                    display: flex;
                    overflow: hidden;
                    align-items: center;
                    border-radius: 1rem;
                    border: 1px solid red;
                    color: red;
                    background-color: #2c1f25;
                    transition: width 0.25s;
                    svg{
                        margin: 0 0.45rem
                    }
                    &:hover{
                        width: 8rem; 
                        &[en="true"]{
                            width: 6rem;
                        }
                    }
                }
            }
        }
    }
</style>