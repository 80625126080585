<template>
  <div>
    <b-navbar toogleable="lg" type="dark" variant="dark" class="p-2" :class="{'mobileMenuOpen': isMobileMenuOpen}">
      <b-navbar-nav small>
        <b-button id="btn-sidenav" v-if="!isMobile" v-b-toggle.sidebar-variant squared variant="transparent" class="p-1">
          <b-icon-list font-scale="1.5" variant="white"></b-icon-list>
        </b-button>
        <button class="mobileMenuBtn" type="button" v-else @click="toggleMobileMenu">
          <b-icon :icon="isMobileMenuOpen ? 'x': 'list'" font-scale="1.5" variant="white"/>
        </button>

        <b-nav-item to="/" exact exact-active-class="active">
          <slot name="logo" />
        </b-nav-item>

      </b-navbar-nav>

      <b-navbar-nav class="headNavItems ml-auto d-flex align-items-center" style="gap: 0.33rem">

        <theme-picker/>

        <language />

        <notifications-popover/>

        <div>
          <b-dropdown no-caret size="sm"  right style="margin: 0px 0px 0px 0px !important; height: 2.5rem"
            class=" d-flex align-items-left justify-content-around m-2 userInfoContainer ">
            <template #button-content>
              <div class="container">
                <div style="margin: 0px 10px 0px 0px">
                  <h6 class="m-0">{{ username }}</h6>
                </div>
                <div>
                  <b-avatar style="height: 1.5rem; width:1.5rem"></b-avatar>
                </div>
              </div>
            </template>

            <b-dropdown-item-button :button-class="'b-nav-item'" variant="outline-secondary" v-if="$session.get('user').privilege != 2">
              <b-nav-item to="/profile" exact exact-active-class="active">
                <b-icon-pencil-fill font-scale="1.5" class="mt-2 pr-2">

                </b-icon-pencil-fill>{{$i18n.t('edit')}}
              </b-nav-item>
            </b-dropdown-item-button>

            <b-dropdown-item-button>
              <b-nav-item exact exact-active-class="active" @click="$emit('logout')">
                <b-icon-power font-scale="1.5" class="mt-2 pr-2 logoutBtn">
                </b-icon-power>Logout
              </b-nav-item>
            </b-dropdown-item-button>

            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-header id="cookieExpireTimeout">
              <label for="cookieExipre">Sessão</label>
              <input type="number" name="cookieExipre" id="cookieExipre" v-model="expireTime" min="0" max="999" @blur="updateValue">
              <small>min</small>
            </b-dropdown-header>

          </b-dropdown>
        </div>

        

        <span v-if="isOffline">
          <div id="isOfflineIndicatorContainer">
            <b-icon-wifi-off/>
          </div>
          <b-tooltip target="isOfflineIndicatorContainer" variant="danger"><strong>Sem conexão a internet</strong></b-tooltip>
        </span>

      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import Language from "../language/LanguageGrayBackground.vue"
import NotificationsPopover from './NotificationsPopover.vue'
import ThemePicker from './ThemePicker.vue'
export default {
  components: {
    Language,
    NotificationsPopover,
    ThemePicker
  },
  data(){
    return {
      isOffline: false,
      isMobileMenuOpen:false,
      expireTime: 0,
    }
  },
  computed: {
    username() {
      return this.$session.get("user").nickname
    },
    isMobile() {
      return this.$session.get("isMobile")
    }
  },
  methods: {

    toggleMobileMenu(){
      this.isMobileMenuOpen = !this.isMobileMenuOpen
    },
    notification() {
      this.$emit("showToast")
    },

    updateValue(e){
      let value = e.target.value
      if(isNaN(value)) {
        this.expireTime = this.$cookie.getExpireTime()
        return
      }
      value = parseInt(value)
      if(value < 0){
        value = 0
      }else if(value > 999){
        value = 999
      }

      this.expireTime = value

      //Verifica se o cookie ainda existe
      const cookieSessionUserId = this.$cookie.getCookie("logged_id")
      if (cookieSessionUserId == null) {

        const id = this.$session.get('logged_id');
			
        const url = this.$session.get("api_url")
        const servername = this.$apiController.GetServerName(url)

        const cookieExpireTime = this.expireTime / 60
        this.$cookie.setCookie("logged_id", id, cookieExpireTime)
        this.$cookie.setCookie("server_name", servername, cookieExpireTime)

      }

      this.$cookie.setExpireTime(this.expireTime)



    }
  },
  mounted(){
    this.expireTime = this.$cookie.getExpireTime()
    window.addEventListener("offline", (e) => {
      this.isOffline = true;
      console.log(e);
    });

    window.addEventListener("online", (e) => {
      this.isOffline = false;
      console.log(e);
    });
  }
}
</script>
<style scoped lang="scss">
.mobileMenuBtn{
  background: transparent;
  border: none;
}

#isOfflineIndicatorContainer{
  padding: 0.5rem;
  background: #b50d0d;
  height: 100%;
  margin-left: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #ff3333;
  &:hover{
    background: #f11616;
    transition: 0.25s ease;
  }
  svg{
    font-size: 1.5rem
  }
}



.navbar {
  padding: .1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &>*{
    z-index: 999;
  }
}

.logoutBtn {
  cursor: pointer
}

ul.navbar-nav .ml-auto {
  align-items: center;
  gap: 1rem;

  svg {
    margin: 0 !important;
  }
}
</style>

<style lang="scss">

nav.navbar.p-2.navbar-dark.bg-dark.navbar-expand {
	background-color: rgba(var(--tmHubTheme-primary-color), 0.95) !important;
	box-shadow: rgb(var(--tmHubTheme-primary-shadow-color)) 0px 20px 30px -10px;

  @media (min-width: 320px) and (max-width: 991px){
    position: relative;
    flex-direction: column;
    .headNavItems{
      z-index: 3;
      gap: 0.33rem;
      position: absolute;
      top: 0rem;
      opacity: 0;
      margin-top: 1rem;
      width: 100%;
      justify-content: space-evenly;
      background: rgb(var(--tmHubTheme-primary-shadow-color, 0, 0, 0));
      padding: 0.25rem 0;
      transition: 0.25s ease;

    }
    &.mobileMenuOpen{
      .headNavItems{
        top: 2rem;
        opacity: 1;
      }
    }
  }
}

.userInfoContainer {
  gap: 0.5rem;
  button.dropdown-toggle{
    border:none;
    background: #0a1828;
    background: rgb(var(--tmHubTheme-darken-primary-color));
  }
  h6 {
    color: white;
    // color: #3cd2a5;
    text-transform: capitalize
  }

  ul{
    background: #454d55;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    border: none;
    hr{
      border-top: 1px solid #5e6062;
    }
    li > .dropdown-item{
      li > a{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        color: white !important;
        svg{
          margin: 0 !important;
          color: #FF9800;
        }
      }

      &:hover{
        background-color: #353c45;
      }
      &:active{
        background-color: #2a2f35;
        li > a{
          color: #FF9800 !important;
        }
      }
    }
  }
}

#cookieExpireTimeout{
  display: flex;
  gap: 0.2rem;
  align-items: flex-end;
  label{margin: 0}
  input#cookieExipre{
    width: 2.5em;
    color: #FF9800;
    font-weight: bold;
    text-align: end;
    border: none;
    border-bottom: 1px solid gray;
    background-color: transparent
  }
}
</style>