<template>
    <div class="grid w-100 h-100 d-flex justify-content-center align-items-center flex-column ">
		<div class="w-100 h-100 d-flex align-items-center justify-content-center flex-column">
			<p class="text-success">{{info.maximum}}</p>
			<div class="cylinder" ref="cylinder">
				<div class="fill" :style="[barColor, fillHeight]"></div>
			</div>
			<p class="text-danger" style="position:relative; top:20px">{{info.minimum}}</p>
		</div>
        <div class="info text-center">
            <h4>{{value}} {{unit}}</h4>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            cylinderHeight: 20
        }
    },
    props:{
		value: {
			type: Number,
			required: false,
			default: 80,
		},
		info:{
			type: Object,
			required: true
		},
		fullscreen:{
			required:false,
			type:Boolean,
			default:false,
		},
		divisionsColorList:{
			type: Array,
			required:true
		}
	},
    computed:{
        unit(){
            return this.info.unit ? this.info.unit : 'unidade'
        },
		min(){
			return this.info.minimum != undefined ? this.info.minimum : 0
		},
		max(){
			return this.info.maximum  != undefined ? this.info.maximum : 100
		},
		getPercentage() {
            let valor = Math.max(this.min, Math.min(this.value, this.max));

            const intervalo = this.max - this.min;
            const porcentagem = ((valor - this.min) / intervalo) * 100;

            return porcentagem;
        },

		/**Ajusta a altura do 'preenchimento' do cilindro */
        fillHeight(){
            var height = this.cylinderHeight * (this.getPercentage / 100)

            return {'--fill-height': `${height}px`}
        },

		/**Gera gradiente do cilindro */
        barColor(){

			return {
                "--barColor": this.divisionsColorList[0].color
            }
		},
    },
    methods:{
        resizeMeasurer(){
			// Verifica se existe o elemento do cilindro criado
            if(this.$refs.cylinder) this.cylinderHeight = this.$refs.cylinder.offsetHeight
        }
    },
    mounted(){
        setTimeout(()=>{
            this.resizeMeasurer()
        }, 1000)
    }

}
</script>

<style scoped>
.grid{
    gap: 1rem
}
.cylinder {
	position: relative;
	overflow: hidden;
	margin: 0 auto;
	width: 25%;
	height: 80%;
	min-height: 8rem;
	border-radius: 50px/25px;
	background-color: rgba(160, 160, 160, 0.5);
}

.cylinder:before {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 50px;
	border-radius: 50px/25px;
	background-color: rgba(160, 160, 160, 0.2);
	content: '';
	border: 1px solid #959595;
}

.cylinder:after {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 50px;
	border-radius: 50px/25px;
	/* background-color: rgba(160, 160, 160, 0.4); */
	content: '';
}

.fill {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: var(--fill-height);
	padding-top: 50px;
	border-radius: 50px/25px;
	background: var(--barColor);
    /* : 0.5; */
    opacity: 1;
	transition: 0.3s linear;
}

/* Parte de cima */
.fill:before {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 50px;
	border-radius: 50px/25px;
    /* 0.2 */
    opacity: 0.2;
	background-color: #5d6368;
	content: '';
	border: 2px solid white;
}

/* Parte de baixo */
.fill:after { 
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 50px;
	border-radius: 50px/25px;
    /* 0.4 */
    opacity: 0.4;
	/* background-color: var(--bottomColor); */
	content: '';
}

</style>