<template>
  <div class="uploadCsvContainer">
        <input accept=".csv" type="file" id="uploadCSV" style="display: none;" @change="readCsv" :multiple="multiple ? 'multiple' : null">
        <div class="uploadCsvArea">
            <label class="uploadCsvButton" for="uploadCSV" v-if="uploaded.length == 0">
                <b-icon-download/>
                <h3>{{$i18n.t('importCsv')}}</h3>
            </label>
            <div class="uploadedFiles" v-else>
                <div class="uploadedFilesGrid">
                    <div class="fileCard" v-for="(file, index) in uploaded" :key="index">
                        <b-icon-file-earmark-excel-fill/>
                        <small>{{file.name}}</small>
                        <small class="remove" @click="remove(index)">remover</small>
                    </div>
                </div>
                <h5><code>{{uploaded.length}}</code> arquivos enviados</h5>
                <label class="add" for="uploadCSV">Selecionar outro</label>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    data(){
        return{
            uploaded: []
        }
    },

    props:{
        element: {
            type: Object,
            required:true
        },
        uploadEnd:{ //acho  q o emit não funciona pela função readCsv ser asincrona, por isso passa a função que quer que seja executada nas props
            type: Function,
            required: true
        },
        multiple:{
            type: Boolean,
            required:false,
            default:false
        }
    },
    methods:{
        remove(index){
            this.uploaded = this.uploaded.filter((f, i) => i != index)
        },
        async readCsv(event) {
            this.uploaded = []
            const files = event.target.files;
            if (files) {
                try{
                    for (let file of files){
                        var csvContent = await this.readText(file)
                        const json = this.csvToJSON(csvContent)[0];
                        const jsonWithCorrectTypes = this.convertTypes(json, this.element);
                        this.uploaded.push({name: file.name, data: jsonWithCorrectTypes})
                    }
                    const items = this.uploaded.map(d => d.data)
                    this.uploadEnd(items)
                }
                catch(e){
                    console.error(e)
                }
            }
        },
        async readText(file){
            return await new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        resolve(e.target.result);
                    };
                    reader.readAsText(file);
                })
        },
        splitString(inputString) {
            // Usar uma expressão regular para dividir a string, considerando as vírgulas fora das aspas duplas
            const result = inputString.split(/\s*,\s*(?=(?:(?:[^"]*"){2})*[^"]*$)/);

            // Remover espaços extras no início e no final de cada parte da string
            return result.map(part => part.trim());
        },
        csvToJSON(csv){

            var lines=csv.split("\n");
            var result = [];
            var headers=lines[0].split(",").map(i => {
                if(i.includes('\r')){
                    i = i.replaceAll('\r', '')
                }
                return i
            });

            for(var i=1;i<lines.length;i++){

                var obj = {};
                var currentline=this.splitString(lines[i])

                for(var j=0;j<headers.length;j++){
                    obj[headers[j]] = currentline[j].replaceAll("\"", "");
                }

                result.push(obj);

            }
            return result
        },
        convertTypes(obj, model) {
            const result = {};

            for (const key in model) {
                if (Object.prototype.hasOwnProperty.call(model, key)) {
                    const tipomodel = typeof model[key];
                    if (obj[key] !== undefined) {
                        if (tipomodel === "boolean") {
                            result[key] = obj[key].toLowerCase() === "true";
                        } else if (tipomodel === "number") {
                            result[key] = Number(obj[key]);
                        } else {
                            result[key] = obj[key];
                        }
                    } else {
                        result[key] = model[key];
                    }
                }
            }

            return result;
        },
    }
}
</script>

<style lang="scss" scoped>
.uploadCsvContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    .uploadCsvArea{
        padding: 1rem;
        width: 50%;
        transition: all 0.5s;
        border-radius: 1rem;
        opacity: 1;
        border: 1px dashed;
        border-color: #007bff;
        background-color: #0f305436;
        &:has(label.uploadCsvButton){
            background-color: #3c3d40;
            opacity: 0.5;
            .uploadCsvButton{
                height: 100%;
                width: 100;
                svg {
                    font-size: 1.5rem;
                    margin-bottom: 0.5rem;
                }
            }
            &:hover{
                opacity: 1;
                border-color: #007bff;
                background-color: #0f305436;
            }
        }
        .uploadedFiles{
            height: 100%;
            width: 100%;
            .uploadedFilesGrid{
                display: flex;
                justify-content: center;
                gap: 3%;
                flex-wrap: wrap;
                align-items: flex-start;
                align-content: center;
                .fileCard{
                    padding: 1rem;
                    width: 30%;
                    background: #081f3699;
                    align-self: flex-start;
                    border-radius: 1rem;
                    border: 1px solid rgb(255 255 255 / 50%);
                    outline: 1px solid;
                    outline-offset: 0.25rem;
                    margin: 1em 0;
                    display:flex;
                    flex-direction: column;
                    align-items:center;
                    gap: 0.75rem;
                    svg{
                        font-size: 1.5rem;
                    }
                    small{
                        font-size: 0.65rem;
                    }
                    small.remove{
                        font-size: 0.75rem;
                        text-decoration: underline;
                        cursor: pointer;
                        margin-bottom: 0;
                        &:hover{
                            color: #b893ff
                        }
                    }
                }
            }
        }
    }
}
label{
    cursor: pointer;
    &.add{
        text-decoration: underline;
        &:hover{
            color: #007bff
        }
    }
}
</style>