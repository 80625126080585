<template>
    <div class="w-100 h-100" style="position: relative;">
        <section class="videoPlayerErrorOverlay" v-if="failed">
            <b-icon-exclamation-diamond-fill />
            <strong>Ocorreu um erro ao reproduzir esse vídeo</strong>
            <button type="button" @click="retry">
                <b-icon-arrow-repeat /> Tentar novamente
            </button>
        </section>
        <iframe v-if="realTime" class="video-js" :src="source"></iframe>
        <video v-else ref="videoPlayer" class="video-js"></video>
    </div>
</template>

<script>
import videojs from 'video.js';

export default {

    data() {
        return {
            counter: 0,
            failed: false,
            player: null,
        }
    },

    props: {
        id: {
            type: Number,
            default: 1,
        },

        frames: {
            type: Number,
            default: 1,
        },

        delay: {
            type: Number,
            default: 0,
        },

        interval: {
            type: Number,
            default: 500,
        },

        source: {
            type: String,
            default: "",
        },
        height: {
            type: Number,
            required: false
        },
        width: {
            type: Number,
            required: false,
            default: 0,
        },
        realTime: {
            type: Boolean,
            required: true,
            default: false,
        }

    },

    computed: {

    },

    methods: {
        retry() {
            if (this.player) {
                this.player.src(this.source);
                this.player.play();
                this.failed = false;
            }
        },
    },

    mounted() {
        const options = {
            controls: false,
            autoplay: true,
            muted: true,
            preload: 'auto',
            notSupportedMessage: 'Ocorreu um erro ao reproduzir o vídeo',
            sources: [{
                src: this.source,
                type: 'application/x-mpegURL'
            }]
        }

        console.log(this.source)

        if (!this.realTime) {
            const video = this.$refs.videoPlayer
           /* if (this.height) {
                video.style["min-height"] = `${this.height}px`;
            }*/
            video.addEventListener("error", () => {
                this.failed = true
            })
            this.player = videojs(video, options, () => {
                this.$el.querySelector('video').play();
            });
        }
    },

    beforeDestroy() {
        if (this.player) {
            this.player.dispose();
        }
    }
}

</script>

<style>
.video-js {
    position: relative !important;
    width: 100% !important;
    height: 100% !important;

    background-color: rgb(var(--tmHubTheme-primary-shadow-color, 0, 0, 0))
}
</style>


<style scoped lang="scss">
.videoPlayerErrorOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.25rem;

    background: #121212;
    background: linear-gradient(135deg,
            #8f2437 25%,
            #520b17 25%,
            #520b17 50%,
            #8f2437 50%,
            #8f2437 75%,
            #520b17 75%,
            #520b17);
    background-size: 40px 40px;

    /* Animation */
    animation: move 9s linear infinite;

    svg {
        font-size: 2.5rem;
    }

    button {
        border: none;
        padding: 0.25rem 1rem;
        border-radius: 0.25rem;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 8px;

        transition: 0.3s ease-in-out;

        svg {
            font-size: 1rem;
        }

        &:hover {
            box-shadow: rgba(0, 0, 0, 0.25) 0px 6px 14px;
            background-color: #ffd8db;
        }
    }
}

@keyframes move {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 40px 40px;
    }
}
</style>
