import {getNumberOfRowsPerPage, setNumberOfRowsPerPage} from './domain/GlobalFunctions/pagination'
import AlarmCommentModalController from "./domain/GlobalFunctions/commentAlarmModal"
import exportToCSV from './domain/GlobalFunctions/exportToCSV';
import theme from './domain/GlobalFunctions/theme';
import interceptors from './domain/GlobalFunctions/interceptors';
import CookieManager from './domain/GlobalFunctions/cookies';
import enableToasts from './domain/GlobalFunctions/enableToasts';

export default class UtilsTelemetry{
    constructor(Vue){
        this.componenst = [
            'Action',
            'Alarm',
            "Audit",
            'AlarmConfiguration',
            "AlarmHistory",
            "Blueprint",
            'ColorConfig',
            'Dashboard',
            'Device',
            'Display',
            'Group',
            'History',
            'Layer',
            'Map',
            'Measurer',
            "Presentation",
            'VideoServerConfig',
            'VideoConfig',
            'Recipient',
            'Schedule',
            'User',
            'Variable',
            'SerialNumber',
            'RecipientGroup',
            'Network',
            `Blueprint`,
            'BlueprintLayers',
            'MapLayers',
            'Aggregation',
            'Tree',
            'ReportSchedule'
        ]
        Vue.component('DefaultIndex', () => import('./components/shared/DefaultIndex'));
        Vue.component('ToggleComponentButton', ()=> import('./components/shared/ToggleComponentButton'))
        Vue.component('ToggleRelationTableButton', () => import('./components/shared/ToggleRelationTableButton'));
        Vue.component('map-modal', () => import('./components/shared/MapModal'));
        Vue.component('expandable-button', () => import('./components/shared/ExpandableButton'));
        Vue.component('select-layer-button', () => import('./components/shared/SelectMapLayerButton'));
        Vue.component('toggle-map-events-button', () => import('./components/shared/ToggleMapShowEventsButton'));
        Vue.component('map-newsbar', () => import('./components/shared/MapNewsBar'));
        Vue.component('text-select-input-group', () => import('./components/shared/TextSelectInputGroup'));
        Vue.component("comment-alarm-modal", () => import('./components/shared/CommentAlarmModal/index.vue'));


        Vue.component("presentation-select-list", () => import('./components/presentation/PresentationSelectList'));
        Vue.component("presentation-loader", () => import('./components/presentation/PresentationLoader'));
    }


    camelize(str) {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }

    async importElement(name, sufix = '') {
        var adjustedName = name.replace(/([A-Z])/g, '-$1').trim().toLowerCase() + sufix;
        return await import(`./domain/${name}/${adjustedName}`)
        .then((module) => {
            return module.default;
        })
        .catch((error) => {
            console.log(error);
            console.log("Error!-" + name)
            console.log(`./domain/${name}/${adjustedName}`)
        });
    }


    async addAllPrototye(Vue) {
        [Vue.prototype.$action, Vue.prototype.$actionHeader, Vue.prototype.$actionService] = await this.addPrototype('action');
        [Vue.prototype.$alarm, Vue.prototype.$alarmHeader, Vue.prototype.$alarmService] = await this.addPrototype('alarm');
        [Vue.prototype.$alarmConfiguration, Vue.prototype.$alarmConfigurationHeader, Vue.prototype.$alarmConfigurationService] = await this.addPrototype('alarmConfiguration');
        [Vue.prototype.$audit, Vue.prototype.$auditHeader, Vue.prototype.$auditService] = await this.addPrototype('audit');
        [Vue.prototype.$blueprint, Vue.prototype.$blueprintHeader, Vue.prototype.$blueprintService] = await this.addPrototype('blueprint');
        [Vue.prototype.$colorConfig, Vue.prototype.$colorConfigHeader, Vue.prototype.$colorConfigService] = await this.addPrototype('colorConfig');
        [Vue.prototype.$dashboard, Vue.prototype.$dashboardHeader, Vue.prototype.$dashboardService] = await this.addPrototype('dashboard');
        [Vue.prototype.$device, Vue.prototype.$deviceHeader, Vue.prototype.$deviceService] = await this.addPrototype('device');
        [Vue.prototype.$display, Vue.prototype.$displayHeader, Vue.prototype.$displayService] = await this.addPrototype('display');
        [Vue.prototype.$group, Vue.prototype.$groupHeader, Vue.prototype.$groupService] = await this.addPrototype('group');
        [Vue.prototype.$history, Vue.prototype.$historyHeader, Vue.prototype.$historyService] = await this.addPrototype('history');
        [Vue.prototype.$layer, Vue.prototype.$layerHeader, Vue.prototype.$layerService] = await this.addPrototype('layer');
        [Vue.prototype.$measurer, Vue.prototype.$measurerHeader, Vue.prototype.$measurerService] = await this.addPrototype('measurer');
        [Vue.prototype.$recipient, Vue.prototype.$recipientHeader, Vue.prototype.$recipientService] = await this.addPrototype('recipient');
        [Vue.prototype.$recipientGroup, Vue.prototype.$recipientGroupHeader, Vue.prototype.$recipientGroupService] = await this.addPrototype('recipientGroup');
        [Vue.prototype.$schedule, Vue.prototype.$scheduleHeader, Vue.prototype.$scheduleService] = await this.addPrototype('schedule');
        [Vue.prototype.$user, Vue.prototype.$userHeader, Vue.prototype.$userService] = await this.addPrototype('user');
        [Vue.prototype.$variable, Vue.prototype.$variableHeader, Vue.prototype.$variableService] = await this.addPrototype('variable');
        [Vue.prototype.$message, Vue.prototype.$messageHeader, Vue.prototype.$messageService] = await this.addPrototype('message');
        [Vue.prototype.$variableCodes, Vue.prototype.$variableCodesHeader, Vue.prototype.$variableCodesService] = await this.addPrototype('variableCodes');
        [Vue.prototype.$serialNumber, Vue.prototype.$serialNumberHeader, Vue.prototype.$serialNumberService] = await this.addPrototype('serialNumber');
        [Vue.prototype.$varData, Vue.prototype.$dataHeader, Vue.prototype.$dataService] = await this.addPrototype("data");
        [Vue.prototype.$status, Vue.prototype.$statusHeader, Vue.prototype.$statusService] = await this.addPrototype("status");
        [Vue.prototype.$alarmData, Vue.prototype.$alarmDataHeader, Vue.prototype.$alarmDataService] = await this.addPrototype("alarmData");
        [Vue.prototype.$videoServerConfig, Vue.prototype.$videoServerConfigHeader, Vue.prototype.$videoServerConfigService] = await this.addPrototype("videoServerConfig");
        [Vue.prototype.$videoConfig, Vue.prototype.$videoConfigHeader, Vue.prototype.$videoConfigService] = await this.addPrototype("videoConfig");
        [Vue.prototype.$network, Vue.prototype.$networkHeader, Vue.prototype.$networkService] = await this.addPrototype("network");
        [Vue.prototype.$blueprint, Vue.prototype.$blueprintHeader, Vue.prototype.$blueprintService] = await this.addPrototype("blueprint");
        [Vue.prototype.$blueprintLayer, Vue.prototype.$blueprintLayerHeader, Vue.prototype.$blueprintLayerService] = await this.addPrototype("blueprintLayer");
        
        [Vue.prototype.$dashboardDisplay, Vue.prototype.$dashboardDisplayHeader, Vue.prototype.$dashboardDisplayService] = await this.addPrototype("dashboardDisplay");
        [Vue.prototype.$groupDisplay, Vue.prototype.$groupDisplayHeader, Vue.prototype.$groupDisplayService] = await this.addPrototype("groupDisplay");
        [Vue.prototype.$presentation, Vue.prototype.$presentationHeader, Vue.prototype.$presentationService] = await this.addPrototype("presentation");
        [Vue.prototype.$presentationDisplay, Vue.prototype.$presentationDisplayHeader, Vue.prototype.$presentationDisplayService] = await this.addPrototype("presentationDisplay");
        [Vue.prototype.$accessRule, Vue.prototype.$accessRuleHeader, Vue.prototype.$accessRuleService] = await this.addPrototype("accessRule");
        [Vue.prototype.$aggregation, Vue.prototype.$aggregationHeader, Vue.prototype.$aggregationService] = await this.addPrototype("aggregation");
        [Vue.prototype.$reportSchedule, Vue.prototype.$reportScheduleHeader, Vue.prototype.$reportScheduleService] = await this.addPrototype("reportSchedule");

        Vue.prototype.$apiController = await import('./api')
        .then(module =>{
            return module.ApiController
        })

        Vue.prototype.$reportService = await import('./domain/report/report-service')
        .then((module)=>{
            return module.default;
        })

        Vue.prototype.$reportScheduleService = await import('./domain/reportSchedule/report-schedule-service')
        .then((module)=>{
            return module.default;
        })


        Vue.prototype.$alarmOperatorService = await import('./domain/alarmOperator/alarm-operator-service')
        .then((module)=>{
            return module.default;
        })

        Vue.prototype.$alarmHistoryService = await import('./domain/alarmHistory/alarm-history-service')
        .then((module)=>{
            return module.default;
        })

        Vue.prototype.$alarmPrioritiesService = await import('./domain/alarmPriorities/alarm-priorities-service')
        .then((module)=>{
            return module.default;
        })

        Vue.prototype.$actionOperatorService = await import('./domain/actionOperator/action-operator-service')
        .then((module)=>{
            return module.default;
        })
        Vue.prototype.$messageController = await import(`./domain/message/message-controller`)
        .then((module => {
            return module.default;
        }))

        Vue.prototype.$modbusService = await import(`./domain/modbus/modbus-service`)
        .then((module => {
            return module.default;
        }))


        Vue.prototype.$snmpService = await import(`./domain/snmp/snmp-service`)
        .then((module => {
            return module.default;
        }))

        Vue.prototype.$positionService = await import("./domain/position/position-service")
        .then(module => {
            return module.default
        })

        Vue.prototype.$treeService = await import("./domain/tree/tree-service")
        .then(module => {
            return module.default
        })


        Vue.prototype.$apiInterceptor = interceptors
        Vue.prototype.$getNumberOfRowsPerPage = getNumberOfRowsPerPage
        Vue.prototype.$setNumberOfRowsPerPage = setNumberOfRowsPerPage
        Vue.prototype.$exportToCSV = exportToCSV
        Vue.prototype.$theme = theme
        Vue.prototype.$commentAlarmModal = AlarmCommentModalController
        Vue.prototype.$enableToasts = enableToasts
        Vue.prototype.$cookie = new CookieManager()

    }

    addAllComponents(Vue) {
        this.componenst.forEach(element => {
            this.addComponent(Vue, element);
        });        
        //this.addCustomComponent(Vue, 'device','state')

        Vue.component('report-index', async() => await import('./components/report/ReportIndex'))
        //Vue.component('history-report', async() => await import('./components/history/HistoryReport/index.vue'))
        Vue.component('report-component-list', async()=> await  import('./components/report/ReportComponentNav/ComponentList/index.vue'))

        Vue.component('dashboard-measurer-info', async ()=> await import('./components/dashboard/DashboardMeasurerInfo'))
        Vue.component('dashboard-panel', async ()=> await import('./components/dashboard/DashboardPanel'))

        Vue.component(`UserRelation`, () => import(`./components/user/UserRelation`));  
        Vue.component(`UserSons`, () => import(`./components/user/UserSons`));  

        Vue.component(`LoginForm`, () => import(`./components/auth/Login`));
        Vue.component(`sign-up-form`, () => import(`./components/auth/SignUp`));

        Vue.component('map-component', ()=> import('./components/map/MapComponent'))
        Vue.component('blueprint-map' , ()=> import('./components/blueprint/BlueprintMap'))
        Vue.component('blueprint-map-component' , ()=> import('./components/blueprint/BlueprintMapComponent'))
        Vue.component('blueprint-position-modal' , ()=> import('./components/blueprint/BlueprintPositionModal'))
    }

    async addComponent(Vue, name) {
        Vue.component(`${name}Index`, () => import(`./components/${this.camelize(name)}/${name}Index`));
        Vue.component(`${name}Info`, () => import(`./components/${this.camelize(name)}/${name}Info`));
    }

    async addCustomComponent(Vue, name, component) {
        const component_address = `./components/${name}/${component}.vue`
        console.log(component_address)
        Vue.component(`${name}-${component}`, () => import(component_address));
    }

    async addPrototype(name, element, elementHeader, elementService) {
        element = await this.importElement(name);
        elementHeader = await this.importElement(name, '-header');
        elementService = await this.importElement(name, '-service');
        return [element, elementHeader, elementService];
    }

}