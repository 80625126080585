
<template>
    <div class="h-100 w-100 p-2" ref="container">
        <b-table striped hover :fields="visibleFields" :items="items" class=" measurerTable" ref="table" tbody-tr-class="cardMeasurerTable"> 
            <template #cell()="row">
                <div class="w-100 h-100 d-flex justify-content-center align-items-center" :style="getRowColor(row.item.value)">
                    {{row.value}}
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fields: [
                { key: 'time', label: this.$i18n.t('time'), visible: false },
                //{key: 'value',     label: this.$i18n.t('value'),     visible: true },
                { key: 'value', label: this.info.unit, visible: true },
                { key: 'variation', label: this.$i18n.t('variation'), visible: false },
            ],
            items: []
        }
    },
    props: {
        value: {
            type: Number,
            required: false,
            default: 80,
        },
        topic: {
            type: String,
            required: false,
        },
        info: {
            type: Object,
            required: true
        },
        fullscreen: {
            required: false,
            type: Boolean,
            default: false,
        }
    },

    methods: {
        getValueByPercentage(percentage) {
            const clamp = Math.max(0, Math.min(percentage, 100));

            const range = this.info.maximum - this.info.minimum;
            const value = this.info.minimum + (clamp / 100) * range;

            return value;
        },
        getRowColor(value){
            var style = {backgroundColor: "black", color: "white"}
            this.info.divisions.forEach(d => {
                var {start, end} = d
                start = this.getValueByPercentage(start)
                end = this.getValueByPercentage(end)
                if(value >= start && value <= end){
                    style.backgroundColor = `${d.color}30`
                    style.color = d.color
                }
            })
            return style
        },
        resizeMeasurer() {
            this.ajustRows()
            this.ajustColumns()
        },
        ajustColumns() {
            var cardProportion;
            try {
                var gridLayoutWidth = document.querySelector(".vue-grid-layout").offsetWidth
                var currentCardWidth = this.$parent.$refs.body.offsetWidth
                var defaultCardWidth = gridLayoutWidth / 6

                cardProportion = Math.floor(currentCardWidth / defaultCardWidth) + 1
            } catch {
                cardProportion = 1
            }

            this.toggleColumns(cardProportion)
        },
        toggleColumns(number) {
            this.fields[0].visible = number >= 2
            this.fields[2].visible = number >= 3
        },
        ajustRows() {
            try {
                this.containerHeight = this.$refs.container.offsetHeight
            } catch(e) { console.log(e) }
        },
        updateValues() {
            var lastValue = 0
            var history = window.Variable.GetVariableTemporaryHistoryValues(this.topic)
            this.items = history.map((value, index) => {
                var intValue = parseFloat(value.replace(",", ".")).toFixed(2)
                if (index == 0) {
                    return { time: '-', value: intValue, status: '', variation: 0 }
                } else {
                    var lastValue = parseFloat(history[index - 1].replace(",", ".")).toFixed(2)
                    let status = intValue > lastValue ? 'high' : intValue == lastValue ? '' : 'low'
                    return { time: '-', value: intValue, status, variation: Math.abs(intValue - lastValue).toFixed(2) }
                }
            }).reverse()
            this.updateTableValuesInterval = setInterval(() => {
                let value = this.value
                let status = value > lastValue ? 'high' : value == lastValue ? '' : 'low'

                let newRow = {
                    time: new Date().toLocaleString('pt-BR', { timezone: 'UTC' }).split(" ")[1],
                    //value: `${value} ${this.info.unit}`, 
                    value: `${value}`,
                    status: status,
                    variation: Math.abs(value - lastValue),
                }

                this.items = [newRow].concat(this.items)
                lastValue = value
                this.ajustRows()
            }, 5000)
        },

        replaceSpecialChar(str) {
            // Remove os acentos e substitui por caracteres simples
            if (str.search(/[\xC0-\xFF]/g) > -1) {
              str = str
                .replace(/[\xC0-\xC5]/g, "A")
                .replace(/[\xC6]/g, "AE")
                .replace(/[\xC7]/g, "C")
                .replace(/[\xC8-\xCB]/g, "E")
                .replace(/[\xCC-\xCF]/g, "I")
                .replace(/[\xD0]/g, "D")
                .replace(/[\xD1]/g, "N")
                .replace(/[\xD2-\xD6\xD8]/g, "O")
                .replace(/[\xD9-\xDC]/g, "U")
                .replace(/[\xDD]/g, "Y")
                .replace(/[\xDE]/g, "P")
                .replace(/[\xE0-\xE5]/g, "a")
                .replace(/[\xE6]/g, "ae")
                .replace(/[\xE7]/g, "c")
                .replace(/[\xE8-\xEB]/g, "e")
                .replace(/[\xEC-\xEF]/g, "i")
                .replace(/[\xF1]/g, "n")
                .replace(/[\xF2-\xF6\xF8]/g, "o")
                .replace(/[\xF9-\xFC]/g, "u")
                .replace(/[\xFE]/g, "p")
                .replace(/[\xFD\xFF]/g, "y");
            }
            return str.replace(/[^A-Z0-9]+/ig, "");
        },
        //cria as classes para as divisões dinamicamente 
        createDivisionsClass(){
            let divisions = this.info.divisions;
            console.log(divisions);
            //divisions.forEach(d => {
            //    console.log(d);
            //})
        }
    },
    computed: {
        visibleFields() {
            return this.fields.filter(field => field.visible)
        },
        visibleItems() {
            const lineQuant = Math.floor(this.containerHeight / 50)
            return this.items.filter((item, index) => index < lineQuant)
        },
    },
    mounted() {
        this.ajustColumns()
        this.ajustRows()
        this.updateValues()
        this.createDivisionsClass()

        //Mostra todas as colunas quando é aberto em tela cheia
        if (this.fullscreen) this.toggleColumns(4)
    },

    beforeDestroy() {
        clearInterval(this.updateTableValuesInterval)
    }
}
</script>

<style lang="scss">

.cardMeasurerTable{
    td{
        padding: 0 !important;
        vertical-align: middle !important;
        height: 3rem;
    }
}


table.measurerTable div,
table.measurerTable td {
    text-align: center !important;
}

table.measurerTable td {
    border-left: 1px solid white;
    font-size: 1.25em;
}

table.measurerTable td:nth-child(1) {
    border-left: none
}
</style>