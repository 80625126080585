<template>
    <div class="">
        <div v-b-tooltip.hover title="Mudar o intervalo do painel" :id="popoverId" class="selectTime d-flex align-items-center">
            <b-icon-clock/>
            <p class="m-0">{{time}}s</p>
        </div>
        <b-popover 
            :target="popoverId"
            triggers="click"
            placement="righttop"
            custom-class="timePopover"
        >
            <text-input name="timeInput" type="number" :value="time" :append="$i18n.t('seconds')" class="m-0" style="width:12rem !important;" @changeValue="changeValue"/>
        </b-popover>
    </div>
</template>

<script>
export default {
    props:{
        time: {
            required:true,
            type:Number
        }
    },
    computed:{
        popoverId(){
            var randomId = Math.floor(Math.random() * 1000 + 1)
            return `popover_${randomId}`
        }
    },
    methods:{
        changeValue(newValue){
            this.$emit("change",newValue)
        },
        pressConfirm(event){
            if(event.key == "Enter" || event.key == "Escape"){
                this.$root.$emit('bv::hide::popover', this.popoverId)
                this.$emit("change",this.time)
            }
        }
    },
    mounted(){
        document.addEventListener("keyup", this.pressConfirm)
    },
    beforeDestroy(){
        document.removeEventListener("keyup", this.pressConfirm)
    }
}
</script>

<style scoped lang="scss">
.selectTime{
    gap: 0.5rem;
    border: 1px solid #673AB7;
    background: #673ab770;
    padding: 0.1rem;
    font-size: 0.75rem;
    border-radius: 0.25rem;
    cursor: pointer;
}
.timePopover{
    background: #673AB7;
    padding: 0rem !important;
    .popover-body{
        padding: 0 !important
    }
}
</style>