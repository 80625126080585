<template>
    <div class="d-flex align-items-center flex-column mb-4">
        <h3>{{$i18n.t('alarmColorDefinition')}}</h3>
        <b-table thead-tr-class="colorTableHeader" tbody-tr-class="colorTableBody" fixed striped primary-key="id" thead-class="text-white" style="text-align: center; width: 75%" :fields="alarmColorFields" :items="[colors]">
            <template #cell(inform)>
                <input type="color" v-model="colors.informColor" class="align"/>
            </template>
            <template #cell(attention)>
                <input type="color" v-model="colors.attentionColor" class="align"/>
            </template>
            <template #cell(critical)>
                <input type="color" v-model="colors.criticalColor" class="align"/>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    props: {
        info: {
            required: true,
            type: Object
        }
    },
    data(){
        return{
            colors: {}
        }
    },
    computed:{
        alarmColorFields(){
            return [{key: 'inform', label: this.$i18n.t('inform')}, {key: 'attention', label: this.$i18n.t('attention')}, {key: 'critical', label: this.$i18n.t('critical')}]
        },
    },
    created(){
        this.colors = this.info
    },
    watch:{
        info:{
            deep: true,
            handler(){
                this.colors = this.info
            }
        },
        colors:{
            deep:true,
            handler(){
                this.$emit('update', this.colors)
            }
        }
    }


}
</script>

<style scoped lang="scss">
input[type="color"]{
        border: none;
        background-color: transparent;
        width: 100%;
        height: 3rem;
        border: 1px solid white;
        border-radius: 5px;
        opacity: 0.9;
        cursor: pointer;
    }
</style>

<style lang="scss">
    thead:has(> .colorTableHeader){
        background-color: transparent;
        th{
            background: transparent;
            color: white;
            border: none;
            padding: 0;
            div{
                font-size: 1.125rem;
            }
        }
    }
    .colorTableBody{
        td{
            padding: 0;
            border: none;
        }
    }
</style>